
import Vue, { defineComponent, getCurrentInstance, ref } from "vue";

import {
  folderStatusChangeId,
  folderClosedId,
  submitFolderId,
  folderDeletedId,
  folderDeletedReminderTemplateId,
} from "../../static/emailTemplates";
import { UPDATE_USER } from "../../graphql/userQueries";
import { useStore } from "vuex";

export default defineComponent({
  name: "NotificationsSettings",
  setup() {
    const store = useStore();
    const instance = getCurrentInstance();
    const notificationTemplates = ref<string[]>(
      store.getters.getUserNotifcationTemplates || []
    );
    const submit = ref<boolean>(
      notificationTemplates.value.indexOf(submitFolderId) !== -1
    );
    const close = ref<boolean>(
      notificationTemplates.value.indexOf(folderClosedId) !== -1
    );
    const change = ref<boolean>(
      notificationTemplates.value.indexOf(folderStatusChangeId) !== -1
    );
    const deletion = ref<boolean>(
      notificationTemplates.value.indexOf(folderDeletedId) !== -1
    );
    const reminder = ref<boolean>(
      notificationTemplates.value.indexOf(folderDeletedReminderTemplateId) !==
        -1
    );

    async function updateUser() {
      let notificationTemplates: any[] = [];
      if (submit.value) notificationTemplates.push(submitFolderId);
      if (close.value) notificationTemplates.push(folderClosedId);
      if (change.value) notificationTemplates.push(folderStatusChangeId);
      if (deletion.value) notificationTemplates.push(folderDeletedId);
      if (reminder.value)
        notificationTemplates.push(folderDeletedReminderTemplateId);
      // $apollo.mutate({
      //   // Query
      //   mutation: UPDATE_USER,
      //   // Parameters
      //   variables: {
      //     updateUserData: {
      //       notificationTemplates,
      //     },
      //   },
      // });
      await store.commit("setNotificationTemplates", notificationTemplates);
      back();
    }

    const back = () => {
      instance?.emit("back");
    };
    return {
      close,
      submit,
      change,
      reminder,
      deletion,
      back,
      updateUser,
    };
  },
});
