
import Vue, { computed, defineComponent, onMounted, ref } from "vue";

import EventBus from "@/EventBus/eventBus";
import { useStore } from "vuex";
import { useRoute, useRouter } from "vue-router";
import { usePlan } from "@/plugins/planPlugin";
import { useRole } from "@/plugins/rolePlugin";
import { useAuth0 } from "@auth0/auth0-vue";

export default defineComponent({
  name: "SideNav",
  setup(props) {
    const STATIC_URL = ref(process.env.VUE_APP_STATIC_URL);
    const PRODUCT_URL = ref(process.env.VUE_APP_PRODUCT_URL);
    const resourcesOpen = ref(false);
    const store = useStore();
    const router = useRouter();
    const route = useRoute();
    const auth: any = useAuth0();
    const plan = usePlan();
    const role = useRole();
    console.log(plan.value())
    const showResourcesTab = computed(() => {
      return store.getters.getShowResourcesTab;
    });
    const showSupportTab = computed(() => {
      return store.getters.getShowSupportTab;
    });
    const changeClass = (name) => {
      if (resourcesOpen.value) {
        if (name === "resources") {
          return { active: true };
        }
      } else if (name == "admin-tools") {
        active: router.currentRoute.value.matched.some(
          ({ path }) => path === "admin-tools"
        );
      } else {
        return {
          active: router.currentRoute.value.path === "/" + name,
        };
      }
    };

    const quickLogout = () => {
      const logoutUrl = store.getters.getOrgLogoutUrl;
      auth.quickLogout({ returnTo: logoutUrl });
    };

    const resources = () => {
      resourcesOpen.value = true;
      EventBus.emit("resources");
    };
    const closeResources = () => {
      resourcesOpen.value = false;
    };
    onMounted(() => {
      EventBus.on("resourcesClosed", closeResources);
    });
    return {
      resources,
      resourcesOpen,
      showResourcesTab,
      showSupportTab,
      quickLogout,
      auth,
      route,
      router,
      role,
      plan,
    };
  },
});
