import gql from "graphql-tag";
import { OrgFragment, OrgWithFoldersFragment } from './fragments';

export const GUARD_TEST = gql`
  query testGuard {
    testGuard
  }
`

export const SPEAKFULLY_ADMIN_TOKEN = gql`
  query getSuperAdminToken {
    getSuperAdminToken
  }
`

export const UPDATE_SURVEY_SCHEMA = gql`
  mutation updateSurveySchema {
    updateSurveySchema
  }
`

export const REMOVE_NOTE_AUTHID = gql`
  mutation removeAuthIdFromNotes {
    removeAuthIdFromNotes
  }
`


export const EXPERIENCE_REQUIRED_BOOLEAN = gql`
  mutation experienceReqBoolean {
    addExperienceReqBoolean {
      matchedCount
      modifiedCount
      upsertedId
      acknowledged
    }
  }
`

export const ADD_PEOPLE_ANALYTICS_BOOLEAN = gql`
  mutation peopleAnalyticsBoolean {
    addPeopleAnalyticsBoolean {
      matchedCount
      modifiedCount
      upsertedId
      acknowledged
    }
  }
`

export const ADD_FOLDER_SUBMITTED_DATE = gql`
  mutation addFolderSubmittedDate {
    addFolderSubmittedDate {
      ok
      status
      error
    }
  }
`

export const ADD_ALLOWED_DOMAINS = gql`
  mutation addAllowedDomains {
    addAllowedDomains {
      matchedCount
      modifiedCount
      upsertedId
      acknowledged
    }
  }
`

export const ADD_ALLOW_MESSAGING = gql`
  mutation addAllowMessagingFlag {
    addAllowMessagingFlag {
      matchedCount
      modifiedCount
      upsertedId
      acknowledged
    }
  }
`

export const ORG_PERSONS_INVOLVED_DEFAULTS = gql`
  mutation addPersonsInvolvedProperties {
    addPersonsInvolvedProperties {
      ok
      n
      nModified
    }
  }
`;

export const ADD_HAS_CONSENTED_FLAG = gql`
  mutation setHasConsented {
    setHasConsented {
      email
    }
  }
`;

  export const GET_ORGS  = gql`
  query getAllOrgs {
    getAllOrgs {
      ...OrgFragment
      allowedDomains
      hraClientId
      hraClientSecret
      attachmentHraClientSecret
      attachmentHraClientId
    }
  }
${OrgFragment}
`;



export const GET_ORGS_WITH_FOLDERS  = gql`
query getAllOrgsWithFolders($offset: Float!, $limit: Float!, $query: String) {
  getAllOrgsWithFolders(offset: $offset, limit: $limit, query: $query) {
    ...OrgWithFoldersFragment
  }
}
${OrgWithFoldersFragment}
`;


export const CLONE_ORG = gql`
  mutation cloneOrg($cloneOrgData: CloneOrgInput!) {
    cloneOrg(cloneOrgData: $cloneOrgData) {
      name
    }
  }
`;

export const SAVE_ORG_SURVEY_QUESTIONS = gql`
  mutation updateSurveyQuestions($updateSurveyQuestions: UpdateSurveyQuestions!) {
    updateSurveyQuestions(updateSurveyQuestions: $updateSurveyQuestions) {
       ...OrgFragment
       allowedDomains
       hraClientId
       hraClientSecret
       attachmentHraClientSecret
       attachmentHraClientId
    }
  }
  ${OrgFragment}
`;

export const SAVE_ORG_SURVEY_QUESTION = gql`
  mutation updateSurveyQuestion($updateSurveyQuestion: UpdateSurveyQuestion!) {
    updateSurveyQuestion(updateSurveyQuestion: $updateSurveyQuestion) {
      name
    }
  }
`;


export const ADMIN_INVITE = gql `
  mutation adminInvite($data: AdminInviteInput!) {
    adminInvite(data: $data)
  }
`;

export const BULK_EMPLOYEE_INVITE = gql `
  mutation bulkEmployeeInvite($data: AdminInviteInput!) {
    bulkEmployeeInvite(data: $data)
  }
`;

export const ADMIN_RESET_PASSWORD = gql `
  mutation openResetPasswordTicket($email: String!, $connectionId: String!) {
    openResetPasswordTicket(email: $email, connectionId: $connectionId)
  }
`;

export const UPDATE_POLICY = gql`
  mutation updatePolicy($text: String!) {
    updatePolicy(text: $text) {
      agreement
      createdDate
    }
  }
`;

export const UPDATE_TERMS = gql`
  mutation updateTerms($text: String!) {
    updateTerms(text: $text) {
      agreement
      createdDate
    }
  }
`;

export const SHAREDWITH_FIELD_MIGRATION = gql`
  mutation addSharedWithField($orgId: ID!) {
    folderSharedWithMigration(orgId: $orgId) {
      ok
      error
      status
      chunkIndex
    }
  }
`

export const ADD_ALLOW_PERSONAL_EMAIL_FLAG = gql`
  mutation addCanUpdatePersonalEmailFlag {
    addCanUpdatePersonalEmailFlag {
      matchedCount
      modifiedCount
      upsertedId
      acknowledged
    }
  }
`

export const ADD_SHOW_SUPPORT_TAB_BOOLEAN = gql`
    mutation addShowSupportTabBoolean {
      addShowSupportTabBoolean {
        matchedCount
        modifiedCount
      }
    }
  `

export const ADD_SHOW_RESOURCES_TAB_BOOLEAN = gql`
	mutation addShowResourcesTabBoolean {
		addShowResourcesTabBoolean {
			matchedCount
			modifiedCount
		}
	}
`

export const UPDATE_FOLDER_RESOLUTION_SCHEMA = gql`
	mutation updateFolderResolutionSchema {
		updateFolderResolutionSchema {
			matchedCount
			modifiedCount
		}
	}
`
export const UPDATE_SHARED_WITH_SCHEMA_FOR_UNSHARING = gql`
  mutation updateSharedWithSchema {
      updateSharedWithSchema {
        id
        sharedWith {
          email
          expiryDate
          isActive
          unsharedBy
          sharedBy
          shareMethod
          _id
          userId
          notificationsEnabled
        }
      }
    }
  `