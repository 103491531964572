
import Vue, { computed, defineComponent, getCurrentInstance, ref } from "vue";

import { SEND_EMAIL } from "../../graphql/emailQueries";

import { useStore } from "vuex";
import { butter } from "@/plugins/butter-plugin";
import { provideApolloClient, useMutation } from "@vue/apollo-composable";
import { Client } from "@/apolloClient";
import { useAuth0 } from "@auth0/auth0-vue";

// @Component({
//   components: {}
// })
// export default class Feedback extends Vue {
export default defineComponent({
  name: "Feedback",
  setup() {
    const feedbackRating = ref<Number>(5);
    const feedbackText = ref<string>("");
    let sent = ref<Boolean>(false);
    const FEEDBACK_EMAIL = ref<any>(process.env.VUE_APP_FEEDBACK_EMAIL || "");
    const butterData = ref<any>(null);
    const isLoadingButterData = ref<boolean>(true);
    const store = useStore();
    const auth = useAuth0();
    const rate = (rating) => {
      feedbackRating.value = rating;
    };
    const instance = getCurrentInstance()
    const userId = computed(() => {
      return store.getters.getUserId;
    });
    const onShow = () => {
      sent.value = false;
      feedbackRating.value = 5;
      feedbackText.value = "";
      getButterData();
    };
    const close = () => {
      // this.$bvModal.hide('feedbackModal');
      instance?.emit("close")
    };
    async function sendEmail() {
      const { mutate, onDone, onError } = await provideApolloClient(
        Client(auth)
      )(() => useMutation(SEND_EMAIL));
      await mutate({
        sendEmailInput: {
          body:
            "Feedback rating = " +
            feedbackRating.value +
            `
                ` +
            feedbackText.value,
          email: FEEDBACK_EMAIL.value,
          subject: "Feedback",
        },
      });

      onDone((res) => {
        sent = res.data.sendEmail.sent;
      });
    }
    async function getButterData() {
      let butterData: any = await butter.page.retrieve("*", "product-contents");
      butterData =
        butterData.data?.data.fields?.feedback_for_speakfully_components;
      isLoadingButterData.value = false;
    }
    return {
      rate,
      feedbackRating,
      feedbackText,
      butterData,
      sent,
      close,
      onShow,
      sendEmail,
    };
  },
});
