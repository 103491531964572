// import Vue from 'vue';
// import dayjs from '@/plugins/dayjs.plugin';

// Vue.filter('capitalize', function (value) {
//   if (!value) return ''
//   value = value.toString()
//   return value.charAt(0).toUpperCase() + value.slice(1)
// })

// Vue.filter('uppercase', function (value) {
//   if (!value) return ''
//   value = value.toString()
//   return value.toUpperCase()
// })

// Vue.filter('toInitials', function (value) {
//   if (!value) return ''
//   if (!value.includes('@')) {
//     return value
//     .split(" ")
//     .map(d => {
//       return d[0];
//     })
//     .join("");
//   } else {
//     let sub = value.substring(0, 2)
//     return sub.charAt(0).toUpperCase() + sub.slice(1);
//   }
// })

// Vue.filter('monthDayYear', function (value) {
//   if (!value) return ''
//   return dayjs(value).format("M/D/YY")
// })

// Vue.filter('readableDate', function (value) {
//   if (!value) return ''
//   return dayjs(value).format("D MMMM, YYYY")
// })

// Vue.filter('dateAndTime', function (value) {
//   if (!value) return ''
//   return dayjs(value).format('MMMM Do YYYY, h:mma');
// })

// Vue.filter('statusName', function (value) {
//   if (!value) return ''
//   switch(value.toLowerCase()) {
//     case 'saved':
//       return "Not Submitted"
//       break;
//     case 'submitted':
//       return 'New Submitted';
//       break;
//     case 'opened':
//       return 'In Review'
//       break;
//     case 'in progress':
//       return 'In Review'
//       break;
//     case 'closed':
//       return 'Closed';
//       break;
//     case 'shared':
//       return 'Shared';
//       break;
//     case 'all':
//       return 'All';
//       break;
//     default:
//       return '';
//   }
// })

// Vue.filter('displayRole', function (value) {
//   switch(value.toLowerCase()) {
//     case 'hradmin':
//       return "Team Admin"
//       break;
//     case 'hrviewer':
//       return 'Team Member';
//       break;
//     case 'orguser':
//       return 'Employee'
//       break;
//     case 'speakfullyadmin':
//       return 'Super Admin';
//       break;
//     default:
//       return '';
//   }
// })
import dayjs from "@/plugins/dayjs.plugin";

export function capitalize(value) {
  if (!value) return "";
  value = value.toString();
  return value.charAt(0).toUpperCase() + value.slice(1);
}

export function uppercase(value) {
  if (!value) return "";
  value = value.toString();
  return value.toUpperCase();
}

export function toInitials(value) {
  if (!value) return "";
  if (!value.includes("@")) {
    return value
      .split(" ")
      .map((d) => {
        return d[0];
      })
      .join("");
  } else {
    let sub = value.substring(0, 2);
    return sub.charAt(0).toUpperCase() + sub.slice(1);
  }
}

export function monthDayYear(value) {
  if (!value) return "";
  return dayjs(value).format("M/D/YY");
}

export function readableDate(value) {
  if (!value) return "";
  return dayjs(value).format("D MMMM, YYYY");
}

export function dateAndTime(value) {
  if (!value) return "";
  return dayjs(value).format("MMMM Do YYYY, h:mma");
}

export function statusName(value) {
  if (!value) return "";
  switch (value.toLowerCase()) {
    case "saved":
      return "Not Submitted";
    case "submitted":
      return "New Submitted";
    case "opened":
    case "in progress":
      return "In Review";
    case "closed":
      return "Closed";
    case "shared":
      return "Shared";
    case "all":
      return "All";
    default:
      return "";
  }
}

export function displayRole(value) {
  switch (value.toLowerCase()) {
    case "hradmin":
      return "Team Admin";
    case "hrviewer":
      return "Team Member";
    case "orguser":
      return "Employee";
    case "speakfullyadmin":
      return "Super Admin";
    default:
      return "";
  }
}
