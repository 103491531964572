import gql from "graphql-tag";

export const SEND_EMAIL = gql`
  mutation sendEmail($sendEmailInput: SendEmailInput!) {
    sendEmail(sendEmailInput: $sendEmailInput) {
      sent
    }
  }
`;

export const REFER_EMAIL = gql`
  mutation referEmail($referEmailInput: ReferEmailInput!) {
    referEmail(referEmailInput: $referEmailInput) {
      sent
    }
  }
`;

export const GET_METADATA = gql`
  query getMetaData($url: String!) {
    getMetaData(url: $url)
  }
`;

export const NUDGE_SURVEY_RECIPIENTS = gql`
  mutation nudgeSurveyRecipients($nudgeSurveyData: NudgeSurveyInput!) {
    nudgeSurveyRecipients(nudgeSurveyData: $nudgeSurveyData)
  }
`;
