
import Vue, {
  computed,
  defineComponent,
  getCurrentInstance,
  onBeforeMount,
  ref,
} from "vue";

import { REFER_EMAIL } from "../../graphql/emailQueries";
import { butter } from "@/plugins/butter-plugin";
import { useStore } from "vuex";
import { useAuth0 } from "@auth0/auth0-vue";
import { referIndId } from "@/static/emailTemplates";
import { useMutation } from "@vue/apollo-composable";

enum Recipient {
  FRIEND = "FRIEND",
  ORG = "ORG",
}

export default defineComponent({
  name: "Refer",
  setup() {
    const orgEmail = ref("");
    const friendEmail = ref("");
    const message = ref("");
    const orgSent = ref(false);
    const friendSent = ref(false);
    const orgReferral = ref(false);
    const inviteTypeSelected = ref(false);
    const butterData = ref(null);
    const store = useStore();
    const auth = useAuth0();
    const instance = getCurrentInstance();
    const referModal = ref(false);
    onBeforeMount(async () => {
      await getButterData();
    });

    const validateEmail = (email: any) => {
      var re =
        /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      return re.test(email);
    };

    const userId = computed(() => {
      return store.getters.getUserId;
    });

    const orgId = () => {
      return store.getters.getUserOrgId;
    };

    const onShow = () => {
      friendSent.value = false;
      orgSent.value = false;
      referModal.value = true;
    };

    const close = () => {
      referModal.value = false;
      instance?.emit("close");
      orgReferral.value = false;
      inviteTypeSelected.value = false;
    };

    const back = () => {
      inviteTypeSelected.value = false;
      orgEmail.value = "";
      friendEmail.value = "";
      message.value = "";
    };

    const typeSelection = (bool: any) => {
      inviteTypeSelected.value = true;
      orgReferral.value = bool;
    };

    async function sendOrg() {
      const { mutate, onDone } = useMutation(REFER_EMAIL);
      mutate({
        referEmailInput: {
          email: orgEmail.value,
          referType: Recipient.ORG,
          message: message.value,
        },
      });
      onDone((data) => {
        orgSent.value = data.data.sent;
        orgEmail.value = "";
        message.value = "";
      });
    }

    async function sendFriend() {
      const { mutate, onDone } = useMutation(REFER_EMAIL);
      mutate({
        referEmailInput: {
          email: friendEmail.value,
          referType: Recipient.FRIEND,
          senderName: auth.user.value?.name,
          message: message.value,
        },
      });
      onDone((data) => {
        friendSent.value = data.data.sent;
        friendEmail.value = "";
        message.value = "";
      });
    }
    async function getButterData() {
      let butterDataRes: any = await butter.page.retrieve(
        "*",
        "product-contents"
      );
      butterData.value = butterDataRes.data?.data.fields;
    }
    return {
      orgReferral,
      friendEmail,
      validateEmail,
      sendFriend,
      close,
      orgEmail,
      orgSent,
      butterData,
      message,
      sendOrg,
      friendSent,
      onShow,
      typeSelection,
      inviteTypeSelected,
      referModal,
      back,
    };
  },
});
