
import { useAuth0 } from "@auth0/auth0-vue";
import { useRole } from "@/plugins/rolePlugin";
import { computed } from "vue";
import { useStore } from "vuex";
import { useRoute } from "vue-router";
export default {
  setup() {
    const store = useStore();
    const auth: any = useAuth0();
    const route = useRoute();
    const role = useRole();
    const logoutUrl = computed(() => {
      return store.getters.getOrgLogoutUrl;
    });

    function logout() {
      auth.quickLogOut({ returnTo: logoutUrl.value });
    }

    return {
      logoutUrl,
      logout,
      auth,
      role,
      route
    };
  },
};
