import gql from "graphql-tag";
import { FolderFragment, OrgFragment } from "./fragments";
//USER SERVER QUERIES

export const UPDATE_USER = gql`
  mutation updateUser($updateUserData: UpdateUserInput!) {
    updateUser(updateUserData: $updateUserData) {
      id
    }
  }
`;
export const ADD_USER = gql`
  mutation addUser($newUserData: NewUserInput!) {
    addUser(newUserData: $newUserData) {
      id
      email
    }
  }
`;

export const GET_USER = gql`
  query getUser {
    getUser {
      id
      email
      personalEmail
      notificationTemplates
      assignedTags {
        tag
        _id
        category
      }
      org {
        ...OrgFragment
      }
      folders {
        ...FolderFragment
      }
    }
  }
  ${OrgFragment}
  ${FolderFragment}
`;


export const GET_USER_SURVEYS = gql`
  query getUserSurveys {
    user {
      surveysAwaitingResponse {
        id
        title
        startDate
        endDate
        userIds
        createdBy
        createdDate
        questions {
          priority
          groupLabel
          questionType
          label
          multi
          instruction
          text: questionText
          answers: answerOptions {
            displayTextField
            displayTextFieldLabel
            label: answerText
            iconClass: media
            questions {
              groupLabel
              questionType
              label
              multi
              instruction
              text: questionText
              answers: answerOptions {
                displayTextField
                displayTextFieldLabel
                label: answerText
                iconClass: media
              }
              skippable
            }
          }
          skippable
        }
        response {
          id
          surveyId
          userId
          answers {
            textFieldValue
            questionLabel
            questionType
            questionGroupLabel
            option
            otherResponse
            }
          submitted
          anonymous
          submittedDate
        }
      }
    }
  }
`;

export const VIEW_OR_RESPOND_TO_SURVEY = gql`
  mutation viewOrRespondToSurvey($surveyResponseData: NewSurveyResponseInput!) {
    viewOrRespondToSurvey(surveyResponseData: $surveyResponseData) {
      id
      surveyId
      userId
      answers {
        textFieldValue
        questionLabel
        questionType
        questionGroupLabel
        option
        otherResponse
      }
      submitted
      anonymous
      submittedDate
    }
  }
`;

export const GET_USER_ROLE = gql`
  query getRole($fullId: String!) {
    getRole(fullId: $fullId)
  }
`;

export const UPDATE_USER_ROLE = gql`
  mutation updateUserRole($updateUserRoleData: UpdateUserRoleInput!) {
    updateUserRole(updateUserRoleData: $updateUserRoleData)
  }
`;

export const ADD_USER_PROPERTIES = gql`
  mutation addUserProperties {
    addUserProperties
  }
`;

export const GET_TERMS = gql`
  query getTerms {
    getTerms {
      id
      agreement
    }
  }
`;

export const GET_EMPLOYEE_DATA = gql`
    query getEmployeeData {
      getEmployeeData {
        name
        role
        authId
        email
        userId
        blocked
        externalOrgs {
          orgId
          isActive
        }
        assignedTags {
            tag
            _id
            category
            isActive
        }
        userProperties {
          filterFactor {
            factor
            group
            order
          }
          value
        }
      }
    }
  `

export const USER_LOGIN = gql`
  query loginEvent($accessToken: String!) {
    loginEvent(accessToken: $accessToken)
  }
`;


export const RESET_PASSWORD = gql`
  mutation resetPassword($email: String!) {
    resetPassword(email: $email)
  }
`;

export const GET_POLICY = gql`
  query getPolicy {
    getPolicy {
      id
      agreement
    }
  }
`;

export const ADD_USER_TERMS = gql`
  mutation addUserTerms($auth0Triggered: Boolean! $email: String!, $authId: String!, $orgName: String) {
    addUserTerms(auth0Triggered: $auth0Triggered, email: $email, orgName: $orgName, authId: $authId) {
      id
      userId
      termsId
      policyId
    }
  }
`;

export const SEND_EXTERNAL_EMAIL = gql`
  mutation sendExternalEmail($sendExternalData: sendExternalInput!) {
    sendExternalEmail(sendExternalData: $sendExternalData)
  }
`;

export const BEGIN_TRIAL = gql`
  mutation beginTrial($newTrialData: NewTrialInput!) {
    beginTrial(newTrialData: $newTrialData) {
      id
    }
  }
`;

export const SEND_USER_INVITE = gql`
  mutation sendUserInvite($inviteUserData: InviteUserInput!) {
    sendUserInvite(inviteUserData: $inviteUserData)
  }
`;

export const HUBSPOT_LEAD = gql`
  mutation sendHubspotLead($hubspotData: HubspotLeadInput!) {
    sendHubspotLead(hubspotData: $hubspotData)
  }
`;

export const CONTACT_SALES = gql`
  mutation contactSales($contactSalesData: ContactSalesInput!) {
    contactSales(contactSalesData: $contactSalesData)
  }
`;

export const RESET_TRIAL_DATA = gql`
  mutation resetTrialData($orgId: String!) {
    resetTrialData(orgId: $orgId)
  }
`;

export const DELETE_TRIAL_DATA = gql`
  mutation deleteTrialData($orgId: String!) {
    deleteTrialData(orgId: $orgId)
  }
`;

// TODO : remove after auth0 verify email page is ready
export const VERIFY_EMAIL = gql`
  mutation sendVerifyEmail($authId: String!) {
    sendVerifyEmail(authId: $authId)
  }
`;

export const DELETE_ORG_USER = gql`
  mutation deleteOrgUser($deleteOrgUserData: DeleteOrgUserInput!) {
    deleteOrgUser(deleteOrgUserData: $deleteOrgUserData)
  }
`;

export const GET_USER_NOTIFICATIONS = gql`
  query getNotificationsByUser {
    getNotificationsByUser {
      message
      createdDate
      read
    }
  }
`;

export const MARK_USER_NOTIFICATIONS_READ = gql`
  mutation markAsReadByUser($userId: String!) {
    markAsReadByUser(userId: $userId)
  }
`;

export const UPDATE_AUTH0_USER = gql`
  mutation updateAuth0User($updateAuth0UserData: updateAuth0UserInput!) {
    updateAuth0User(updateAuth0UserData: $updateAuth0UserData)
  }
`;

export const GET_AUTH0_MANAGEMENT_TOKEN = gql`
  query getAuth0ManagementToken {
    getAuth0ManagementToken
  }
`;

//USER CACHE QUERIES

export const NOTIFICATIONS_SUBSCRIPTION = gql`
  subscription onNotificationAdded($userId: String!) {
    notificationAdded(userId: $userId) {
      id
      message
      createdDate
      read
    }
  }
`;
