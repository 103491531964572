
import Vue, {
  computed,
  defineComponent,
  inject,
  onBeforeMount,
  onBeforeUnmount,
  onMounted,
  ref,
  toRaw,
} from "vue";
import Header from "@/components/layout/Header.vue";
import Footer from "@/components/layout/Footer.vue";
import SideNav from "@/components/layout/SideNav.vue";
import CSESideNav from "@/components/cse/CSESideNav.vue";
import MobileNav from "@/components/layout/MobileNav.vue";
import CSEMobileNav from "@/components/cse/CSEMobileNav.vue";
import ToolbarMenu from "@/components/layout/ToolbarMenu.vue";
import localize from "./localize.js";
import "@/assets/custom.scss";
import { useStore } from "vuex";
import { useRoute } from "vue-router";
import { useAuth0 as useAuth } from "@auth0/auth0-vue";
import { AuthUser } from "./auth/types/authUser";

export default defineComponent({
  name: "App",
  components: {
    Header,
    Footer,
    SideNav,
    CSESideNav,
    MobileNav,
    CSEMobileNav,
    ToolbarMenu,
  },
  
  setup() {
    let profile: any;
    let toolbarOpen = ref<boolean>(false);
    let toolbarActive = ref<any>(null);
    let mobileNavOpen = ref<boolean>(false);
    let unreadCount: number = 0;
    const store = useStore();
    const route = useRoute();
    const auth = useAuth();
    const isDone = ref(false);
    const authUser = ref(auth?.user.value);
    const { loginWithRedirect } = auth;
    function toggleNav() {
      mobileNavOpen.value = !mobileNavOpen.value;
    }
    function toggleToolbar(active: number | null) {
      if (active == toolbarActive.value && toolbarOpen) {
        toolbarOpen.value = !toolbarOpen;
        if (!toolbarOpen) {
          toolbarActive.value = null;
        }
      } else if (active == null) {
        toolbarOpen.value = false;
        toolbarActive.value = null;
      } else {
        toolbarOpen.value = true;
        toolbarActive.value = active;
      }
    }

    const showLayout = computed(() => {
      let isMultilingual = computed(() => store.getters.getOrgIsMultilingual);
      var publicPages = [
        "/signup",
        "/logout",
        "/tcp",
        "/verify-email",
        "/export/folder",
      ];
      var show = publicPages.indexOf(route.path) == -1;
      if (!auth.isAuthenticated.value) {
        return false;
      } else {
        isMultilingual ? localize() : null;
        return show;
      }
    });

    const loading = computed(() => {
      const x = store.getters.getAppLoading;
      return x;
    });

    const isCSE = computed(() => {
      // Uncomment this line, switch the auth0 connection string to 'JohnTest' before pushing to dev
      return auth.user.value?.connection?.toLowerCase() === "cse";
      //return this.$store.getters.getAuth0Connection.toLowerCase() === 'username-password-authentication';
    });
    const onBeforeUnload = () => {
      let user = computed(() => store.getters.getCurrentGqlUser);
      let data: any = {};
      // const authUser = ref(auth?.user.value);
      const last_login =
        authUser.value && authUser.value["https://speakfully.com/last_login"];
      data.duration = new Date().getTime() - last_login;
      data.loginCount =
        authUser.value && authUser.value["https://speakfully.com/logins_count"];
      if (user.value) {
        store.dispatch("amplitudeTrack", { data, eventName: "Logout" });
      }
    };

    onBeforeUnmount(() => {
      window.removeEventListener("beforeunload", onBeforeUnload);
    });
    window.addEventListener("beforeunload", onBeforeUnload);

    onMounted(async () => {
      if (!auth.isAuthenticated.value && !auth.isLoading.value) {
        loginWithRedirect();
      } else if (!isDone.value) {
        if (!window.location.href.includes('/export/folder')) {
          store.dispatch("getGqlUser", auth);
          isDone.value = true;
        }
      }
      if (
        navigator.userAgent.search("Safari") >= 0 &&
        navigator.userAgent.search("Chrome") < 0
      ) {
        document.getElementsByTagName("BODY")[0].className += " safari";
      }
    });
    return {
      toolbarOpen,
      toggleToolbar,
      toolbarActive,
      mobileNavOpen,
      isCSE,
      toggleNav,
      showLayout,
      loading,
      unreadCount,
      auth,
      route,
    };
  },
});
