import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx, createElementVNode as _createElementVNode, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-2eea895a"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "toolbar" }
const _hoisted_2 = { class: "row-container" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_b_col = _resolveComponent("b-col")!
  const _component_Settings = _resolveComponent("Settings")!
  const _component_Notifications = _resolveComponent("Notifications")!
  const _component_Support = _resolveComponent("Support")!
  const _component_b_row = _resolveComponent("b-row")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_b_row, {
        class: "",
        "no-gutters": ""
      }, {
        default: _withCtx(() => [
          _createVNode(_component_b_col, {
            class: "spacer d-none d-sm-block",
            md: "9",
            onClick: _ctx.handleClose
          }, null, 8, ["onClick"]),
          _createVNode(_component_b_col, {
            class: "menu-container",
            md: "3",
            sm: "12"
          }, {
            default: _withCtx(() => [
              (_ctx.openMenu == 1)
                ? (_openBlock(), _createBlock(_component_Settings, {
                    key: 0,
                    onClose: _ctx.handleClose
                  }, null, 8, ["onClose"]))
                : _createCommentVNode("", true),
              (_ctx.openMenu == 2)
                ? (_openBlock(), _createBlock(_component_Notifications, {
                    key: 1,
                    data: _ctx.Notifications.notif,
                    onClose: _ctx.handleClose,
                    onViewed: _ctx.markAsRead
                  }, null, 8, ["data", "onClose", "onViewed"]))
                : _createCommentVNode("", true),
              (_ctx.openMenu == 3)
                ? (_openBlock(), _createBlock(_component_Support, {
                    key: 2,
                    onClose: _ctx.handleClose
                  }, null, 8, ["onClose"]))
                : _createCommentVNode("", true)
            ], undefined, true),
            _: 1
          })
        ], undefined, true),
        _: 1
      })
    ])
  ]))
}