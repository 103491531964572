
import Vue, { computed, defineComponent, getCurrentInstance, watch } from "vue";

import EventBus from "@/EventBus/eventBus";
import { useStore } from "vuex";
import { useRouter } from "vue-router";
import { useRole } from "@/plugins/rolePlugin";
import { usePlan } from "@/plugins/planPlugin";
import { useAuth0 } from "@auth0/auth0-vue";

export default defineComponent({
  name: "MobileNav",
  props: {
    email: {
      type: String,
    },
    isOpen: {
      type: Boolean,
    },
  },
  setup(props) {
    const store = useStore();
    const auth: any = useAuth0();
    const route = useRouter();
    const role = useRole();
    const plan = usePlan();
    const instance = getCurrentInstance();
    const showResourcesTab = computed(() => {
      return store.getters.getShowResourcesTab;
    });

    const ShowSupportTab = computed(() => {
      return store.getters.getShowSupportTab;
    });

    watch(
      () => route.currentRoute,
      (newVal) => {
        if (props.isOpen) {
          instance?.emit("onClose");
        }
      },
      { deep: true }
    );

    const openResources = (route: string) => {
      EventBus.emit("resources");
      instance?.emit("onClose");
    };

    const close = () => {
      instance?.emit("onClose");
    };
    const logout = () => {
      auth.logout();
    };
    return {
      close,
      logout,
      ShowSupportTab,
      showResourcesTab,
      openResources,
      plan,
      role,
    };
  },
});
