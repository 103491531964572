
import Vue, {
  computed,
  defineComponent,
  onBeforeMount,
  onMounted,
  ref,
} from "vue";

import EventBus from "@/EventBus/eventBus";
import { useStore } from "vuex";
import { butter } from "@/plugins/butter-plugin";

export default defineComponent({
  name: "Resources",
  setup() {
    const show = ref<boolean>(false);
    const image = ref<string>(
      "https://previews.123rf.com/images/stockbroker/stockbroker1111/stockbroker111100001/11183288-business-meeting-in-an-office.jpg"
    );
    const exist = ref<boolean>(false);
    let butterData = ref<any>([]);
    const store = useStore();
    const track = (resource) => {
      const data = {
        url: resource.resourceUrl,
        title: resource.title,
      };
      store.dispatch("amplitudeTrack", {
        data,
        eventName: "ResourceClick",
      });
    };

    const user = computed(() => {
      return store.getters.getCurrentGqlUser;
    });

    const getImage = (item) => {
      if (item.images.length) {
        var title = item.images.filter((x) => x.isTitle)[0];

        if (title) {
          return title.fileUrl;
        }
      }
    };

    const shuffle = (array) => {
      return array.sort(() => Math.random() - 0.5);
    };

    const formatLinks = (link: string) => {
      if (!link) return null;
      if (!link.includes("http://") && !link.includes("https://")) {
        return "http://" + link;
      } else {
        return link;
      }
    };

    const compliedResources = computed(() => {
      const alldata = [...resources.value].concat(butterData);
      const res = shuffle(alldata);
      return res;
    });

    const GetButterStuff = () => {
      butter.page.retrieve("*", "resources").then((res) => {
        butterData = formatButter(res);
      });
    };

    onBeforeMount(() => {
      GetButterStuff();
    });

    const resources = computed(() => {
      return store.getters.getOrgResources;
    });

    const getFile = (item: any) => {
      var file = item.images.filter((a) => !a.isTitle)[0];
      if (file) {
        return file.fileUrl;
      }
    };

    const formatButter = (res) => {
      return res.data.data.fields.resources.map((resource) => {
        return {
          id: Math.round(Math.random() * 100),
          title: resource.title,
          description: resource.description,
          resourceUrl: resource.resourceurl,
          images: [{ fileUrl: resource.image, isTitle: true }],
        };
      });
    };

    const getBackgroundStyle = (res) => {
      let colors = ["#92D6D7", "#E7694B", "#0A193E"];
      if (!res.images || !res.images.filter((x) => x.isTitle).length) {
        let color = colors[Math.floor(Math.random() * colors.length)];
        return { backgroundColor: color };
      }
    };
    const start = () => {
      exist.value = true;
      show.value = true;
    };

    const stop = () => {
      show.value = !show.value;
      EventBus.emit("resourcesClosed");
      setTimeout(() => {
        exist.value = false;
      }, 1000);
    };

    onMounted(() => {
      EventBus.on("resources", () => start());
    });
    return {
      getImage,
      formatLinks,
      getFile,
      track,
      stop,
      getBackgroundStyle,
      exist,
      show,
      compliedResources,
    };
  },
});
