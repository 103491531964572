
import Vue, { defineComponent, ref } from "vue";

export default defineComponent({
  name: "UpgradeRequestConfirmation",
  setup() {
    const showUpgradeRequestModal = ref(false);
    const show = () => {
      showUpgradeRequestModal.value = true;
    };

    const hide = () => {
      showUpgradeRequestModal.value = false;
    };
    return {
      show,
      hide,
      showUpgradeRequestModal,
    };
  },
});
