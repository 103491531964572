// import { ApolloClient } from "apollo-client";
import { createUploadLink } from "apollo-upload-client";
import {
  HttpLink,
  InMemoryCache,
  // IntrospectionFragmentMatcher,
  ApolloClient,
  ApolloLink,
} from "@apollo/client/core";
import { fromPromise, split, GraphQLRequest, concat } from "apollo-link";
import { useAuth0, Auth0VueClient } from "@auth0/auth0-vue";
import { setContext } from "@apollo/client/link/context";
import { onError } from "@apollo/client/link/error";
import { ServerError } from "apollo-link-http-common";
import { watch } from "vue";
// const authService = getInstance();
// const authService = useAuth0();

let isRefreshing: boolean = false;
let pendingRequests: any[] = [];
let token: string | null;

const getNewToken = async (auth0: Auth0VueClient) => {
  const result = await auth0?.getAccessTokenSilently({
    authorizationParams: {
      redirect_uri: process.env.VUE_APP_PRODUCT_URL,
      ignoreCache: true,
    },
  });
  return result;
};

const getHeaders = async (auth0: Auth0VueClient) => {
  // const authService = useAuth0();
  const getToken = async () => {
    try {

    const result = await auth0?.getAccessTokenSilently({
      authorizationParams: {
        redirect_uri: process.env.VUE_APP_PRODUCT_URL,
        ignoreCache: true,
      },
    });
    return result;
    } catch (error) {
        await auth0.loginWithRedirect({
          appState: {
            targetUrl: window.location.pathname,
          }
        });
      throw error;
    }

  };
  // If loading has already finished, check our auth state using `getToken()`
  if (!auth0?.isLoading.value) {
    return await getToken();
  }
  // Watch for the loading property to change before we check isAuthenticated
  // authService.$watch("loading", async (loading) => {
  //   if (loading === false) {
  //     return await getToken();
  //   }
  // });
  watch(
    () => auth0?.isLoading.value,
    async (newValue: any, oldValue) => {
      if (newValue === false) {
        return await getToken();
      }
    }
  );
};

const errorLink = (auth0: Auth0VueClient) => onError(
  ({ graphQLErrors, networkError, operation, forward }) => {
    if (graphQLErrors) {
      for (const err of graphQLErrors as any[]) {
        if (
          err.extensions?.code === "UNAUTHENTICATED" ||
          err.extensions?.response?.statusCode === 401
        ) {
          console.log("Unauthenticated API Request");
          let forward$;
          if (!isRefreshing) {
            isRefreshing = true;
            forward$ = fromPromise(
              getNewToken(auth0)
                .then((accessToken: any) => {
                  token = accessToken;
                  return forward(operation);
                })
                .catch((error) => {
                  console.log(error);
                  pendingRequests = [];

                  const product_url = process.env.VUE_APP_PRODUCT_URL;
                  auth0?.logout({
                    openUrl(url: any) {
                      url = product_url?.toString();
                      window.location.replace(url);
                    },
                    // openUrl: process.env.VUE_APP_PRODUCT_URL,
                  });
                })
                .finally(() => {
                  isRefreshing = false;
                })
            ).filter((value) => Boolean(value));
          } else {
            // Will only emit once the Promise is resolved
            forward$ = fromPromise(
              new Promise<void>((resolve) => {
                pendingRequests.push(() => resolve());
              })
            );
          }
          return forward$.flatMap(() => forward(operation));
        }
      }
    }
    if (networkError) {
      console.log(`[Network error]: ${networkError}`);
      if (
        networkError.name === "ServerError" &&
        (networkError as ServerError).statusCode === 401
      ) {
        // remove cached token on 401 from the server
        token = null;
      }
    }
  }
);

// const fragmentMatcher = new IntrospectionFragmentMatcher({
//   introspectionQueryResultData,
// });
const httpLink = new HttpLink({
  uri: process.env.VUE_APP_GRAPHQL_ENDPOINT,
});

const authLink =(auth0) => setContext(async (_operation, { headers }) => {
  if (window.location.href.includes("/export/folder")) {
    const userToken = token || localStorage.getItem("ACCESS_TOKEN");
    return {
      headers: {
        ...headers,
        authorization: userToken,
        exportauth: localStorage.getItem("EXPORT_AUTH") || null,
      },
    };
  } else {
    const userToken = token || (await getHeaders(auth0));
    return {
      headers: {
        ...headers,
        authorization: userToken,
        exportauth: localStorage.getItem("EXPORT_AUTH") || null,
        dangerzone: localStorage.getItem("ADMIN_TOKEN") || null,
      },
    };
  }
});

// const wsLink = new WebSocketLink({
//   uri: process.env.VUE_APP_GQL_WS as string,
//   options: {
//     reconnect: true,
//     timeout: 30000,
//     connectionParams: async () => {
//       const userToken = token || await getHeaders();
//       return { headers: { authorization: userToken } }
//     }
//   }
// });

const link = (auth0) => ApolloLink.from([errorLink(auth0), authLink(auth0), httpLink]);

// const splitLink = ApolloLink.split(
//   ({ query }) => {
//     const definition = getMainDefinition(query);
//     return (
//       definition.kind === "OperationDefinition" &&
//       definition.operation === "subscription"
//     );
//   },
//   authLink,
//   errorLink,
//   httpLink
// );

export const Client =  (auth0) => new ApolloClient({
  link: link(auth0),
  cache: new InMemoryCache({
    addTypename: true,
    // fragmentMatcher,
  }),
  connectToDevTools: true,
});

export async function getAccessToken (auth0) {
  const res = token || await getHeaders(auth0);
  return res;
}
