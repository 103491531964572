
import router from "@/router";
import Vue, { defineComponent, getCurrentInstance } from "vue";

// @Component
// export default class Support extends Vue {
export default defineComponent({
  name: "Support",
  setup() {
    const instance = getCurrentInstance();
    const close = () => {
      instance?.emit("close");
    };

    const goTo = (sec: number) => {
      router.push({ name: "support", params: { section: sec } });
      close();
    };
    return {
      close,
      goTo,
    };
  },
});
