import "./class-component-hooks";
import App from "./App.vue";
import router from "./router";
import { store } from "./store";
import RolePlugin from "./plugins/rolePlugin";
import { PlanPlugin } from "./plugins/planPlugin";
// import butterPlugin from "./plugins/butter-plugin";
import "@/plugins/dayjs.plugin";
import { Client } from "./apolloClient";
import CKEditor from "@ckeditor/ckeditor5-vue";
import Localize from "./localize.js";
import VueGtm from "vue-gtm";
import { BootstrapVue } from "bootstrap-vue";
import "@/utils/formatting.filters";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap-vue/dist/bootstrap-vue.css";
import "mutationobserver-shim";
import "@/global-styles/highcharts-options";
import "@/global-styles/globals.scss";
import "@/global-styles/typography.scss";
import "@/global-styles/table.scss";
import HighchartsVue from "highcharts-vue";
import Highcharts from "highcharts";
import { createAuth0, useAuth0 } from "@auth0/auth0-vue";
// import { createGtm } from "@gtm-support/vue-gtm";
import { LayoutPlugin, TablePlugin } from "bootstrap-vue";
import { createApolloProvider } from "@vue/apollo-option";
import { DefaultApolloClient } from "@vue/apollo-composable";
import { createApp, h, provide } from "vue";

import ElementPlus from 'element-plus';
import "element-plus/dist/index.css";

function skipRedirect(window: Window & typeof globalThis) {
  const res = window.location.pathname.includes("/export/folder")
  return res;

}

export const auth0 = createAuth0({
  domain: process.env.VUE_APP_AUTH0_DOMAIN ?? "",

  clientId: process.env.VUE_APP_AUTH0_CLIENTID ?? "",
  // useRefreshTokens: true,
  // cacheLocation: "localstorage",
  authorizationParams: {
    redirect_uri: process.env.VUE_APP_PRODUCT_URL,
    audience: process.env.VUE_APP_AUTH0_AUDIENCE,

  },
}, {
  skipRedirectCallback: skipRedirect(window),
});
const app = createApp({
  setup() {
    provide(DefaultApolloClient, Client(auth0));
  },

  render: () => h(App),
  },
);


app
  .use(HighchartsVue as any)
  .use(BootstrapVue as any)
  .use(CKEditor)
  .use(router)
  .use(auth0)
  .use(store)
  .use(ElementPlus)


  // .use(Auth0Plugin, {
  //   domain: process.env.VUE_APP_AUTH0_DOMAIN,
  //   clientId: process.env.VUE_APP_AUTH0_CLIENTID,
  //   audience: process.env.VUE_APP_AUTH0_AUDIENCE,
  //   onRedirectCallback: () => (appState) => {
  //     router.push(
  //       appState && appState.targetUrl
  //         ? appState.targetUrl
  //         : window.location.pathname
  //     );
  //   },
  // })

  .use(RolePlugin, auth0)
  .use(PlanPlugin, store)
  .use(TablePlugin as any)
  .use(LayoutPlugin as any)
  .use(Localize)
  // Enables hot reload in dev when using keep-alive component
  // .use(VueKeepAliveDev, {
  //   environment: "development",
  // })

  .mount("#app");
