import gql from "graphql-tag";
import { MessageFragment } from './fragments';

export const GET_FEEDBACK_BY_USER = gql`
  query getFeedbackByUser {
    getFeedbackByUser {
      id
      orgId
      userId
      feedbackType
      answers {
        textFieldValue
        questionLabel
        questionType
        questionGroupLabel
        option
        otherResponse
      }
      anonymous
      submittedDate
      archived
      messages {
        ...MessageFragment
      }
    }
  }
${MessageFragment}
`;

export const GET_FEEDBACK_BY_ORG = gql`
  query getFeedbackByOrg {
    getFeedbackByOrg {
      id
      orgId
      userId
      feedbackType
      answers {
        textFieldValue
        questionLabel
        questionType
        questionGroupLabel
        option
        otherResponse
      }
      anonymous
      submittedDate
      archived
      messages {
        ...MessageFragment
      }
    }
  }
${MessageFragment}
`;

export const CREATE_FEEDBACK = gql`
  mutation createFeedback($newFeedbackData: NewFeedbackInput!) {
    createFeedback(newFeedbackData: $newFeedbackData) {
      id
      feedbackType
      userId
      answers {
        textFieldValue
        questionLabel
        questionType
        questionGroupLabel
        option
        otherResponse
      }
      anonymous
      submittedDate
      archived
    }
  }
`;

export const GET_FEEDBACK_MESSAGES = gql`
  query getFeedbackMessages($feedbackId: String!) {
    getFeedbackMessages(feedbackId: $feedbackId) {
      id
      archived
      anonymous
      userId
      messages {
       ...MessageFragment
      }
    }
  }
${MessageFragment}
`;

export const TOGGLE_FEEDBACK_ARCHIVED = gql`
  mutation toggleFeedbackArchived($feedbackId: String!, $archived: Boolean!) {
    toggleFeedbackArchived(feedbackId: $feedbackId, archived: $archived) {
      id
      orgId
      userId
      feedbackType
      answers {
        textFieldValue
        questionLabel
        questionType
        questionGroupLabel
        option
        otherResponse
      }
      anonymous
      submittedDate
      archived
      messages {
       ...MessageFragment
      }
    }
  }
${MessageFragment}
`;

export const CREATE_FEEDBACK_MESSAGE = gql`
  mutation createFeedbackMessage($messageData: MessageInput!) {
    createFeedbackMessage(messageData: $messageData) {  
      anonymous
      message {
        ...MessageFragment
      }
    }
  }
${MessageFragment}
`;