import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, withCtx as _withCtx, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "app" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Header = _resolveComponent("Header")!
  const _component_router_view = _resolveComponent("router-view")!
  const _component_b_row = _resolveComponent("b-row")!
  const _component_b_overlay = _resolveComponent("b-overlay")!
  const _component_CSESideNav = _resolveComponent("CSESideNav")!
  const _component_SideNav = _resolveComponent("SideNav")!
  const _component_b_col = _resolveComponent("b-col")!
  const _component_ToolbarMenu = _resolveComponent("ToolbarMenu")!
  const _component_MobileNav = _resolveComponent("MobileNav")!
  const _component_CSEMobileNav = _resolveComponent("CSEMobileNav")!
  const _component_b_container = _resolveComponent("b-container")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_ctx.showLayout)
      ? (_openBlock(), _createBlock(_component_Header, {
          key: 0,
          onOnToggleNav: _ctx.toggleNav,
          onOnToggleToolbar: _ctx.toggleToolbar,
          navOpen: _ctx.mobileNavOpen,
          unread: _ctx.unreadCount,
          activeMenu: _ctx.toolbarActive
        }, null, 8, ["onOnToggleNav", "onOnToggleToolbar", "navOpen", "unread", "activeMenu"]))
      : _createCommentVNode("", true),
    (_ctx.route.name === 'exportfolder')
      ? (_openBlock(), _createBlock(_component_b_row, {
          key: 1,
          "no-gutters": ""
        }, {
          default: _withCtx(() => [
            _createVNode(_component_router_view)
          ], undefined, true),
          _: 1
        }))
      : (_openBlock(), _createBlock(_component_b_row, {
          key: 2,
          class: "full-height",
          "no-gutters": ""
        }, {
          default: _withCtx(() => [
            _createVNode(_component_b_overlay, {
              id: "overlay-background",
              show: _ctx.loading,
              variant: "light",
              "spinner-variant": "primary",
              opacity: "0.90",
              blur: "5px",
              rounded: "md",
              "no-wrap": ""
            }, null, 8, ["show"]),
            (_ctx.showLayout)
              ? (_openBlock(), _createBlock(_component_b_col, {
                  key: 0,
                  class: "col-lg-auto col-mg-auto col-sm-auto d-none d-sm-block"
                }, {
                  default: _withCtx(() => [
                    (_ctx.isCSE)
                      ? (_openBlock(), _createBlock(_component_CSESideNav, { key: 0 }))
                      : (_openBlock(), _createBlock(_component_SideNav, { key: 1 }))
                  ], undefined, true),
                  _: 1
                }))
              : _createCommentVNode("", true),
            _createVNode(_component_b_col, null, {
              default: _withCtx(() => [
                _createVNode(_component_b_container, {
                  fluid: "",
                  class: "bgc p-0 m-0"
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_router_view, { class: "router-view" }),
                    (_ctx.toolbarOpen)
                      ? (_openBlock(), _createBlock(_component_ToolbarMenu, {
                          key: 0,
                          onClose: _ctx.toggleToolbar,
                          active: _ctx.toolbarActive
                        }, null, 8, ["onClose", "active"]))
                      : _createCommentVNode("", true),
                    (_ctx.mobileNavOpen && !_ctx.isCSE)
                      ? (_openBlock(), _createBlock(_component_MobileNav, {
                          key: 1,
                          onOnClose: _ctx.toggleNav,
                          email: _ctx.auth.user.value?.email,
                          isOpen: _ctx.mobileNavOpen
                        }, null, 8, ["onOnClose", "email", "isOpen"]))
                      : _createCommentVNode("", true),
                    (_ctx.mobileNavOpen && _ctx.isCSE)
                      ? (_openBlock(), _createBlock(_component_CSEMobileNav, {
                          key: 2,
                          onOnClose: _ctx.toggleNav,
                          email: _ctx.auth.user.value?.email,
                          isOpen: _ctx.mobileNavOpen
                        }, null, 8, ["onOnClose", "email", "isOpen"]))
                      : _createCommentVNode("", true)
                  ], undefined, true),
                  _: 1
                })
              ], undefined, true),
              _: 1
            })
          ], undefined, true),
          _: 1
        }))
  ]))
}