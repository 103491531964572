import Vue from "vue";
import { createRouter, createWebHistory, createWebHashHistory } from "vue-router";
// import store from "./store";
import Client from "./apolloClient";
import { SPEAKFULLY_ADMIN_TOKEN } from "@/graphql/dangerZoneQueries";
// import { AuthGuard } from "./auth/auth.guard";
import { createAuthGuard, useAuth0 } from "@auth0/auth0-vue";
import { useStore } from "vuex";
const AuthGuard = createAuthGuard();
// Vue.use(Router);
const store = useStore();
const router = createRouter({
  // mode: "history",

  history: createWebHistory(),
  base: process.env.BASE_URL,
  routes: [
    {
      path: "/",
      redirect: {
        name: "dashboard",
      },
      component: () =>
        import(
          /* webpackChunkName: "layout" */ "./components/layout/SidebarDashLayout.vue"
        ),
      meta: {
        auth: true,
      },
      beforeEnter: AuthGuard,
      children: [
        {
          // Setting a route's first child with an empty path will set that as the 'default' router-view
          path: "",
          name: "dashboard",
          beforeEnter: (to, from, next) => {
            const store = useStore();
            store.commit("removeSelectedFolder");
            store.commit("removeSelectedLogEntry");
            next();
          },
          components: {
            main: () =>
              import(
                /* webpackChunkName: "layout" */ "./views/GuidedDashboard.vue"
              ),
            sidebar: () =>
              import(
                /* webpackChunkName: "layout" */ "./components/sidebars/UserDataSidebar.vue"
              ),
          },
        },
        {
          path: "/login",
          redirect: {
            name: "dashboard",
          },
          meta: {
            auth: true,
          },
        },
        {
          path: "folders",
          name: "folders",
          beforeEnter: (to, from, next) => {
            const store = useStore();
            store.commit("removeSelectedFolder");
            store.commit("removeSelectedLogEntry");
            next();
          },
          components: {
            main: () =>
              import(
                /* webpackChunkName: "folders" */ "./views/AllFolders.vue"
              ),
            sidebar: () =>
              import(
                /* webpackChunkName: "layout" */ "./components/sidebars/UserDataSidebar.vue"
              ),
          },
        },
        {
          path: "user-folder/:id",
          redirect: (to) => ({
            name: "folder",
            params: { id: to.params.id },
          }),
          props: {
            sidebar: {
              isAdmin: false,
            },
          },
          components: {
            main: () =>
              import(
                /* webpackChunkName: "layout" */ "./components/layout/HeaderMainContentLayout.vue"
              ),
            sidebar: () =>
              import(
                /* webpackChunkName: "layout" */ "./components/sidebars/FolderViewSidebar.vue"
              ),
          },
          children: [
            {
              path: "/folder/:id",
              props: {
                header: {
                  isAdmin: false,
                },
              },
              components: {
                content: () =>
                  import(
                    /* webpackChunkName: "layout" */ "./components/layout/RouteTabWrapper.vue"
                  ),
                header: () =>
                  import(
                    /* webpackChunkName: "layout" */ "./components/folder/FolderHeader.vue"
                  ),
              },
              children: [
                {
                  path: "",
                  name: "folder.experiences",
                  props: {
                    tab: {
                      isAdmin: false,
                      folderId: (route) => route.params.id,
                    },
                  },
                  components: {
                    tab: () =>
                      import(
                        /* webpackChunkName: "folder-view" */ "./components/folder/FolderExperiences.vue"
                      ),
                  },
                },
                {
                  path: "messages",
                  name: "folder.messages",
                  props: {
                    tab: {
                      view: "folder",
                      isAdmin: false,
                      folderId: (route) => route.params.id,
                    },
                  },
                  beforeEnter: async (to, from, next) => {
                    const store = useStore();
                    await store.dispatch("getFolderMessages", to.params.id);
                    next();
                  },
                  components: {
                    tab: () =>
                      import(
                        /* webpackChunkName: "folder-view" */ "./components/messages/MessageComponent.vue"
                      ),
                  },
                },
              ],
            },
          ],
        },
        {
          path: "support/:section?",
          name: "support",
          beforeEnter: (to, from, next) => {
            const store = useStore();
            store.commit("removeSelectedFolder");
            store.commit("removeSelectedLogEntry");
            if (!store.getters.getShowSupportTab) {
              next("/");
            } else {
              next();
            }
          },
          props: {
            main: (route) => {
              section: route.params.section || null;
            },
          },
          components: {
            main: () =>
              import(/* webpackChunkName: "support" */ "./views/Support.vue"),
            sidebar: () =>
              import(
                /* webpackChunkName: "layout" */ "./components/sidebars/UserDataSidebar.vue"
              ),
          },
        },
        {
          path: "feedback",
          redirect: {
            name: "feedback",
            params: {
              showTab: "all",
            },
          },
          beforeEnter: (to, from, next) => {
            const store = useStore();
            store.commit("removeSelectedFolder");
            store.commit("removeSelectedLogEntry");
            next();
          },
          components: {
            main: () =>
              import(
                /* webpackChunkName: "layout" */ "./components/layout/HeaderMainContentLayout.vue"
              ),
            sidebar: () =>
              import(
                /* webpackChunkName: "layout" */ "./components/sidebars/UserDataSidebar.vue"
              ),
          },
          children: [
            {
              path: ":showTab",
              name: "feedback",
              props: {
                content: (route) => ({
                  adminView: false,
                  showTab: route.params.showTab,
                }),
              },
              beforeEnter: async (to, from, next) => {
                const store = useStore()
                await store.dispatch("getFeedbackByUser");
                next();
              },
              components: {
                content: () =>
                  import(
                    /* webpackChunkName: "feedback" */ "./components/feedback/FeedbackList.vue"
                  ),
                header: () =>
                  import(
                    /* webpackChunkName: "layout" */ "./components/feedback/FeedbackHeader.vue"
                  ),
              },
            },
          ],
        },
        {
          path: "super-admin",
          redirect: {
            name: "dashboard",
          },
          meta: {
            auth: true,
            superAdmin: true,
          },
          components: {
            main: () =>
              import(
                /* webpackChunkName: "layout" */ "./components/layout/HeaderMainContentLayout.vue"
              ),
            sidebar: () =>
              import(
                /* webpackChunkName: "layout" */ "./components/sidebars/DangerZoneSidebar.vue"
              ),
          },
          children: [
            {
              path: "/danger-zone",
              name: "danger-zone",
              components: {
                content: () =>
                  import(
                    /* webpackChunkName: "layout" */ "./components/layout/RouteTabWrapper.vue"
                  ),
                header: () =>
                  import(
                    /* webpackChunkName: "layout" */ "./components/danger-zone/DangerZoneHeader.vue"
                  ),
              },
              children: [
                {
                  path: "migrations",
                  name: "danger-zone.migrations",
                  components: {
                    tab: () =>
                      import(
                        /* webpackChunkName: "danger-zone" */ "./components/danger-zone/Migrations.vue"
                      ),
                  },
                },
                {
                  path: "demo-data",
                  name: "danger-zone.demo-data",
                  components: {
                    tab: () =>
                      import(
                        /* webpackChunkName: "danger-zone" */ "./components/danger-zone/DemoData.vue"
                      ),
                  },
                },
                {
                  path: "testing-scripts",
                  name: "danger-zone.testing-scripts",
                  components: {
                    tab: () =>
                      import(
                        /* webpackChunkName: "danger-zone" */ "./components/danger-zone/TestingScripts.vue"
                      ),
                  },
                },
                {
                  path: "org-scripts",
                  name: "danger-zone.org-scripts",
                  components: {
                    tab: () =>
                      import(
                        /* webpackChunkName: "danger-zone" */ "./components/danger-zone/OrgDataScripts.vue"
                      ),
                  },
                },
                {
                  path: "organizations",
                  name: "danger-zone.organizations",
                  components: {
                    tab: () =>
                      import(
                        /* webpackChunkName: "danger-zone" */ "./components/danger-zone/Organizations.vue"
                      ),
                  },
                },
                {
                  path: "hra-bulk-conversions",
                  name: "danger-zone.hra-bulk-conversions",
                  components: {
                    tab: () =>
                      import(
                        /* webpackChunkName: "danger-zone" */ "./components/danger-zone/BulkConversions.vue"
                      ),
                  },
                },
                // {
                // 	path: "org-scripts",
                // 	name: "danger-zone.org-scripts",
                // 	components: {
                // 		tab: () =>
                // 			import(
                // 				/* webpackChunkName: "danger-zone" */ "./components/danger-zone/OrgDataScripts.vue"
                // 			)
                // 	}
                // }
              ],
            },
          ],
        },
        {
          path: "admin",
          name: "admin",
          meta: {
            hrRoute: true,
          },
          // redirect: {
          //   // Default route; but we want to show the subroute ('/analytics') in this case so we use a redirect instead of an empty path for default route
          //   // name: "admin.analytics.organizational",
          //   path: "/analytics"
          // },
          props: {
            main: {
              isAdmin: true,
            },
          },
          components: {
            main: () =>
              import(
                /* webpackChunkName: "layout" */ "./components/layout/HeaderMainContentLayout.vue"
              ),
            sidebar: () =>
              import(
                /* webpackChunkName: "layout" */ "./components/sidebars/AdminDashSidebar.vue"
              ),
          },
          children: [
            {
              path: "folders",
              beforeEnter: (to, from, next) => {
                const store = useStore();
                if (to.params.id) {
                  next({
                    name: "admin.folder.view",
                    params: { id: to.params.id },
                  });
                }
                store.commit("removeSelectedFolder");
                store.commit("removeSelectedLogEntry");
                next();
              },
              components: {
                content: () =>
                  import(
                    /* webpackChunkName: "layout" */ "./components/layout/RouteTabWrapper.vue"
                  ),
                header: () =>
                  import(
                    /* webpackChunkName: "layout" */ "./components/Employees/FolderListHeader.vue"
                  ),
              },
              children: [
                {
                  path: "",
                  name: "admin.folders.assigned",
                  props: {
                    tab: { showTab: "assigned" },
                  },
                  components: {
                    tab: () =>
                      import(
                        /* webpackChunkName: "admin-folders" */ "./components/admin/AssignedFoldersTable.vue"
                      ),
                  },
                  beforeEnter: async (to, from, next) => {
                    const store = useStore();
                    await store.dispatch("setAppLoading", true);
                    await store.dispatch("getEmployeeFolders", {
                      canAccess: true,
                    });
                    await store.dispatch("setAppLoading", false);
                    next();
                  },
                },
                {
                  path: "all",
                  name: "admin.folders.all",
                  props: {
                    tab: {
                      showTab: "all",
                    },
                  },
                  components: {
                    tab: () =>
                      import(
                        /* webpackChunkName: "admin-folders" */ "./components/admin/AssignedFoldersTable.vue"
                      ),
                  },
                  beforeEnter: async (to, from, next) => {
                    const store = useStore();

                    await store.dispatch("setAppLoading", true);
                    await store.dispatch("getEmployeeFolders", {});
                    await store.dispatch("setAppLoading", false);
                    next();
                  },
                },
                {
                  path: "now",
                  name: "admin.folders.now",
                  props: {
                    tab: { showTab: "now" },
                  },
                  components: {
                    tab: () =>
                      import(
                        /* webpackChunkName: "admin-folders" */ "./components/admin/AssignedFoldersTable.vue"
                      ),
                  },
                  beforeEnter: async (to, from, next) => {
                    const store = useStore();
                    await store.dispatch("setAppLoading", true);
                    await store.dispatch("getEmployeeFolders", {
                      folderType: "now",
                      ...(store.getters.getUserRole === "HRViewer" && {
                        canAccess: true,
                      }),
                    });
                    await store.dispatch("setAppLoading", false);
                    next();
                  },
                },
              ],
            },
            {
              path: "employees/:id",
              name: "admin.employee-profile",
              redirect: {
                name: "admin.employee-profile.view",
                params: {
                  showTab: "submitted",
                },
              },
              props: {
                header: (route) => ({
                  id: route.params.id,
                }),
                content: (route) => ({
                  id: route.params.id,
                }),
              },
              beforeEnter: (to, from, next) => {
                const store = useStore();
                store.commit("removeSelectedFolder");
                store.commit("removeSelectedLogEntry");
                next();
              },
              components: {
                content: () =>
                  import(
                    /* webpackChunkName: "layout" */ "./components/layout/RouteTabWrapper.vue"
                  ),
                header: () =>
                  import(
                    /* webpackChunkName: "admin-employee-profile" */ "./components/Employees/ProfileHeader.vue"
                  ),
              },
              children: [
                {
                  path: ":showTab",
                  name: "admin.employee-profile.view",
                  props: {
                    tab: (route) => ({
                      showTab: route.params.showTab,
                      id: route.params.id,
                    }),
                  },
                  components: {
                    tab: () =>
                      import(
                        /* webpackChunkName: "admin-employee-profile" */ "./components/admin/EmployeeAnalytics.vue"
                      ),
                  },
                  beforeEnter: async (to, from, next) => {
                    const allowedTabs = ["submitted", "mentioned"];
                    if (allowedTabs.includes(to.params.showTab)) {
                      next();
                    } else {
                      next({
                        name: "admin.employee-profile.view",
                        params: { showTab: "submitted", id: route.params.id },
                      });
                    }
                  },
                },
              ],
            },
            {
              path: "pulse-surveys",
              redirect: {
                name: "admin.pulse-surveys.list",
                params: { showTab: "active" },
              },
              beforeEnter: (to, from, next) => {
                const store = useStore();
                store.commit("removeSelectedFolder");
                store.commit("removeSelectedLogEntry");
                next();
              },
              props: {
                header: (route) => ({ survey: route.params.record || null }),
              },
              components: {
                content: () =>
                  import(
                    /* webpackChunkName: "layout" */ "./components/layout/RouteTabWrapper.vue"
                  ),
                header: () =>
                  import(
                    /* webpackChunkName: "admin-surveys" */ "./components/admin/PulseSurveyHeader.vue"
                  ),
              },
              children: [
                {
                  path: ":showTab",
                  name: "admin.pulse-surveys.list",
                  beforeEnter: async (to, from, next) => {
                    let allowedTabs = ["active", "completed"];
                    const store = useStore();

                    await store.dispatch("getPulseSurveysAndResponses");
                    if (allowedTabs.includes(to.params.showTab)) {
                      next();
                    } else {
                      next({
                        name: "admin.pulse-surveys.list",
                        params: { showTab: "active" },
                      });
                    }
                  },
                  props: {
                    tab: (route) => ({
                      showTab: route.params.showTab,
                    }),
                  },
                  components: {
                    tab: () =>
                      import(
                        /* webpackChunkName: "admin-surveys" */ "./components/admin/PulseSurveys.vue"
                      ),
                  },
                },
                {
                  path: ":id",
                  name: "admin.pulse-surveys.view",
                  props: {
                    tab: (route) => ({
                      surveyId: route.params.id,
                      survey: route.params.record,
                    }),
                  },
                  components: {
                    tab: () =>
                      import(
                        /* webpackChunkName: "admin-surveys" */ "./components/admin/SurveyView.vue"
                      ),
                  },
                },
              ],
            },
            {
              path: "feedback",
              redirect: {
                name: "admin.feedback",
                params: { showTab: "active" },
              },
              beforeEnter: (to, from, next) => {
                const store = useStore();
                store.commit("removeSelectedFolder");
                store.commit("removeSelectedLogEntry");
                next();
              },
              props: {
                content: (route) => ({
                  adminView: true,
                  showTab: route.params.showTab,
                }),
              },
              components: {
                content: () =>
                  import(
                    /* webpackChunkName: "admin-feedback" */ "./components/layout/RouteTabWrapper.vue"
                  ),
                header: () =>
                  import(
                    /* webpackChunkName: "admin-feedback" */ "./components/feedback/FeedbackHeader.vue"
                  ),
              },
              children: [
                {
                  path: ":showTab",
                  name: "admin.feedback",
                  props: {
                    tab: (route) => ({
                      adminView: true,
                      showTab: route.params.showTab,
                    }),
                  },
                  beforeEnter: async (to, from, next) => {
                    const store = useStore();

                    await store.dispatch("getFeedbackByOrg");
                    next();
                  },
                  components: {
                    tab: () =>
                      import(
                        /* webpackChunkName: "admin-feedback" */ "./components/feedback/FeedbackList.vue"
                      ),
                  },
                },
              ],
            },
            {
              path: "analytics",
              beforeEnter: (to, from, next) => {
                const store = useStore();
                store.commit("removeSelectedFolder");
                store.commit("removeSelectedLogEntry");
                next();
              },
              components: {
                content: () =>
                  import(
                    /* webpackChunkName: "layout" */ "./components/layout/RouteTabWrapper.vue"
                  ),
                header: () =>
                  import(
                    /* webpackChunkName: "admin-analytics" */ "./components/admin/AdminHeader.vue"
                  ),
              },
              children: [
                {
                  path: "",
                  name: "admin.analytics.organizational",
                  props: {
                    tab: (route) => ({
                      showTab: "all",
                    }),
                  },
                  components: {
                    tab: () =>
                      import(
                        /* webpackChunkName: "admin-analytics" */ "./components/admin/Analytics.vue"
                      ),
                  },
                },
                {
                  path: "speakfully",
                  name: "admin.analytics.speakfully",
                  props: {
                    tab: (route) => ({
                      showTab: "speakfully",
                    }),
                  },
                  components: {
                    tab: () =>
                      import(
                        /* webpackChunkName: "admin-analytics" */ "./components/admin/Analytics.vue"
                      ),
                  },
                },
                {
                  path: "speakfullynow",
                  name: "admin.analytics.speakfullynow",
                  props: {
                    tab: (route) => ({
                      showTab: "speakfullynow",
                    }),
                  },
                  components: {
                    tab: () =>
                      import(
                        /* webpackChunkName: "admin-analytics" */ "./components/admin/Analytics.vue"
                      ),
                  },
                },
                {
                  path: "people-data",
                  name: "admin.analytics.people",
                  props: {
                    tab: (route) => ({
                      showTab: "people",
                    }),
                  },
                  components: {
                    tab: () =>
                      import(
                        /* webpackChunkName: "admin-analytics" */ "./components/admin/PeopleAnalytics.vue"
                      ),
                  },
                },
              ],
            },
          ],
        },
        {
          path: "admin-folder/:id",
          redirect: (to) => ({
            name: "admin.folder.experiences",
            params: { id: to.params.id },
          }),
          meta: {
            hrRoute: true,
          },
          props: {
            sidebar: {
              isAdmin: true,
            },
          },
          components: {
            main: () =>
              import(
                /* webpackChunkName: "layout" */ "./components/layout/HeaderMainContentLayout.vue"
              ),
            sidebar: () =>
              import(
                /* webpackChunkName: "user-sidebar" */ "./components/sidebars/FolderViewSidebar.vue"
              ),
          },
          children: [
            {
              path: "/admin/folder/:id",
              components: {
                content: () =>
                  import(
                    /* webpackChunkName: "layout" */ "./components/layout/RouteTabWrapper.vue"
                  ),
                header: () =>
                  import(
                    /* webpackChunkName: "folder-view" */ "./components/folder/AdminFolderHeader.vue"
                  ),
              },
              children: [
                {
                  path: "",
                  name: "admin.folder.experiences",
                  props: {
                    tab: (route) => ({
                      folderId: route.params.id,
                      isAdmin: true,
                    }),
                  },
                  components: {
                    tab: () =>
                      import(
                        /* webpackChunkName: "folder-view" */ "./components/folder/FolderExperiences.vue"
                      ),
                  },
                },
                {
                  path: "messages",
                  name: "admin.folder.messages",
                  props: {
                    tab: (route) => ({
                      folderId: route.params.id,
                      view: "folder",
                      isAdmin: true,
                    }),
                  },
                  beforeEnter: async (to, from, next) => {
                    const store = useStore()
                    await store.dispatch("getFolderMessages", to.params.id);
                    next();
                  },
                  components: {
                    tab: () =>
                      import(
                        /* webpackChunkName: "folder-view" */ "./components/messages/MessageComponent.vue"
                      ),
                  },
                },
                {
                  path: "notes",
                  name: "admin.folder.notes",
                  props: {
                    tab: (route) => ({
                      folderId: route.params.id,
                      isAdmin: true,
                    }),
                  },
                  beforeEnter: async (to, from, next) => {
                    const store = useStore();

                    await store.dispatch("getFolderNotes", to.params.id);
                    next();
                  },
                  components: {
                    tab: () =>
                      import(
                        /* webpackChunkName: "folder-view" */ "./components/folder/AdminFolderNotes.vue"
                      ),
                  },
                },
              ],
            },
          ],
        },
        {
          path: "admin-tools",
          beforeEnter: (to, from, next) => {
            const store = useStore();
            store.commit("removeSelectedFolder");
            store.commit("removeSelectedLogEntry");
            next();
          },
          meta: {
            auth: true,
          },
          components: {
            main: () =>
              import(
                /* webpackChunkName: "layout" */ "./components/layout/HeaderMainContentLayout.vue"
              ),
            sidebar: () =>
              import(
                /* webpackChunkName: "admin-tools" */ "./components/sidebars/AdminDashSidebar.vue"
              ),
          },
          children: [
            {
              path: "",
              name: "admin-tools.menu",
              components: {
                content: () =>
                  import(
                    /* webpackChunkName: "admin-tools" */ "./components/admin-tools/AdminToolsMenu.vue"
                  ),
                header: () =>
                  import(
                    /* webpackChunkName: "admin-tools" */ "./components/admin-tools/ToolsMenuHeader.vue"
                  ),
              },
            },
            {
              path: "employees",
              name: "admin-tools.employees",
              beforeEnter: async (to, from, next) => {
                const store = useStore();

                await store.dispatch("getEmployeeFolders", {});
                next();
              },
              components: {
                content: () =>
                  import(
                    /* webpackChunkName: "admin-tools" */ "./components/admin-tools/EmployeeTools.vue"
                  ),
                header: () =>
                  import(
                    /* webpackChunkName: "admin-tools" */ "./components/admin-tools/EmployeeToolsHeader.vue"
                  ),
              },
            },
            {
              path: "resources",
              name: "admin-tools.resources",
              components: {
                content: () =>
                  import(
                    /* webpackChunkName: "admin-tools" */ "./components/admin-tools/ResourceTools.vue"
                  ),
                header: () =>
                  import(
                    /* webpackChunkName: "admin-tools" */ "./components/admin-tools/ResourceToolsHeader.vue"
                  ),
              },
            },
          ],
        },
      ],
    },
    // CSC specific routes so that we don't temporarily muddy our product with if/else BS
    {
      path: "/cse",
      redirect: {
        name: "cse.dashboard",
      },
      component: () =>
        import(
          /* webpackChunkName: "layout" */ "./components/layout/SidebarDashLayout.vue"
        ),
      meta: {
        cse: true,
        auth: true,
      },
      children: [
        {
          path: "",
          name: "cse.dashboard",
          components: {
            main: () =>
              import(
                /* webpackChunkName: "dashboard-folders" */ "./components/cse/CSEGuidedDashboard.vue"
              ),
            sidebar: () =>
              import(
                /* webpackChunkName: "user-sidebar" */ "./components/sidebars/UserDataSidebar.vue"
              ),
          },
        },
        {
          path: "feedback",
          redirect: {
            name: "cse.feedback",
            params: {
              showTab: "all",
            },
          },
          components: {
            main: () =>
              import(
                /* webpackChunkName: "layout" */ "./components/layout/HeaderMainContentLayout.vue"
              ),
            sidebar: () =>
              import(
                /* webpackChunkName: "layout" */ "./components/sidebars/UserDataSidebar.vue"
              ),
          },
          beforeEnter: async (to, from, next) => {
            const store = useStore()
            await store.dispatch("getFeedbackByUser");
            const allowedTabs = ["active", "all", "archived"];
            if (allowedTabs.includes(to.params.showTab)) {
              next();
            } else {
              next({ name: "cse.feedback", params: { showTab: "active" } });
            }
          },
          children: [
            {
              path: ":showTab",
              name: "cse.feedback",
              props: {
                content: (route) => ({
                  adminView: false,
                  showTab: route.params.showTab,
                }),
              },
              components: {
                content: () =>
                  import(
                    /* webpackChunkName: "feedback" */ "./components/feedback/FeedbackList.vue"
                  ),
                header: () =>
                  import(
                    /* webpackChunkName: "layout" */ "./components/cse/CSEFeedbackHeader.vue"
                  ),
              },
            },
          ],
        },
        {
          path: "admin",
          name: "cse-admin",
          components: {
            main: () =>
              import(
                /* webpackChunkName: "layout" */ "./components/layout/HeaderMainContentLayout.vue"
              ),
            sidebar: () =>
              import(
                /* webpackChunkName: "layout" */ "./components/cse/CSEAdminDashSidebar.vue"
              ),
          },
          children: [
            {
              path: "surveys",
              props: {
                header: (route) => ({ survey: route.params.record || null }),
              },
              components: {
                content: () =>
                  import(
                    /* webpackChunkName: "layout" */ "./components/layout/RouteTabWrapper.vue"
                  ),
                header: () =>
                  import(
                    /* webpackChunkName: "admin-surveys" */ "./components/cse/CSEPulseSurveyHeader.vue"
                  ),
              },
              children: [
                {
                  path: ":showTab",
                  name: "cse-admin.pulse-surveys.list",
                  props: {
                    tab: (route) => ({
                      showTab: route.params.showTab,
                    }),
                  },
                  beforeEnter: async (to, from, next) => {
                    let allowedTabs = ["active", "completed"];
                    const store = useStore();

                    await store.dispatch("getPulseSurveysAndResponses");
                    if (allowedTabs.includes(to.params.showTab)) {
                      next();
                    } else {
                      next({
                        name: "cse-admin.pulse-surveys.list",
                        params: { showTab: "active" },
                      });
                    }
                  },
                  components: {
                    tab: () =>
                      import(
                        /* webpackChunkName: "admin-surveys" */ "./components/cse/CSEPulseSurveys.vue"
                      ),
                  },
                },
                {
                  path: ":id",
                  name: "cse-admin.pulse-surveys.view",
                  props: {
                    tab: (route) => ({
                      surveyId: route.params.id,
                      survey: route.params.record,
                    }),
                  },
                  components: {
                    tab: () =>
                      import(
                        /* webpackChunkName: "admin-surveys" */ "./components/cse/CSESurveyView.vue"
                      ),
                  },
                },
              ],
            },
            {
              path: "feedback",
              redirect: {
                name: "cse-admin.feedback",
                params: {
                  showTab: "active",
                },
              },
              components: {
                content: () =>
                  import(
                    /* webpackChunkName: "layout" */ "./components/layout/RouteTabWrapper.vue"
                  ),
                header: () =>
                  import(
                    /* webpackChunkName: "feedback" */ "./components/cse/CSEFeedbackHeader.vue"
                  ),
              },
              children: [
                {
                  path: ":showTab",
                  name: "cse-admin.feedback",
                  props: {
                    tab: (route) => ({
                      adminView: true,
                      showTab: route.params.showTab,
                    }),
                  },
                  beforeEnter: async (to, from, next) => {
                    const store = useStore();
                    
                    await store.dispatch("getFeedbackByOrg");
                    next();
                  },
                  components: {
                    tab: () =>
                      import(
                        /* webpackChunkName: "feedback" */ "./components/feedback/FeedbackList.vue"
                      ),
                  },
                },
              ],
            },
          ],
        },
      ],
    },
    {
      path: "/auth-layout",
      redirect: { name: "error" },
      meta: {
        auth: false,
      },
      component: () =>
        import(
          /* webpackChunkName: "public-routes" */ "./components/layout/AuthLayout.vue"
        ),
      children: [
        {
          path: "/error",
          name: "error",
          props: {
            main: {
              name: "butterData",
            },
          },
          components: {
            main: () =>
              import(
                /* webpackChunkName: "public-routes" */ "./views/Error.vue"
              ),
          },
        },
        {
          path: "/verify-email",
          name: "verify-email",
          props: {
            main: (route) => ({
              name: "butterData",
              authId: route.params.authId,
            }),
          },
          components: {
            main: () =>
              import(
                /* webpackChunkName: "public-routes" */ "./views/VerifyEmail.vue"
              ),
          },
        },
        {
          path: "/tcp",
          name: "tcp",
          props: {
            main: {
              name: "butterData",
            },
          },
          components: {
            main: () =>
              import(/* webpackChunkName: "public-routes" */ "./views/Tcp.vue"),
          },
        },
      ],
    },
    {
      path: "/shared-folders",
      redirect: { name: "basic.shared-folders.assigned" },
      meta: {
        auth: true,
        plan: "basic",
      },
      component: () =>
        import(
          /* webpackChunkName: 'shared-folders' */ "./components/layout/HeaderMainContentLayout"
        ),
      children: [
        {
          path: "",
          name: "basic.shared-folders.assigned",
          props: {
            content: { showTab: "assigned" },
          },
          components: {
            content: () =>
              import(
                /* webpackChunkName: "admin-folders" */ "./components/admin/AssignedFoldersTable.vue"
              ),
            header: () =>
              import(
                /* webpackChunkName: "admin-folders" */ "./components/admin/SharedFolderHeader.vue"
              ),
          },
          beforeEnter: async (to, from, next) => {
            const store = useStore();

            await store.dispatch("getEmployeeFolders", { canAccess: true });
            next();
          },
        },
        {
          path: "all",
          name: "basic.shared-folders.all",
          props: {
            content: { showTab: "all" },
          },
          components: {
            content: () =>
              import(
                /* webpackChunkName: "admin-folders" */ "./components/admin/AssignedFoldersTable.vue"
              ),
            header: () =>
              import(
                /* webpackChunkName: "admin-folders" */ "./components/admin/SharedFolderHeader.vue"
              ),
          },
          beforeEnter: async (to, from, next) => {
            const store = useStore();

            await store.dispatch("getEmployeeFolders", {});
            next();
          },
        },
      ],
    },
    {
      path: "/speakfully/callback",
      meta: {
        auth: true,
      },
      name: "callback",
      component: () =>
        import(/* webpackChunkName: "public-routes" */ "./views/Callback.vue"),
    },
    {
      path: "/oops",
      name: "oops",
      meta: {
        auth: false,
      },
      component: () =>
        import(/* webpackChunkName: "public-routes" */ "./views/Oops.vue"),
    },
    {
      path: "/export/folder",
      name: "exportfolder",
      meta: {
        auth: false,
      },
      component: () =>
        import(
          /* webpackChunkName: "export-folder" */ "./exportTemplates/folderExport.vue"
        ),
    },
  ],
});


router.beforeEach = (to, from, next) => {
  if (to.path === "/export/folder" || to.path === "/tcp") {
    next();
  } else {
    AuthGuard(to, from, next);
  }
};


router.onError((err) => {
  if (err == "403") {
    router.push("/403");
  } else {
    console.log(err)
    router.push({ name: "oops" });
    throw new Error(err.message || "Route cannot be reached.");
  }
});

export default router;
