import gql from "graphql-tag";

export const GET_FOLDER_METADATA = gql`
  query getOrgFolderCount($orgId: String!) {
    getOrgFolderCount(orgId: $orgId) {
      folderNum
      incidentNum
    }
  }
`;

export const GET_INCIDENT_METADATA = gql`
  query orgIncidents($orgId: String!) {
    getIncidentsByOrg(orgId: $orgId) {
      key {
        date
        type
      }
      category
      severity
      locations
      impacts
      total
    }
  }
`;

export const GET_CUSTOM_INCIDENT_METADATA = gql`
  query getCustomIncidentsByOrg($orgId: String!) {
    getCustomIncidentsByOrg(orgId: $orgId) {
      answers {
        questionGroupLabel
        questionLabel
        questionType
        option
        textFieldValue
      }
      date
    }
  }
`;

export const GET_FOLDER_STATUS_COUNT = gql`
  query getFolderDataByOrg($orgId: String!) {
    getFolderDataByOrg(orgId: $orgId) {
      status
      total
    }
  }
`;

export const GET_ANALYTICS_DATA = gql`
  query getAllAnalyticsData {
    getAllAnalyticsData {
      userTotal
      statusTrendData {
        statusHistory {
          status
          date
        }
        currentStatus
        currentStatusDate
      }
      incidentMetadata  {
        answers {
        questionGroupLabel
        questionLabel
        questionType
        option
        textFieldValue
        }
        date
      }
      anonymousByMonth {
        submittedDate
        isAnonymous
      }
    }
  }
`;

export const GET_SPEAKFULLY_ANALYTICS_DATA = gql`
  query getSpeakfullyAnalyticsData($selectedFilters: SelectedFilters) {
    getSpeakfullyAnalyticsData(selectedFilters: $selectedFilters) {
      userTotal
      statusTrendData {
        statusHistory {
          status
          date
        }
        currentStatus
        currentStatusDate
      }
      incidentMetadata  {
        answers {
        questionGroupLabel
        questionLabel
        questionType
        option
        textFieldValue
        }
        date
      }
      anonymousByMonth {
        submittedDate
        isAnonymous
      }
    }
  }
`;
export const GET_SPEAKFULLYNOW_ANALYTICS_DATA = gql`
  query getSpeakfullyNowAnalyticsData {
    getSpeakfullyNowAnalyticsData {
      statusTrendData {
        statusHistory {
          status
          date
        }
        currentStatus
        currentStatusDate
      }
      incidentMetadata  {
        answers {
        questionGroupLabel
        questionLabel
        questionType
        option
        textFieldValue
        }
        date
      }
      anonymousByMonth {
        submittedDate
        isAnonymous
      }
    }
  }
`;

export const EMPLOYEE_PROFILE_ANALYTICS = gql`
  query getEmployeeAnalytics($queryInput: EmployeeAnalyticsInput!) {
    employeeProfileAnalytics(queryInput: $queryInput) {
      experienceData {
          questionGroupLabel
          questionLabel
          questionType
          option
      }
      mentions {
        role
        users {
            userId
            total
        }
      }
      submittedDates {
        date {
          month
          year
        }
        total
      }
    }
  }
`;

export const GET_PEOPLE_ANALYTICS = gql `
  query getPeopleAnalytics {
    getPeopleAnalytics {
      role
      dataByMonth{
        month
        users {
          userId
          totalMentions: total
          experienceTypes {
            type
            total
          }
        }
      }
    }
  }
`;

export const TOP_PEOPLE_INVOLVED = gql`
    query topPeopleInvolved($currentPage: Float!) {
        topPeopleInvolved(currentPage: $currentPage) {
            role 
            totalPages
            users {
                userId
                totalMentions
                datesMentioned
            }
        }
    }
`;

export const PEOPLE_ANALYTICS_TABLE = gql`
    query peopleInvolvedTable {
        peopleInvolvedTable {
            role 
            users {
                userId
                totalMentions
                mentionsByType {
                    type
                    total
                }
            }
        }
    }
`;

export const GET_AVAILABLE_FILTER_VALUES = gql `
query getFilterValues {
  getFilterValues {
    filterId
    value
  }
}
`;

export const AMPLITUDE_TRACK = gql`
  mutation amplitudeTrack($amplitudeTrackInput: AmplitudeTrackInput!) {
    amplitudeTrack(amplitudeTrackInput: $amplitudeTrackInput)
  }
`;
