export default function getLocalize (){
  const KEY = JSON.stringify(process.env.VUE_APP_LOCALIZE_PROJECT_KEY);
  const js = document.createElement('script');
  js.type = 'text/javascript';
  js.src = 'https://global.localizecdn.com/localize.js';


  const first = document.createElement('script');
  const firstScript = document.createTextNode(`
    !function(a) {
      if(!a.Localize) {
        a.Localize = {};
        for(var e = [
          "translate",
          "untranslate",
          "phrase",
          "initialize",
          "translatePage",
          "setLanguage",
          "getLanguage",
          "detectLanguage",
          "getAvailableLanguages",
          "untranslatePage",
          "bootstrap",
          "prefetch",
          "on",
          "off",
          "hideWidget",
          "showWidget",
          "getSourceLanguage"
        ],
        t = 0; t < e.length; t++)
        a.Localize[e[t]] = function() {}
      }
    }(window);
  `);
  
  
  const second = document.createElement('script');
  const secondScript = document.createTextNode(`
    Localize.initialize({
    key: ${KEY},
    rememberLanguage: true,
    // other options go here, separated by commas
  });
  `);

  try {
    document.head.appendChild(js);
    first.appendChild(firstScript);
    document.head.appendChild(first);
    second.appendChild(secondScript);
    document.head.appendChild(second);
  } catch (e) {
    console.error(e);
  }
};