import Highcharts from 'highcharts';

Highcharts.theme = {
  colors: [ '#E7694B', '#57adae', '#C1C1C1', '#15337E', '#BBC2DD', '#4F9091', '#D9D9D9'],
  chart: {
    backgroundColor: '#ffffff',
    styledMode: false,
    displayErrors: true
  },
  credits: {
    enabled: false
  },
  title: {
    align: 'left',
    margin: 30,
    style: {
      textTransform: 'capitalize',
      fontFamily: "'Work Sans', sans-serif",
      fontWeight: '500',
      fontSize: '15px',
      color: '#868686',
    }
  },
  subtitle: {
    align: 'left',
    margin: 30,
    style: {
      textTransform: 'capitalize',
      fontFamily: "'Work Sans', sans-serif",
      fontWeight: '300',
      fontSize: '13px',
      color: '#868686',
    }
  },
  plotOptions: {
    areaspline: {
      cursor: 'pointer',
      fillOpacity: 0.75,
      marker: {
        lineWidth: 1,
        lineColor: '#fff'
      }
    },
    column: {
      maxPointWidth: 80,
    }
  },
  xAxis: {
    labels: {
      y: 25,
      style: {
        fontSize: '1em',
        fontWeight: '300',
        fontFamily: '"Work-Sans", sans-serif',
        color: '#c2c2c2'
      },
      align: "center",
    }
  },
  yAxis: {
    softMax: 10,
    tickInterval: 1,
    title: {
      text: null
    },
    tickColor: '#c2c2c2',
    labels: {
      style: {
      fontFamily: '"Work-Sans", sans-serif',
      color: '#c2c2c2'
      }
    },
  }
}

Highcharts.setOptions(Highcharts.theme)