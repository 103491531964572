/* eslint-disable no-console */
import { browserName, deviceType, mobileModel } from "mobile-device-detect";
import {
  ADD_RESOURCE,
  GET_ORG,
  REMOVE_RESOURCE,
  GET_ORG_BY_NAME,
  UPDATE_ORG,
  ADD_SUBMISSION,
  ADD_SURVEY,
  REMOVE_SURVEY,
  SURVEYS_AND_RESPONSES,
  END_SURVEY,
} from "./graphql/orgQueries";
import {
  GET_USER,
  GET_USER_ROLE,
  GET_EMPLOYEE_DATA,
  GET_HR_EMPLOYEE_DATA,
  GET_USER_NOTIFICATIONS,
  MARK_USER_NOTIFICATIONS_READ,
  UPDATE_AUTH0_USER,
  DELETE_ORG_USER,
  ADD_USER_PROPERTIES,
} from "@/graphql/userQueries";
import {
  GET_FEEDBACK_BY_USER,
  GET_FEEDBACK_BY_ORG,
  CREATE_FEEDBACK,
  CREATE_FEEDBACK_MESSAGE,
  TOGGLE_FEEDBACK_ARCHIVED,
  GET_FEEDBACK_MESSAGES,
} from "@/graphql/feedbackQueries";
import { Client } from "./apolloClient.ts";
import {
  provideApolloClient,
  useQuery,
  useMutation,
} from "@vue/apollo-composable";
import { createLogger } from "vuex";
import {
  ADD_FOLDER,
  UPDATE_FOLDER_NAME,
  ADD_INCIDENT,
  DELETE_CUSTOM_INCIDENT,
  DELETE_INCIDENT,
  DELETE_FOLDER,
  EXPORT_FOLDER_QUESTIONS,
  EXPORT_FOLDER,
  SUBMIT_FOLDER,
  GET_FOLDER,
  GET_SHARED_FOLDER,
  UPDATE_SHARED_FOLDER_STATUS,
  UPDATE_SHARED_FOLDER_RESOLUTION,
  GET_FOLDER_NOTES,
  CREATE_OR_UPDATE_NOTE,
  DELETE_NOTE,
  REVIEWED_FOLDER,
  INTERNAL_SHARE_FOLDER,
  EXTERNAL_SHARE_FOLDER,
  CREATE_FOLDER_MESSAGE,
  GET_FOLDER_MESSAGES,
  UNDO_FOLDER_ANONYMITY,
  GET_EMPLOYEE_FOLDERS,
  EDIT_ADMIN_TAGS,
  UNSHARE_FOLDER,
} from "./graphql/folderQueries";
import { DELETE_ATTACHMENT } from "./graphql/attachmentQueries";
import {
  GET_FOLDER_METADATA,
  GET_CUSTOM_INCIDENT_METADATA,
  GET_FOLDER_STATUS_COUNT,
  GET_FOLDER_STATUS_TRENDS,
  AMPLITUDE_TRACK,
  GET_FOLDER_PERSON_DATA,
  GET_ANALYTICS_DATA,
  GET_SPEAKFULLY_ANALYTICS_DATA,
  GET_SPEAKFULLYNOW_ANALYTICS_DATA,
  GET_AVAILABLE_FILTER_VALUES,
  GET_PEOPLE_ANALYTICS,
  GET_EMPLOYEE_ANALYTICS_DATA,
  EMPLOYEE_SUBMITTED_PROFILE_DATA,
  EMPLOYEE_MENTIONED_PROFILE_DATA,
  EMPLOYEE_PROFILE_ANALYTICS,
} from "./graphql/analyticsQueries";
import { NUDGE_SURVEY_RECIPIENTS } from "./graphql/emailQueries";
import * as cloneDeep from "lodash.clonedeep";
import dayjs from "./plugins/dayjs.plugin";
import { createStore } from "vuex";
import { computed, ref } from "vue";
// import { Client } from "./apolloClient";
// Vue.use(Vuex);

const plugins = [];
if (process.env.NODE_ENV === "development") {
  plugins.push(createLogger());
}

// export default new Vuex.Store({
export const store = createStore({
  state() {
    return {
      appLoading: false,
      routeLoading: false,
      auth0Connection: null,
      user: {},
      userFullName: "",
      userAuthId: "",
      currentGqlUser: null,
      userFolders: [],
      userFeedback: [],
      userRole: "",
      userLogEntries: [],
      userSharedFolders: [],
      allEmployeeFolders: [],
      org: {},
      orgFeedback: [],
      pulseSurveysAndResponses: [],
      selectedFolder: null,
      selectedFolderNotes: [],
      selectedMessages: [],
      selectedFeedback: null,
      selectedLogEntry: null,
      selectedEmployee: null,
      selectedEmployeeAnalytics: null,
      selectedEmployeeFolderData: null,
      employeeData: [],
      hrEmployees: [],
      folderMetadata: {},
      userNotificatons: [],
      notificationTemplates: [],
      isSSO: false,
      note: null,
      exportPDFData: null,
      activeUserDataFilters: [],
      availableUserFilterValues: [],
      personsInvolvedData: [],
      statusTrendData: [],
      incidentMetadata: [],
      anonymousByMonth: [],
      activeFilterUserCount: 0,
      peopleAnalytics: [],
      showWelcomeModal: false,
      auth: {},
    };
  },
  plugins,
  mutations: {
    toggleWelcomeModal(state, value) {
      state.showWelcomeModal = value;
    },
    setRouteLoading(state, isLoading) {
      state.routeLoading = isLoading;
    },
    toggleAppLoading(state, isLoading) {
      state.appLoading = isLoading;
    },
    setIsSSO(state, isSSO) {
      state.isSSO = isSSO;
    },
    setAuth0Connection(state, connectionString) {
      state.auth0Connection = connectionString;
    },
    setUserRole(state, role) {
      state.userRole = role;
    },
    setGqlUser(state, userInfo) {
      state.currentGqlUser = userInfo;
    },
    setOrgData(state, orgInfo) {
      state.org = orgInfo;
    },
    setOrgResources(state, resources) {
      state.org.resources = resources;
    },
    setOrgSubmissions(state, submissions) {
      state.org.currentSubmissions = submissions;
    },
    setOrgSurveys(state, surveys) {
      state.org.surveys = surveys;
    },
    setOrgResolutionOptions(state, resolutionOptions) {
      state.org.resolutionOptions = resolutionOptions;
    },
    setUserFolders(state, userFolders) {
      state.userFolders = userFolders;
    },
    setUserFeedback(state, feedback) {
      state.userFeedback = feedback;
    },
    setAuth(state, auth) {
      state.auth = auth;
    },
    updateUserFeedback(state, feedback) {
      const userFeedbackArrCopy = cloneDeep(state.userFeedback);
      const existingFeedbackIndex = userFeedbackArrCopy.findIndex(
        (f) => f.id == feedback.id
      );
      if (existingFeedbackIndex > -1) {
        userFeedbackArrCopy[existingFeedbackIndex] = feedback;
      } else {
        userFeedbackArrCopy.push(feedback);
      }
      state.userFeedback = userFeedbackArrCopy;
    },
    updateUserFeedbackMessages(state, { userFeedbackIndex, message }) {
      let f = cloneDeep(state.userFeedback[userFeedbackIndex]);
      let employee = state.employeeData.find(
        (u) => u.userId === message.userId
      );
      message.username =
        !!employee && !f.anonymous ? employee.name : "Anonymous";
      if (!f.messages) {
        let m = [];
        f.messages = m.push(message);
      } else {
        let m = f.messages?.findIndex((x) => x.id === message.id);
        !!m > -1 ? (f.messages[m] = message) : f.messages.push(message);
      }
      state.userFeedback[userFeedbackIndex] = f;
    },
    setOrgFeedback(state, feedback) {
      state.orgFeedback = feedback;
    },
    updateOrgFeedback(state, feedback) {
      const orgFeedbackCopy = cloneDeep(state.orgFeedback);
      const feedbackIndex = orgFeedbackCopy.findIndex(
        (f) => f.id == feedback.id
      );
      if (feedbackIndex > -1) {
        orgFeedbackCopy[feedbackIndex] = feedback;
      } else {
        orgFeedbackCopy.push(feedback);
      }
      state.orgFeedback = orgFeedbackCopy;
    },
    updateOrgFeedbackMessages(state, { orgFeedbackIndex, message }) {
      let f = cloneDeep(state.orgFeedback[orgFeedbackIndex]);
      let employee = state.employeeData.find(
        (u) => u.userId === message.userId
      );
      message.username =
        !!employee && !f.anonymous ? employee.name : "Anonymous";
      if (!f.messages) {
        let m = [];
        f.messages = m.push(message);
      } else {
        let m = f.messages?.findIndex((x) => x.id === message.id);
        !!m > -1 ? (f.messages[m] = message) : f.messages.push(message);
      }
      state.orgFeedback[orgFeedbackIndex] = f;
    },
    setSelectedFeedback(state, feedback) {
      state.selectedFeedback = feedback;
    },
    setSelectedMessages(state, messages) {
      state.selectedMessages = messages;
    },
    addMessage(state, message) {
      let messageCopy = cloneDeep(state.selectedMessages);
      messageCopy.push(message);
      state.selectedMessages = messageCopy;
    },
    clearSelectedMessages(state) {
      state.selectedMessages = [];
    },
    setUserLogEntries(state, userFolders) {
      const logs = [];
      userFolders.forEach((x) =>
        x.incidents.forEach((y) => logs.push({ folderId: x.id, log: y }))
      );
      state.userLogEntries = logs;
    },
    setUserSharedFolders(state, sharedFolders) {
      state.userSharedFolders = sharedFolders;
    },
    addUserDataToSharedFolders(state) {
      const employees = state.employeeData;
      const userSharedFolders = cloneDeep(state.userSharedFolders);
      try {
        const newFolders = userSharedFolders.map((sharedFolder) => {
          const user = employees.find(
            (employee) => employee.email == sharedFolder.user.email
          );
          if (!!user && !sharedFolder.isAnonymous) {
            return { ...sharedFolder, username: user.name };
          } else {
            return { ...sharedFolder, username: "Anonymous" };
          }
        });
        state.userSharedFolders = newFolders;
      } catch (e) {
        console.log(e);
        throw e;
      }
    },
    setNotificationTemplates(state, data) {
      state.notificationTemplates = data;
    },
    setUserNotificationsRead(state) {
      state.userNotificatons.forEach((x) => {
        x.read = true;
      });
    },
    updateUser(state, data) {
      state.user = Object.assign(state.user, data);
    },
    setUserNotificationsData(state, data) {
      state.userNotificatons = data;
    },
    removeSelectedFeedback(state) {
      state.selectedFeedback = null;
    },
    setSelectedFolder(state, folderData) {
      let folder = { ...folderData };
      if (folder.sharedWith && folder.sharedWith.length) {
        folder.sharedWith = folder.sharedWith.filter(
          (record) => record.isActive
        );
      }

      if (!folder.username) {
        if (folder.reportedBy && state.org.allowIdentityDisclosure) {
          state.selectedFolder = { ...folder, username: folder.reportedBy };
          return;
        }
        if ((!folder.userId && !folder.user) || folder.isAnonymous) {
          console.log(folder.userId, folder.user, folder.isAnonymous);
          state.selectedFolder = { ...folder, username: "Anonymous" };
          return;
        }
        let employee = state.employeeData?.find(
          (x) => x.userId === (folder.userId || folder.user.id)
        );
        if (employee && !folder.isAnonymous) {
          state.selectedFolder = { ...folder, username: employee.name };
        } else {
          state.selectedFolder = { ...folder, username: state.user.name || "" };
        }
      } else {
        state.selectedFolder = folder;
      }
    },
    setSelectedFolderNotes(state, folder) {
      if (state.selectedFolder?.id == folder.id) {
        let newNotes = cloneDeep(state.selectedFolderNotes);
        newNotes = folder.notes;
        state.selectedFolderNotes = newNotes;
      }
    },
    addOrEditNote(state, newNote) {
      let newNotes = cloneDeep(state.selectedFolderNotes);
      let currentIndex = newNotes.findIndex((note) => note.id === newNote.id);
      if (currentIndex > -1) {
        newNotes.splice(currentIndex, 1);
      }
      newNotes.push(newNote);
      state.selectedFolderNotes = newNotes;
    },
    addUserNameToNotes(state) {
      try {
        state.selectedFolderNotes = state.selectedFolderNotes.map((note) => {
          let user = state.employeeData.find(
            (employee) => employee.userId == note.userId
          );
          if (!user) {
            user = state.selectedFolder.sharedWith.find(
              (x) => x.userId === note.userId
            );
          }
          return { ...note, username: user?.name || user?.email || "" };
        });
      } catch (e) {
        console.log(e);
        throw e;
      }
    },

    setSelectedFolderMessages(state, folder) {
      const selectedFolder = cloneDeep(state.selectedFolder);
      const newMessages = folder.messages.map((message) => {
        const user = state.employeeData.find(
          (employee) => employee.userId == message.userId
        );
        if (user) {
          if (
            selectedFolder.isAnonymous &&
            selectedFolder.user.id == user.userId
          ) {
            return { ...message, username: "Anonymous" };
          }
          return { ...message, username: user.name };
        } else {
          return { ...message, username: selectedFolder.reportedBy || "" };
        }
      });
      if (selectedFolder.id === folder.id) {
        delete selectedFolder.messages;
        state.selectedFolder = { ...selectedFolder, messages: newMessages };
      }
    },

    setEmployeeData(state, employees) {
      console.log(employees);
      state.employeeData = employees;
      const hr = employees?.filter(
        (emp) =>
          emp.role == "HRAdmin" ||
          emp.role == "HRViewer" ||
          emp.role == "SpeakfullyAdmin" ||
          emp.externalOrgs?.some((org) => org.orgId === state.org.id)
      );
      state.hrEmployees = hr;
    },
    setSelectedEmployee(state, employee) {
      state.selectedEmployee = employee;
    },
    removeSelectedEmployee(state) {
      state.selectedEmployee = null;
    },
    setFolderMetadata(state, folderMetadata) {
      state.folderMetadata = folderMetadata;
    },
    setIncidentMetadataByDate(state, incidentMetadataByDate) {
      state.incidentMetadataByDate = incidentMetadataByDate;
    },
    setCustomIncidentMetadataByDate(state, customIncidentMetadataByDate) {
      state.customIncidentMetadataByDate = customIncidentMetadataByDate;
    },
    setFolderPersonData(state, folderPersonData) {
      state.folderPersonData = folderPersonData;
    },
    setFolderTrendData(state, folderTrendData) {
      state.folderTrendData = folderTrendData;
    },
    setFolderStatusData(state, folderStatusData) {
      state.folderStatusData = folderStatusData;
    },
    setSelectedFolderByID(state, folderId) {
      const folder = state.userFolders.filter(
        (folder) => folder.id == folderId
      );
      state.selectedFolder = folder[0];
    },
    setSelectedLogEntry(state, logEntry) {
      let newSelectedLogEntry = cloneDeep(state.selectedLogEntry);
      newSelectedLogEntry = logEntry;
      state.selectedLogEntry = newSelectedLogEntry;
    },
    setSelectedLogEntryByID(state, logEntryId) {
      const logEntry = state.userLogEntries.filter(
        (i) => i.log.id == logEntryId
      );
      state.selectedLogEntry = logEntry[0].log;
    },
    removeSelectedFolder(state) {
      state.selectedFolder = null;
    },
    removeResource(state, resourceData) {
      state.org.resources = state.org.resources.filter(
        (x) => x.id !== resourceData.resourceId
      );
    },
    removeSelectedLogEntry(state) {
      state.selectedLogEntry = null;
    },
    addLogEntry(state, { incident, folderId }) {
      const logEntry = { folderId: folderId, log: incident };
      const logEntryIndex = state.userLogEntries.findIndex(
        (i) => i.log.id == incident.id
      );

      if (logEntryIndex === -1) {
        state.userLogEntries.push(logEntry);
      } else {
        state.userLogEntries.splice(logEntryIndex, 1, logEntry);
      }
    },
    removeLogEntry(state, incidentData) {
      state.userLogEntries = state.userLogEntries.filter(
        (x) => x.log.id !== incidentData.incidentId
      );
    },
    removeLogEntries(state, folderId) {
      //for deleting multiple logs when deleting a folder
      state.userLogEntries = state.userLogEntries.filter(
        (x) => x.folderId !== folderId
      );
    },
    removeAttachment(state, attachmentId) {
      let newSelectedLogEntry = cloneDeep(state.selectedLogEntry);
      newSelectedLogEntry.attachments = newSelectedLogEntry.attachments.filter(
        (attachment) => attachment.id !== attachmentId
      );
      state.selectedLogEntry = newSelectedLogEntry;
    },
    addAttachmentToNote(state, attachment) {
      const noteCopy = cloneDeep(state.note);
      const allNotesCopy = cloneDeep(state.selectedFolderNotes);
      if (noteCopy.id === attachment.noteId) {
        noteCopy.attachments.push(attachment);
        state.note = noteCopy;
      }
      const currentNoteIndex = allNotesCopy.findIndex(
        (note) => note.id === attachment.noteId
      );
      if (currentNoteIndex > -1) {
        allNotesCopy[currentNoteIndex].attachments.push(attachment);
        state.selectedFolderNotes = allNotesCopy;
      }
    },
    removeNoteAttachment(state, { attachmentId, noteId }) {
      const allNotesCopy = cloneDeep(state.selectedFolderNotes);
      const currentNoteIndex = allNotesCopy.findIndex(
        (note) => note.id === noteId
      );
      if (state.note) {
        state.note.attachments = state.note.attachments.filter(
          (attachment) => attachment.id !== attachmentId
        );
      }
      if (currentNoteIndex > -1) {
        allNotesCopy[currentNoteIndex].attachments = allNotesCopy[
          currentNoteIndex
        ].attachments.filter((attachment) => attachment.id !== attachmentId);
        state.selectedFolderNotes = allNotesCopy;
      }
    },
    addFolder(state, { addFolder: newFolder }) {
      state.userFolders.push(newFolder);
    },
    removeFolder(state, folder) {
      //folder argument is the folder id
      state.userFolders = state.userFolders.filter((x) => x.id !== folder);
    },
    updateFolder(state, newFolder) {
      const userFolders = cloneDeep(state.userFolders);
      let folderIndex = userFolders.findIndex(
        (folder) => folder.id == newFolder.id
      );
      if (folderIndex != -1) {
        userFolders[folderIndex] = newFolder;
        state.userFolders = userFolders;
      }
    },
    updateSharedFolder(state, newFolder) {
      const uniqBy = require("lodash.uniqby");
      const userSharedFolders = cloneDeep(state.allEmployeeFolders);
      let sharedFolderIndex = userSharedFolders.findIndex(
        (sharedFolder) => sharedFolder.id == newFolder.id
      );
      if (sharedFolderIndex > -1) {
        if (!newFolder.username && !newFolder.isAnonymous) {
          let employee = state.employeeData.findIndex(
            (x) => x.userId === newFolder.userId
          );
          if (employee > -1) {
            newFolder.username = state.employeeData[employee].name;
          }
        } else if (newFolder.isAnonymous) {
          newFolder.username = "Anonymous";
        } else {
          newFolder.username = newFolder.username || newFolder.reportedBy || "";
        }
        // Filter out inactive records
        let tableData = {
          id: newFolder.id,
          currentStatus: newFolder.currentStatus,
          createdDate: newFolder.createdDate,
          isAnonymous: newFolder.isAnonymous,
          folderType: newFolder.folderType,
          name: newFolder.name,
          sharedWith: newFolder.sharedWith.filter((record) => record.isActive),
          submittedDate: newFolder.submittedDate,
          userId: newFolder.userId,
          username: newFolder.username,
        };
        let viewingPermissions = tableData.sharedWith
          ?.map((user) => {
            let folderViewerIndex = state.employeeData?.findIndex(
              (emp) => emp.userId === user.userId
            );
            if (folderViewerIndex > -1) {
              return {
                ...user,
                name: state.employeeData[folderViewerIndex].name,
              };
            } else {
              return { ...user, name: user.email };
            }
          })
          .filter(Boolean);
        tableData.viewingPermissions = uniqBy(
          viewingPermissions,
          (i) => i.userId
        );
        userSharedFolders[sharedFolderIndex] = tableData;
        state.allEmployeeFolders = userSharedFolders;
      }
    },
    setTrialStartDate(state, day) {
      state.org.trialStartDate = day;
    },
    updateEmployeeRole(state, { authId, role }) {
      const employees = cloneDeep(state.employeeData);
      const emp = employees.findIndex((x) => x.authId == authId);
      employees[emp].role = role;
      this.commit("setEmployeeData", employees);
    },
    updateEmployeeBlockedStatus(state, { authId, blocked }) {
      const employees = cloneDeep(state.employeeData);
      const emp = employees.findIndex((x) => x.authId == authId);
      employees[emp].blocked = blocked;
      this.commit("setEmployeeData", employees);
    },
    removeOrgUser(state, userId) {
      const employees = cloneDeep(state.employeeData);
      const updatedEmp = employees.filter((emp) => {
        return emp.userId != userId;
      });
      state.employeeData = updatedEmp;

      const sharedFolderCopy = cloneDeep(state.allEmployeeFolders);
      const updatedFol = sharedFolderCopy.filter((folder) => {
        return folder.userId != userId;
      });
      state.allEmployeeFolders = updatedFol;

      const hrCopy = cloneDeep(state.hrEmployees);
      const updatedHr = hrCopy.filter((hr) => {
        return hr.userId != userId;
      });
      state.hrEmployees = updatedHr;
    },
    setNote(state, noteChange) {
      state.note = noteChange;
    },
    clearNote(state) {
      state.note = null;
    },
    setNoteActionOptions(state, options) {
      state.org.noteActionOptions = options;
    },
    setPulseSurveysAndResponses(state, responses) {
      state.pulseSurveysAndResponses = responses;
    },
    updateOrgSurvey(state, survey) {
      let updatedSurveys = cloneDeep(state.pulseSurveysAndResponses);
      let i = updatedSurveys.findIndex((x) => x.id === survey.id);
      i > -1 ? (updatedSurveys[i] = survey) : updatedSurveys.push(survey);
      state.pulseSurveysAndResponses = updatedSurveys;
    },
    removeSurvey(state, surveyId) {
      state.pulseSurveysAndResponses = state.pulseSurveysAndResponses.filter(
        (x) => x.id !== surveyId
      );
    },
    setExportPDFData(state, pdf) {
      state.exportPDFData = pdf;
    },
    clearExportPDFData(state, pdf) {
      state.exportPDFData = null;
    },
    addExportDataToFolder(state, { newFolder, isHR }) {
      let folders = cloneDeep(
        isHR ? state.allEmployeeFolders : state.userFolders
      );
      let folderIndex = folders.findIndex((x) => x.id == newFolder.id);
      if (folderIndex != -1) {
        if (isHR) {
          let user;
          !newFolder.isAnonymous &&
            (user = state.employeeData.find(
              (employee) => employee.email == newFolder.user?.email
            ));
          const folder = user
            ? { ...newFolder, username: user.name }
            : { ...newFolder, username: "Anonymous" };
          folders[folderIndex] = folder;
          state.allEmployeeFolders = folders;
        } else {
          folders[folderIndex] = newFolder;
          state.userFolders = folders;
        }
      }
    },

    setAnalyticsData(state, { dataByFilter, filters }) {
      state.incidentMetadata = dataByFilter.incidentMetadata;
      state.statusTrendData = dataByFilter.statusTrendData;
      state.activeFilterUserCount = dataByFilter.userTotal;
      state.activeUserDataFilters = filters;
      state.anonymousByMonth = dataByFilter.anonymousByMonth;
    },

    setEmployeeAnalytics(state, data) {
      data.mentions.forEach((role) => {
        role.users = role.users.map((user) => {
          const i = state.employeeData.findIndex(
            (employee) => employee.userId === user.userId
          );
          const name = i > -1 ? state.employeeData[i].name : "Anonymous";
          return [name, user.total];
        });
      });
      state.selectedEmployeeAnalytics = data;
    },

    setAvailableFilterValues(state, availableFilters) {
      state.availableUserFilterValues = availableFilters;
    },

    setOrgFilterFactors(state, filterFactors) {
      state.org.filterFactors = filterFactors;
    },

    setPeopleAnalytics(state, peopleAnalytics) {
      state.peopleAnalytics = peopleAnalytics;
    },

    setSharedFolderData(state, employeeFolders) {
      const uniqBy = require("lodash.uniqby");
      const employeeNames = state.employeeData.reduce(
        (a, { userId, name }) => ({ ...a, [userId]: name }),
        {}
      );
      employeeFolders = employeeFolders.map((folder) => ({
        ...folder,
        username: "",
      }));
      employeeFolders.forEach((folder) => {
        if (!folder.isAnonymous) {
          folder.username =
            folder.reportedBy || employeeNames[folder.userId] || "";
        } else {
          folder.username = "Anonymous";
        }

        let viewingPermissions = folder.sharedWith
          .filter((record) => record.isActive)
          .map((user) => {
            return { ...user, name: employeeNames[user.userId] || user.email };
          })
          .filter(Boolean);
        folder.viewingPermissions = uniqBy(viewingPermissions, (i) => i.userId);
        folder.personsInvolved.forEach((user) => {
          if (user.name) return;
          user.name = employeeNames[user.userId] || user.email || "";
        });
      });
      state.allEmployeeFolders = employeeFolders;
    },

    setSelectedEmployeeFolderData(state, employeeId) {
      state.selectedEmployeeFolderData = state.allEmployeeFolders.filter(
        (folder) => {
          return folder.userId === employeeId;
        }
      );
    },
  },

  actions: {
    async setAppLoading({ commit }, isLoading) {
      await commit("toggleAppLoading", isLoading);
    },
    async getGqlUser({ dispatch, commit, state }, auth) {
      try {
        const authUser = await computed(() => auth?.user.value);
        console.log(authUser);
        // let role = "Super Admin";
        let role =
          authUser.value["https://speakfully.com/roles"] &&
          authUser.value["https://speakfully.com/roles"][0];
        if (!state.appLoading) await commit("toggleAppLoading", true);
        await commit("setUserRole", role);
        await commit("setAuth0Connection", authUser.value.connection);
        await commit("setAuth", auth);
        let now = dayjs();
        const initialLogin =
          authUser.value["https://speakfully.com/logins_count"] == 1 ||
          (authUser.value["https://speakfully.com/logins_count"] <= 3 &&
            now.diff(authUser.value.last_login, "hours") > 12) ||
          (now.valueOf() - this.last_login) / 86400000 > 30;
        await commit("toggleWelcomeModal", initialLogin);
        const { onResult, error, result } = await provideApolloClient(
          Client(state.auth)
        )(() =>
          useQuery(GET_USER, {
            fetchPolicy: "network-only",
          })
        );
        onResult(async (getUser) => {
          console.log(await getUser);
          const userInfo = {
            id: getUser?.data.getUser.id,
            email: getUser?.data.getUser.email,
            personalEmail: getUser?.data.getUser.personalEmail || null,
            authId: authUser.value.sub,
            assignedTags: getUser?.data.getUser.assignedTags || null,
          };
          let isOrgUser =
            role?.toLowerCase() !== "freeuser" &&
            role?.toLowerCase() !== "externaluser";
          const org = computed(() => {
            return {
              id: isOrgUser ? getUser.data.getUser.org?.id : null,
              name: getUser.data.getUser.org?.name || "",
              displayName:
                getUser.data.getUser.org?.appConfig.displayName || "",
              planName: getUser.data.getUser.org?.planName || "",
              resources: getUser.data.getUser.org?.appConfig.resources || [],
              surveys: getUser.data.getUser.org?.surveys || [],
              isMultilingual:
                getUser.data.getUser.org?.appConfig.isMultilingual || false,
              allowAnonymous:
                getUser.data.getUser.org?.submissionConfig.allowAnonymous ||
                false,
              allowMessaging:
                getUser.data.getUser.org?.submissionConfig.messagingEnabled ||
                false,
              allowIdentityDisclosure:
                getUser.data.getUser.org?.submissionConfig
                  .allowIdentityDisclosure || false,
              quickExitUrl:
                getUser.data.getUser.org?.appConfig.quickExitUrl || null,
              currentSubmissions:
                getUser.data.getUser.org?.currentSubmissions || [],
              personsInvolvedProperties:
                getUser.data.getUser.org?.submissionConfig
                  .personsInvolvedProperties || [],
              allowExternalShare:
                getUser.data.getUser.org?.submissionConfig.allowExternalShare ||
                false,
              externalShareDays:
                getUser.data.getUser.org?.submissionConfig.externalShareDays ||
                10,
              noteActionOptions:
                getUser.data.getUser.org?.submissionConfig.noteActionOptions ||
                [],
              filterFactors:
                getUser.data.getUser.org?.analyticsConfig.dataFilterFactors ||
                [],
              trialStartDate: getUser.data.getUser.org?.trialStartDate || null,
              allowAddingPersonalEmail:
                getUser.data.getUser.org?.appConfig.allowAddingPersonalEmail,
              peopleAnalyticsFeature:
                getUser.data.getUser.org?.analyticsConfig
                  .peopleAnalyticsEnabled || false,
              showResourcesTab:
                getUser.data.getUser.org?.appConfig.showResourcesTab,
              showSupportTab:
                getUser.data.getUser.org?.appConfig.showSupportTab,
              isIntegratedOrg:
                getUser.data.getUser.org?.isIntegratedOrg || false,
              ...(((getUser.data.getUser.org?.submissionConfig
                .experienceDateRequired ||
                getUser.data.getUser.org?.submissionConfig
                  .experienceDateRequired === false) && {
                experienceDateRequired:
                  getUser.data.getUser.org?.submissionConfig
                    .experienceDateRequired,
              }) ||
                true),
            };
          });
          await commit("setOrgData", org.value);
          let user_properties = {
            isOrgUser: isOrgUser,
            browserName: browserName,
            deviceType: deviceType,
            mobileModel: mobileModel,
            ...(org?.id && { orgId: org?.id }),
            ...(org?.name && { orgName: org?.name }),
          };
          userInfo.user_properties = user_properties;
          userInfo.signInTime = new Date().getTime();

          await commit("setGqlUser", userInfo);
          if (isOrgUser)
            await dispatch("getEmployeeUserData", getUser.data.getUser?.id);
          if (isOrgUser && org.filterFactors?.length)
            dispatch("addUserProperties", authUser.value.sub);
          if (getUser.data.getUser.folders) {
            let userFolders = getUser.data.getUser.folders;
            await commit("setUserFolders", userFolders);
            commit("setUserLogEntries", userFolders);
          }
          // if (org.planName?.toLowerCase() === "trial" && !org.trialStartDate) {
          //   dispatch("addTrialStartDate", org?.id);
          // }
          commit(
            "setNotificationTemplates",
            getUser.data.getUser.notificationTemplates
          );
          if (authUser.value.logins_count === 1)
            dispatch("amplitudeTrack", { data: {}, eventName: "New User" });
          // const notifications = await Client.query({
          //   query: GET_USER_NOTIFICATIONS,
          //   fetchPolicy: "network-only",
          // });
          // commit(
          //   "setUserNotificationsData",
          //   notifications.data.getNotificationsByUser
          // );
          await commit("toggleAppLoading", false);
        });
      } catch (e) {
        console.log("error" + e);
        throw e;
      }
    },

    // async refreshUser({ dispatch }) {
    //   try {
    //     const connectionString = this.getters.getAuth0Connection;
    //     await dispatch("getGqlUser", {
    //       authId: user.authId,
    //       connectionString: connectionString,
    //       fullId: user.fullId
    //     });
    //   } catch (e) {
    //     console.log(e);
    //   }
    // },

    addUserProperties({}, fullId) {
      Client.mutate({
        mutation: ADD_USER_PROPERTIES,
      });
    },

    async getOrgCustomIncidents({ commit }, orgId) {
      const customIncidentData = await Client.query({
        query: GET_CUSTOM_INCIDENT_METADATA,
        variables: {
          orgId: orgId,
        },
        fetchPolicy: "network-only",
      });
      const data = customIncidentData.data.getCustomIncidentsByOrg;
      let customIncidentMetadataByDate = data.map((x) => {
        return x.answers.reduce(
          (fin, cur) => {
            if (!cur.questionType) {
              return fin;
            }
            if (cur.questionGroupLabel) {
              if (
                cur.questionGroupLabel &&
                !fin[cur.questionType][cur.questionGroupLabel]
              )
                fin[cur.questionType][cur.questionGroupLabel] = {};
              if (
                !fin[cur.questionType][cur.questionGroupLabel][
                  cur.questionLabel
                ]
              )
                fin[cur.questionType][cur.questionGroupLabel][
                  cur.questionLabel
                ] = {};
              fin[cur.questionType][cur.questionGroupLabel][cur.questionLabel][
                cur.option
              ] = 1;
            } else {
              if (!fin[cur.questionType][cur.questionLabel])
                fin[cur.questionType][cur.questionLabel] = {};
              fin[cur.questionType][cur.questionLabel][cur.option] = 1;
            }
            return fin;
          },
          {
            date: new Date(x.date),
            A: {},
            B: {},
            C: {},
          }
        );
      });
      commit("setCustomIncidentMetadataByDate", customIncidentMetadataByDate);
      return customIncidentMetadataByDate;
    },

    async createFeedback({ state }, feedback) {
      try {
        const { mutate, onDone, onError } = await provideApolloClient(
          Client(state.auth)
        )(() => useMutation(CREATE_FEEDBACK));
        mutate({
          newFeedbackData: feedback.value,
        });
        onError((error) => {
          throw new Error(error.message);
        });
        onDone((result) => {
          return result.data;
        });
      } catch (e) {
        console.log(e);
        throw e;
      }
    },

    async toggleFeedbackArchived({ commit, state }, { archived, feedbackId }) {
      try {
        const { mutate, onDone, onError } = await provideApolloClient(
          Client(state.auth)
        )(() => useMutation(TOGGLE_FEEDBACK_ARCHIVED));
        mutate({
          archived,
          feedbackId,
        });
        onError((error) => {
          console.log(error.message);
          throw new Error(error.message);
        });
        onDone(async (res) => {
          let feedback = res.data.toggleFeedbackArchived;
          let user = state.employeeData.find(
            (employee) => employee.userId === feedback.userId
          );
          !!user && !feedback.anonymous
            ? (feedback.username = user.name)
            : (feedback.username = "Anonymous");
          await commit("updateOrgFeedback", res.data.toggleFeedbackArchived);
        });
      } catch (e) {
        console.log(e);
        throw e;
      }
    },

    async getFeedbackByUser({ commit, state }) {
      try {
        const { onResult, onError, result } = provideApolloClient(
          Client(state.auth)
        )(() =>
          useQuery(GET_FEEDBACK_BY_USER, {
            fetchPolicy: "network-only",
          })
        );
        onError((error) => {
          throw new Error(error.message);
        });
        onResult(async (result) => {
          const feedbackByUser = result.data?.getFeedbackByUser?.map(
            (feedback) => ({
              ...feedback,
              username: "",
            })
          );
          feedbackByUser?.forEach((feedback) => {
            const user = state.employeeData?.find(
              (employee) => employee.userId == feedback.userId
            );
            if (!!user && !feedback.anonymous) {
              feedback.username = user.name;
            } else {
              feedback.username = "Anonymous";
            }
          });
          await commit("setUserFeedback", feedbackByUser);
        });
      } catch (e) {
        console.log(e);
        throw e;
      }
    },

    async getFeedbackByOrg({ commit, state }) {
      try {
        const { onResult, onError } = await provideApolloClient(
          Client(state.auth)
        )(() =>
          useQuery(GET_FEEDBACK_BY_ORG, {
            fetchPolicy: "network-only",
          })
        );
        onError((error) => {
          throw new Error(error.message);
        });
        onResult(async (res) => {
          const resData = res?.data?.getFeedbackByOrg.map((feedback) => {
            const user = state.employeeData.find(
              (employee) => employee.userId == feedback.userId
            );
            if (!!user && !feedback.anonymous) {
              return { ...feedback, username: user.name };
            } else {
              return {
                ...feedback,
                username: "Anonymous",
              };
            }
          });
          await commit("setOrgFeedback", res?.data?.getFeedbackByOrg);
        });
      } catch (e) {
        console.log(e);
        throw e;
      }
    },

    async getFeedbackMessages({ commit, state }, feedbackId) {
      try {
        const { onResult, onError } = await provideApolloClient(
          Client(state.auth)
        )(() =>
          useQuery(
            GET_FEEDBACK_MESSAGES,
            {
              feedbackId,
            },
            {
              fetchPolicy: "network-only",
            }
          )
        );
        onError((error) => {
          throw new Error(error.message);
        });
        onResult(async (res) => {
          let { messages, ...feedback } = res.data.getFeedbackMessages;
          const updatedMessages = messages.map((message) => {
            const user = state.employeeData.find(
              (employee) => employee.userId === message.userId
            );

            const updatedMessage = { ...message };

            if (message.userId === feedback.userId && feedback.anonymous) {
              updatedMessage.username = "Anonymous";
            } else if (user) {
              updatedMessage.username = user.name;
            } else {
              updatedMessage.username = "";
            }

            return updatedMessage;
          });
          await commit("setSelectedMessages", updatedMessages);
        });
      } catch (e) {
        console.log(e);
        throw e;
      }
    },

    async createFeedbackMessage(
      { commit, state },
      { messageText, parentDocumentId }
    ) {
      const { mutate, onDone, onError } = await provideApolloClient(
        Client(state.auth)
      )(() => useMutation(CREATE_FEEDBACK_MESSAGE));
      mutate({
        messageData: {
          message: messageText,
          feedbackId: parentDocumentId,
        },
      });
      onError((error) => {
        console.log(error.message);
        throw Error(error.message);
      });
      onDone((result) => {
        let { message, anonymous } = result.data.createFeedbackMessage;
        const addMessage = { ...message };
        if (anonymous) {
          addMessage.username = "Anonymous";
        } else {
          let user = state.employeeData.find(
            (employee) => employee.userId === message.userId
          );
          addMessage.username = !!user ? user.name : "";
        }
        commit("addMessage", addMessage);
      });
    },

    async setSelectedFolder({ commit }, folder) {
      await commit("setSelectedFolder", folder);
    },

    // async getFolderbyFolderId({ commit, state }, folderId) {
    //   console.log(folderId)
    //   const { onResult, onError, result } = Client(state.auth)(() =>
    //     useQuery(
    //       GET_FOLDER,
    //       {
    //         queryInput: {
    //           folderId: folderId,
    //         },
    //       },
    //       {
    //         fetchPolicy: "network-only",
    //       }
    //     )
    //   );
    //   onResult(async (res) => {
    //     const folder = getFolder.data.getFolder;
    //     commit("setSelectedFolder", folder);
    //     return folder;
    //   });
    //   onError((error) => {});
    // },

    async getPulseSurveysAndResponses({ commit, state }) {
      // await dispatch("getEmployeeFolders", { employeeId, byUser });
      const { onResult, onError, result } = provideApolloClient(
        Client(state.auth)
      )(() =>
        useQuery(
          SURVEYS_AND_RESPONSES,

          {
            fetchPolicy: "network-only",
          }
        )
      );
      onResult(async (res) => {
        const employees = this.state.employeeData;
        const resData = res?.data?.orgSurveysAndResponses.forEach((survey) => {
          survey.responsesReceived = survey.responsesReceived.map(
            (response) => {
              const userIndex = employees.findIndex(
                (employee) => employee.userId == response.userId
              );
              if (userIndex > -1 && !response.anonymous) {
                return { ...response, username: employees[userIndex].name };
              } else {
                return { ...response, username: "Anonymous" };
              }
            }
          );
        });
        await commit("setPulseSurveysAndResponses", resData);
      });
      onError((error) => {
        console.log(error.message);
        throw Error(error.message);
      });
    },

    async getAnalyticsData({ commit, dispatch, state }, { tab, filters }) {
      let folderData;
      let error;
      if (tab === "speakfullynow") {
        const { onResult, onError, result } = await provideApolloClient(
          Client(state.auth)
        )(() =>
          useQuery(
            GET_SPEAKFULLYNOW_ANALYTICS_DATA,

            {
              fetchPolicy: "network-only",
            }
          )
        );

        onResult((result) => {
          let fData = result.data?.getSpeakfullyNowAnalyticsData;
          if (fData) {
            console.log(fData);
            dispatch("setFormatAnalyticsData", {
              rawFolderData: fData,
              filters,
            });
          }
        });
      } else if (tab === "speakfully") {
        const { onResult, onError, result } = await provideApolloClient(
          Client(state.auth)
        )(() =>
          useQuery(
            GET_SPEAKFULLY_ANALYTICS_DATA,
            {
              selectedFilters: { filters: filters },
            },
            {
              fetchPolicy: "network-only",
            }
          )
        );

        onResult((result) => {
          let fData = result.data?.getSpeakfullyAnalyticsData;
          if (fData) {
            dispatch("setFormatAnalyticsData", {
              rawFolderData: fData,
              filters,
            });
          }
        });
      } else {
        const { onResult, onError, result } = await provideApolloClient(
          Client(state.auth)
        )(() =>
          useQuery(
            GET_ANALYTICS_DATA,

            {
              fetchPolicy: "network-only",
            }
          )
        );

        onResult((result) => {
          let fData = result.data?.getAllAnalyticsData;
          if (fData) {
            dispatch("setFormatAnalyticsData", {
              rawFolderData: fData,
              filters,
            });
          }
        });
      }
      if (error) {
        console.log(error.message);
        throw Error(error.message);
      }
    },

    setFormatAnalyticsData({ commit }, { rawFolderData, filters }) {
      if (!rawFolderData) return;

      let folderData = cloneDeep(rawFolderData);
      folderData.statusTrendData.forEach((i) => {
        i.statusHistory.map((j) => {
          return { date: new Date(j.date), status: j.status.toLowerCase() };
        });
      });
      if (folderData.incidentMetadata[0]?.answers) {
        const incidentMetadataByDate = folderData.incidentMetadata.map((x) => {
          return x.answers?.reduce(
            (fin, cur) => {
              if (!cur.questionType) {
                return fin;
              }
              if (cur.questionGroupLabel) {
                if (
                  cur.questionGroupLabel &&
                  !fin[cur.questionType][cur.questionGroupLabel]
                )
                  fin[cur.questionType][cur.questionGroupLabel] = {};
                if (
                  !fin[cur.questionType][cur.questionGroupLabel][
                    cur.questionLabel
                  ]
                )
                  fin[cur.questionType][cur.questionGroupLabel][
                    cur.questionLabel
                  ] = {};

                if (
                  typeof fin[cur.questionType][cur.questionGroupLabel][
                    cur.questionLabel
                  ] === "object"
                )
                  fin[cur.questionType][cur.questionGroupLabel][
                    cur.questionLabel
                  ][cur.option] = 1;
              } else {
                if (!fin[cur.questionType]) {
                  fin[cur.questionType] = {};
                }
                if (!fin[cur.questionType][cur.questionLabel])
                  fin[cur.questionType][cur.questionLabel] = {};
                if (
                  typeof fin[cur.questionType][cur.questionLabel] === "object"
                )
                  fin[cur.questionType][cur.questionLabel][cur.option] = 1;
              }
              return fin;
            },
            {
              date: new Date(x.date),
              A: {},
              B: {},
              C: {},
            }
          );
        });
        folderData.incidentMetadata = incidentMetadataByDate;
      }
      commit("setAnalyticsData", {
        dataByFilter: folderData,
        filters: filters,
      });
    },

    async getEmployeeProfileData({ commit }, { employeeId, byUser, month }) {
      await dispatch("getEmployeeFolders", { employeeId, byUser });
      const { onResult, onError, result } = provideApolloClient(
        Client(state.auth)
      )(() =>
        useQuery(
          EMPLOYEE_PROFILE_ANALYTICS,
          {
            queryInput: {
              employeeId,
              byUser,
              month,
            },
          },
          {
            fetchPolicy: "network-only",
          }
        )
      );
      onResult(async (res) => {
        await commit("setEmployeeAnalytics", res.data.employeeProfileAnalytics);
      });
      onError((error) => {
        console.log(error.message);
        throw Error(error.message);
      });
    },

    async getPeopleAnalytics({ commit, state }) {
      const { onResult, onError, result } = provideApolloClient(
        Client(state.auth)
      )(() =>
        useQuery(GET_PEOPLE_ANALYTICS, {
          fetchPolicy: "network-only",
        })
      );
      onResult(async (res) => {
        await commit("setPeopleAnalytics", res.data.getPeopleAnalytics);
      });
      onError((error) => {
        console.log(error.message);
        throw Error(error.message);
      });
    },

    async getEmployeeFolders(
      { commit, state },
      { employeeId, byUser, canAccess, folderType }
    ) {
      const { onResult, onError, result } = provideApolloClient(
        Client(state.auth)
      )(() =>
        useQuery(
          GET_EMPLOYEE_FOLDERS,
          {
            queryInput: {
              employeeId: employeeId,
              byUser: byUser,
              canAccess: canAccess,
              folderType: folderType,
            },
          },
          {
            fetchPolicy: "network-only",
          }
        )
      );
      onResult(async (res) => {
        const folderList = computed(() => res.data.getEmployeeFolderList);
        await commit("setSharedFolderData", folderList.value);
        return await folderList.value;
      });
      onError((error) => {});
    },

    async getFilterValues({ commit }) {
      // const { data, error } = await Client.query({
      //   query: GET_AVAILABLE_FILTER_VALUES,
      //   fetchPolicy: "network-only",
      // });
      // if (error) {
      //   console.log(error.message);
      //   throw Error(error.message);
      // }
      // await commit("setAvailableFilterValues", data.getFilterValues);
    },

    async markNotifcationsRead({ commit }, auth) {
      const { onResult, error } = await provideApolloClient(Client(auth))(() =>
        useMutation(MARK_USER_NOTIFICATIONS_READ, {
          userId: store.getters.getUserId,
        })
      );
      commit("setUserNotificationsRead");
    },

    async updateAuth0User({ commit }, data) {
      const { onDone, onError } = await provideApolloClient(Client(state.auth))(
        () =>
          useMutation(UPDATE_AUTH0_USER, {
            updateAuth0UserData: data,
          })
      );
      commit("updateUser", data);
    },

    async createFolder({ commit, state }, folderData) {
      try {
        if (state.org?.allowAnonymous) {
          folderData.isAnonymous = true;
        }
        const { onDone, onError, mutate } = provideApolloClient(
          Client(state.auth)
        )(() => useMutation(ADD_FOLDER));
        const response = await mutate({
          newFolderData: folderData,
        });
        onDone((newFolder) => {
          console.log(newFolder);
          commit("addFolder", newFolder.data.addFolder);
          commit("setSelectedFolder", newFolder.data.addFolder);
          return newFolder.data.addFolder;
        });

        onError((err) => {
          console.log(err);
          throw err;
        });

        return response.data.addFolder;
      } catch (e) {
        console.log(e);
      }
    },

    async updateFolderName({ commit, state }, data) {
      try {
        const { onDone, onError } = await provideApolloClient(
          Client(state.auth)
        )(() =>
          useMutation(UPDATE_FOLDER_NAME, {
            updateFolderNameData: data,
          })
        );
        onDone(async (res) => {
          const folder = res.data.updateFolderName;
          await commit("setSelectedFolder", folder);
          await commit("updateFolder", folder);
        });
      } catch (e) {
        console.error(e);
        throw e;
      }
    },
    async removeFolder({ commit, state }, folderId) {
      try {
        const { mutate, onDone, onError } = await provideApolloClient(
          Client(state.auth)
        )(() => useMutation(DELETE_FOLDER));

        const result = await mutate({
          folderId: folderId,
        });

        onDone((result) => {
          console.log(result.data);
          commit("removeFolder", folderId);
          commit("removeLogEntries", folderId);
          return result.data.deleteFolder;
        });
        onError((error) => {
          console.log(error);
          throw Error(error.message);
        });

        return result?.data?.deleteFolder;
      } catch (e) {
        console.log(e);
      }
    },
    async updateSharedFolderStatus({ commit, state }, { folderId, status }) {
      try {
        const { onDone, onError, mutate } = await provideApolloClient(
          Client(state.auth)
        )(() => useMutation(UPDATE_SHARED_FOLDER_STATUS));
        mutate({
          updateStatusData: {
            folderId,
            status,
          },
        });
        onError((error) => {
          throw new Error(error.message);
        });
        onDone(async (res) => {
          console.log(res);
          let sharedFolder = res.data.updateSharedFolderStatus;
          await commit("updateSharedFolder", sharedFolder);
          await commit("setSelectedFolder", sharedFolder);
        });
      } catch (e) {
        console.log(e);
        throw e;
      }
    },
    async updateSharedFolderTags({ commit, state }, { folderId, tagId }) {
      try {
        const { onDone, onError, mutate } =  provideApolloClient(
          Client(state.auth)
        )(() => useMutation(EDIT_ADMIN_TAGS));
       const res = await mutate({
          folderId,
          tagId,
        });
        commit("updateSharedFolder", res.data.editAdminTags);
        commit("setSelectedFolder", res.data.editAdminTags);
        onError((error) => {
          throw new Error(error.message);
        });
      
      } catch (e) {
        console.log(e);
        throw e;
      }
    },
    async createOrUpdateSharedFolderResolution(
      { commit, state },
      { folderId, resolution }
    ) {
      try {
        const { mutate, onDone, onError } = await provideApolloClient(
          Client(state.auth)
        )(() => useMutation(UPDATE_SHARED_FOLDER_RESOLUTION));
        mutate(
          {
            updateResolutionData: {
              folderId,
              resolution,
            },
          },
          {
            fetchPolicy: "no-cache",
          }
        );

        onDone((result) => {
          let sharedFolder = result.data.createOrUpdateSharedFolderResolution;
          commit("setSelectedFolder", sharedFolder);
        });
        onError((error) => {
          console.log(error);
          throw Error(error.message);
        });
      } catch (e) {
        console.log(e);
        throw e;
      }
    },

    async toggleFolderAnonymity({ commit }, { folderId, isAnonymous }) {
      try {
        const {
          data: { toggleFolderAnonymity },
        } = await Client.mutate({
          mutation: UNDO_FOLDER_ANONYMITY,
          variables: {
            anonymousFolderData: {
              folderId,
              isAnonymous,
            },
          },
          fetchPolicy: "no-cache",
        });
        await commit("updateFolder", toggleFolderAnonymity);
        await commit("setSelectedFolder", toggleFolderAnonymity);
      } catch (e) {
        console.log(e);
      }
    },

    async reviewedFolder({ commit }, updateData) {
      try {
        const folder = await Client.mutate({
          mutation: REVIEWED_FOLDER,
          variables: {
            reviewedData: updateData,
          },
          fetchPolicy: "no-cache",
        });
        await commit("updateSharedFolder", folder.data.reviewedFolder);
        await commit("addUserDataToSharedFolders", this.state.employeeData);
        await commit(
          "setSelectedFolder",
          this.state.allEmployeeFolders.find(
            (folder) => folder.id == updateData.folderId
          )
        );
      } catch (e) {
        console.log(e);
        throw e;
      }
    },

    async createIncident({ commit, state }, { log }) {
      console.log("log:", log);
      const { mutate, onDone, onError } = await provideApolloClient(
        Client(state.auth)
      )(() => useMutation(ADD_INCIDENT));

      mutate({ newIncidentData: log });
      console.log("log:", log);

      onDone(async (res) => {
        console.log(res);
        const incidentId = res.data.addIncident.id;

        const { onResult, onError } = provideApolloClient(Client(state.auth))(
          () =>
            useQuery(
              GET_FOLDER,
              {
                folderId: log.folderId,
              },
              {
                fetchPolicy: "no-cache",
              }
            )
        );
        onResult(async (res) => {
          console.log(res);
          const folder = res.data.getFolder;
          const { incidents } = res.data.getFolder;
          const incident = incidents.find((i) => i.id == incidentId);
          await commit("setSelectedFolder", folder);
          await commit("updateFolder", folder);
          await commit("setSelectedLogEntry", incident);
          // adds log to userLogEntries
          await commit("addLogEntry", { incident, folderId: folder.id });
        });
      });
      onError((err) => {
        console.log(err);
        throw err;
      });
    },

    async amplitudeTrack({ commit, state }, { data, eventName }) {
      let payload = {
        event_properties: data,
        user_properties: this.getters.getCurrentGqlUser.user_properties,
      };
      const userId = this.getters.getCurrentGqlUser.id;
      const { mutate, onDone, onError } = await provideApolloClient(
        Client(state.auth)
      )(() => useMutation(AMPLITUDE_TRACK));

      await mutate({
        amplitudeTrackInput: {
          json: JSON.stringify(payload),
          eventName: eventName,
          ...(userId && { userId: userId }),
        },
      });
    },
    async addAttachment({ commit, state }, attachment) {
      const incidentId = this.getters.getSelectedLogEntry?.id;
      console.log(incidentId);
      const folderId = this.getters.getSelectedFolder.id;
      const orgId = this.getters.orgId || "freeUser";
      const data = new FormData();
      data.append("files", attachment);

      await fetch(
        `${process.env.VUE_APP_SERVER_URL}/speakfully/attachments/upload/${folderId}/${incidentId}/${orgId}`,
        {
          method: "POST",
          body: data,
        }
      ).then((response) => {
        if (response.status >= 400) {
          console.log(response.statusText);
          throw new Error(response.statusText);
        }
      });
      await new Promise((resolve, reject) => {
        const { onResult, onError } = provideApolloClient(Client(state.auth))(
          () =>
            useQuery(
              GET_FOLDER,
              {
                folderId: folderId,
              },
              {
                fetchPolicy: "network-only",
              }
            )
        );
        onResult((result) => {
          const folder = result.data.getFolder;
          let incident = {};
          const { incidents } = result.data.getFolder;
          incident = incidents.find((i) => i.id == incidentId);
          commit("setSelectedFolder", folder);
          commit("updateFolder", folder);
          commit("setSelectedLogEntry", incident);
          // adds log to userLogEntries
          commit("addLogEntry", { incident, folderId: folder.id });
          resolve();
        });

        onError((error) => {
          // Handle error
          console.error(error);
          reject(error);
        });
      });
    },
    async addNoteAttachment({ commit }, attachment) {
      const noteId = this.getters.getCurrentNote.id;
      const folderId = this.getters.getSelectedFolder.id;
      const orgId = this.getters.orgId || "freeUser";
      const data = new FormData();
      data.append("files", attachment);
      await fetch(
        `${process.env.VUE_APP_SERVER_URL}/speakfully/attachments/uploadNoteAttachment/${folderId}/${noteId}/${orgId}`,
        {
          method: "POST",
          body: data,
        }
      ).then(async (res) => {
        if (res.status >= 400) {
          console.log(res.statusText);
          throw new Error("Error creating attachment");
        } else {
          const data = res.json();
          await commit("addAttachmentToNote", data);
        }
      });
    },
    async deleteNoteAttachment(
      { commit, state },
      { attachmentId, attachmentPath, noteId }
    ) {
      try {
        const deleteAttachmentData = { attachmentId, attachmentPath };

        const { mutate, onDone, onError } = await provideApolloClient(
          Client(state.auth)
        )(() => useMutation(DELETE_ATTACHMENT));

        const result = await mutate({
          deleteAttachmentData,
        });

        onDone((result) => {
          console.log("deleting store");
          commit("removeNoteAttachment", { attachmentId, noteId });
        });
        onError((error) => {
          console.log(error);
          throw Error(error.message);
        });
      } catch (e) {
        console.log(e);
        throw e;
      }
    },
    async deleteResource({ commit }, resourceData) {
      try {
        await Client.mutate({
          mutation: REMOVE_RESOURCE,
          variables: {
            removeResourceData: resourceData,
          },
        });
        await commit("removeResource", resourceData);
      } catch (e) {
        console.log(e);
      }
    },
    async createResource(
      { commit },
      { resource, attachments, titleAttachments }
    ) {
      try {
        const resourceInfo = await Client.mutate({
          mutation: ADD_RESOURCE,
          variables: {
            newResourceData: resource,
          },
        });
        const resourceId = resourceInfo.data.addResource.id;
        if (attachments && attachments.length >= 1) {
          const orgId = this.getters.orgId || "freeUser";
          let files = attachments;
          const data = new FormData();
          files.forEach((file) => {
            data.append("files", file);
          });
          await fetch(
            `${process.env.VUE_APP_SERVER_URL}/speakfully/attachments/uploadResource/${resourceId}/${orgId}/false`,
            {
              method: "POST",
              body: data,
            }
          ).then((response) => {
            if (response.status > 400) {
              console.log(response.statusText);
              throw new Error(response.statusText);
            }
          });
        }
        if (titleAttachments && titleAttachments.length >= 1) {
          const orgId = this.getters.orgId || "freeUser";
          let files = titleAttachments;
          const data = new FormData();
          files.forEach((file) => {
            data.append("files", file);
          });
          await fetch(
            `${process.env.VUE_APP_SERVER_URL}/speakfully/attachments/uploadResource/${resourceId}/${orgId}/true`,
            {
              method: "POST",
              body: data,
            }
          ).then((response) => {
            if (response.status > 400) {
              console.log(response.statusText);
              throw new Error(response.statusText);
            }
          });
        }
        const updatedOrg = await Client.query({
          query: GET_ORG,
          variables: {
            orgId: resource.orgId,
          },
          fetchPolicy: "network-only",
        });

        const org = updatedOrg.data.getOrg;
        await commit("setOrgResources", org.resources);
      } catch (e) {
        console.log(e);
        throw e;
      }
    },
    async createSurvey({ commit }, survey) {
      try {
        const {
          data: { addSurvey },
          error,
        } = await Client.mutate({
          mutation: ADD_SURVEY,
          variables: {
            newSurveyData: survey,
          },
        });
        if (error) {
          console.log(error.message);
          throw Error(error.message);
        }
        commit("updateOrgSurvey", addSurvey);
      } catch (e) {
        console.log(e);
        throw e;
      }
    },
    async endSurvey({ commit }, surveyId) {
      try {
        const {
          data: { endSurvey },
          error,
        } = await Client.mutate({
          mutation: END_SURVEY,
          variables: {
            endSurveyData: {
              surveyId: surveyId,
              endDate: dayjs().utc().subtract(1, "hour").toDate(),
            },
          },
        });
        if (error) {
          console.log(error.message);
          throw Error(error.message);
        }
        commit("updateOrgSurvey", endSurvey);
      } catch (e) {
        console.log(e);
        throw e;
      }
    },
    async deleteSurvey({ commit }, surveyId) {
      try {
        await Client.mutate({
          mutation: REMOVE_SURVEY,
          variables: {
            removeSurveyData: {
              orgId: this.getters.getUserOrgId,
              surveyId: surveyId,
            },
          },
        });
        await commit("removeSurvey", surveyId);
      } catch (e) {
        console.log(e);
      }
    },
    async deleteLog({ commit, state }, incidentData) {
      commit("removeSelectedLogEntry");
      try {
        const { mutate, onDone, onError } = await provideApolloClient(
          Client(state.auth)
        )(() => useMutation(DELETE_INCIDENT));
        mutate({
          removeIncidentData: incidentData,
        });

        onDone((result) => {
          commit("updateFolder", result.data.deleteIncident);

          if (
            this.state.selectedFolder &&
            this.state.selectedFolder.id == result.data.deleteIncident.id
          ) {
            commit("setSelectedFolder", result.data.deleteIncident);
          }
          // removes log from userLogEntries
          commit("removeLogEntry", incidentData);
        });
        onError((error) => {
          console.log(error);
          throw Error(error.message);
        });
      } catch (e) {
        console.log(e);
        throw e;
      }
    },
    async deleteCustomLog({ commit, state }, incidentData) {
      try {
        const removedIncident = await Client.mutate({
          mutation: DELETE_CUSTOM_INCIDENT,
          variables: {
            removeCustomIncidentData: incidentData,
          },
        });
        commit("updateFolder", removedIncident.data.deleteCustomIncident);
        commit("removeSelectedLogEntry");
        if (
          this.state.selectedFolder &&
          this.state.selectedFolder.id ==
            removedIncident.data.deleteCustomIncident.id
        ) {
          commit(
            "setSelectedFolder",
            removedIncident.data.deleteCustomIncident
          );
        }
        // removes log from userLogEntries
        commit("removeLogEntry", incidentData);
      } catch (e) {
        console.log(e);
      }
    },
    async deleteAttachment({ commit, state }, deleteAttachmentData) {
      try {
        await new Promise((resolve, reject) => {
          const { mutate, onDone, onError } = provideApolloClient(
            Client(state.auth)
          )(() => useMutation(DELETE_ATTACHMENT));

          mutate({
            deleteAttachmentData,
          });

          onDone((result) => {
            commit("removeAttachment", deleteAttachmentData.attachmentId);
            console.log("deleting");
            resolve();
          });

          onError((error) => {
            reject(error);
          });
        });
      } catch (e) {
        console.log(e);
        throw e;
      }
    },
    selectFolder({ commit }, folder) {
      commit("setSelectedFolder", folder);
    },

    async exportFolderQuestions({ commit, state }, exportQuestions) {
      try {
        console.log(exportQuestions);
        const { mutate, onDone, onError } = await provideApolloClient(
          Client(state.auth)
        )(() => useMutation(EXPORT_FOLDER_QUESTIONS));
        mutate({
          exportFolderData: exportQuestions,
        });

        onDone((result) => {
          console.log(result);
          commit("updateFolder", result.data.exportFolderQuestions);
          commit("setSelectedFolder", result.data.exportFolderQuestions);
        });
        onError((error) => {
          console.log(error);
          throw Error(error.message);
        });
      } catch (e) {
        console.log(e);
      }
    },
    async exportFolder({ commit, state }, { folderId, isHR, timezone }) {
      try {
        let newFolder;
        let pdf;
        const { mutate, onDone, onError } = await provideApolloClient(
          Client(state.auth)
        )(() => useMutation(EXPORT_FOLDER));
        const exportFolderPromise = new Promise((res, rej) => {

          mutate({
            confirmExport: {
              folderId,
              isHR,
              timezone,
            },
          });

          onDone(async (result) => {
            pdf = result;
            if (!isHR) {
              const { onResult, onError } = provideApolloClient(
                Client(state.auth)
              )(() =>
                useQuery(GET_FOLDER, {
                  folderId,
                })
              );
              onResult(async (res) => {
                newFolder = res.data?.getFolder;
              });
            } else {
              const { onResult, onError } = provideApolloClient(
                Client(state.auth)
              )(() =>
                useQuery(
                  GET_SHARED_FOLDER,
                  {
                    folderId,
                  },
                  {
                    fetchPolicy: "no-cache",
                  }
                )
              );
              onResult(async (res) => {
                newFolder = res.data?.getSharedFolder;
              });
            }

            const folderWithPDF = { ...state?.selectedFolder, ...newFolder, pdf: pdf.data.exportFolder };
            await commit("addExportDataToFolder", {
              newFolder: folderWithPDF,
              isHR: isHR,
            });
            await commit("setSelectedFolder", folderWithPDF);
            res();
          });
          onError((err) => {
            console.log(`Error in exportFolder Mutation: ${err}`);
            rej(err);
          });

        });

        await exportFolderPromise;
      } catch (e) {
        console.log(`Error in exporting folder ${e}`);
      }
    },
    async submitFolder({ commit, state }, submitFolderData) {
      try {
        const { mutate, onDone, onError } = await provideApolloClient(
          Client(state.auth)
        )(() => useMutation(SUBMIT_FOLDER));
        mutate(
          {
            submitFolderData: submitFolderData,
          },
          {
            fetchPolicy: "no-cache",
          }
        );

        onDone((result) => {
          commit("updateFolder", result.data.submitFolder);
          commit("setSelectedFolder", result.data.submitFolder);
        });
        onError((error) => {
          console.log(error);
          throw Error(error.message);
        });
      } catch (e) {
        console.log(e);
        throw e;
      }
    },
    async internalShareFolder({ commit, state }, sendInternalData) {

      try {
        const { mutate, onDone, onError } = await provideApolloClient(
          Client(state.auth)
        )(() => useMutation(INTERNAL_SHARE_FOLDER));
        mutate(
          {
            sendInternalData: sendInternalData,
          },
          {
            fetchPolicy: "no-cache",
          }
        );

        onDone((result) => {
            commit(
            "updateSharedFolder",
            result.data.internalShareFolder
          );
           commit("addUserDataToSharedFolders");
           commit(
            "setSelectedFolder",
            result.data.internalShareFolder
          );
        });
        onError((error) => {
          console.log(error);
          throw Error(error.message);
        });
      } catch (e) {
        console.log(e);
        throw e;
      }
    },

    async externalShareFolder({ commit, state }, sendExternalData) {
      
      try {
        const { mutate, onDone, onError } = await provideApolloClient(
          Client(state.auth)
        )(() => useMutation(EXTERNAL_SHARE_FOLDER));
        mutate(
          {
            sendExternalData: sendExternalData,
          },
          {
            fetchPolicy: "no-cache",
          }
        );

        onDone((result) => {
           commit(
            "updateSharedFolder",
            result.data.externalShareFolder
          );
           commit("addUserDataToSharedFolders");
           commit(
            "setSelectedFolder",
            result.data.externalShareFolder
          );
        });
        onError((error) => {
          console.log(error);
          throw Error(error.message);
        });
      } catch (e) {
        console.log(e);
        throw e;
      }
    },

    async unshareFolder({ commit, state }, unshareFolderData) { 
      try {
        const { mutate, onDone, onError } = await provideApolloClient(
          Client(state.auth)
        )(() => useMutation(UNSHARE_FOLDER));
        mutate(
          {
            folderId: unshareFolderData.folderId,
            userId: unshareFolderData.userId,
          },
          {
            fetchPolicy: "no-cache",
          }
        );

        onDone((result) => {
           commit(
            "updateSharedFolder",
            result.data.revokeSharedWithPermissions
          );
           commit("addUserDataToSharedFolders");
           commit(
            "setSelectedFolder",
            result.data.revokeSharedWithPermissions
          );
        });
        onError((error) => {
          console.log(error);
          throw Error(error.message);
        });
      } catch (e) {
        console.log(e);
        throw e;
      }
    },

    async getEmployeeUserData({ commit, state }) {
      try {
        const { onResult, onError } = provideApolloClient(Client(state.auth))(
          () =>
            useQuery(GET_EMPLOYEE_DATA, {
              fetchPolicy: "network-only",
            })
        );
        onResult(async (result) => {
          const employeeData = await result?.data?.getEmployeeData;

          commit("setEmployeeData", employeeData);
        });
        // const employees = computed(() => query.result.value ?? "");
        // const employees = await Client.query({
        //   query: GET_EMPLOYEE_DATA,
        //   fetchPolicy: "network-only",
        // });
        // commit("setEmployeeData", employees.value.data.getEmployeeData);
      } catch (e) {
        console.log(e);
        throw e;
      }
    },

    async createOrUpdateNote({ commit, state }, noteData) {
      const { mutate, onDone, onError } = await provideApolloClient(
        Client(state.auth)
      )(() => useMutation(CREATE_OR_UPDATE_NOTE));
      mutate({
        noteData: noteData,
      });

      onDone((result) => {
        commit("addOrEditNote", result.data.createOrUpdateNote);
        commit("setNote", result.data.createOrUpdateNote);
        commit("addUserNameToNotes");
      });
      onError((error) => {
        console.log(error);
        throw Error(error.message);
      });
    },
    async deleteNote({ commit, state }, noteData) {
      const { mutate, onDone, onError } = await provideApolloClient(
        Client(state.auth)
      )(() => useMutation(DELETE_NOTE));
      mutate(
        {
          deleteNoteData: noteData,
        },
        {
          fetchPolicy: "no-cache",
        }
      );
      onDone((result) => {
        commit("setSelectedFolderNotes", result.data.deleteNote);
        commit("addUserNameToNotes");
      });
      onError((error) => {
        console.log(error);
        throw Error(error.message);
      });
    },
    async getFolderNotes({ commit, state }, folderId) {
      const { onResult, onError } = provideApolloClient(Client(state.auth))(
        () =>
          useQuery(
            GET_FOLDER_NOTES,
            {
              folderId: folderId,
            },
            {
              fetchPolicy: "no-cache",
            }
          )
      );
      onResult(async (res) => {
        const folder = res.data.getFolderNotes;
        await commit("setSelectedFolderNotes", folder);

        if (!!this.getters.getCurrentNote) {
          const newNote = folder.notes.find(
            (note) => note.id === this.getters.getCurrentNote.id
          );
          await commit("setNote", newNote);
        }
        await commit("addUserNameToNotes");
      });
    },

    async addTrialStartDate({ commit }, orgId) {
      const date = new Date().toISOString();
      await commit("setTrialStartDate", date);
      await Client.mutate({
        mutation: UPDATE_ORG,
        variables: {
          updateOrgData: {
            id: orgId,
            trialStartDate: date,
          },
        },
      });
    },

    async getFolderMessages({ commit, state }, folderId) {
      const { onResult, onError } = provideApolloClient(Client(state.auth))(
        () =>
          useQuery(
            GET_FOLDER_MESSAGES,
            {
              folderId: folderId,
            },
            {
              fetchPolicy: "no-cache",
            }
          )
      );
      onError((error) => {
        throw new Error(error.message);
      });
      onResult(async (res) => {
        let { messages, ...folder } = res.data.getFolderMessages;
        messages.forEach((message) => {
          let user = state.employeeData.find(
            (employee) => employee.userId == message.userId
          );
          if (
            (message.userId === folder.user?.id && !!folder.isAnonymous) ||
            !message.userId
          ) {
            message.username = "Anonymous";
          } else if (!!user) {
            message.username = user.name;
          } else {
            message.username = "";
          }
        });
        await commit("setSelectedMessages", messages);
      });
    },

    async createFolderMessage(
      { commit, state },
      { messageText, parentDocumentId }
    ) {
      const { mutate, onDone, onError } = await provideApolloClient(
        Client(state.auth)
      )(() => useMutation(CREATE_FOLDER_MESSAGE));
      mutate({
        messageData: {
          message: messageText,
          folderId: parentDocumentId,
        },
      });
      onError((error) => {
        console.log(error);
        throw Error(error.message);
      });
      onDone((result) => {
        console.log("++++++", result);
        let { message, anonymous } = result.data.createFolderMessage;
        if (typeof message === undefined) message = {};
        if (anonymous && message) {
          message.username = "Anonymous";
        } else {
          let user = state.employeeData.find(
            (employee) => employee.userId === message.userId
          );
          message.username = !!user ? user.name : "";
        }
        commit("addMessage", message);
      });
      // const {
      //   data: { createFolderMessage },
      //   error,
      // } = await Client.mutate({
      //   mutation: CREATE_FOLDER_MESSAGE,
      //   variables: {
      //     messageData: {
      //       message: messageText,
      //       folderId: parentDocumentId,
      //     },
      //   },
      // });
      // if (error) {
      //   console.log(error.message);
      //   throw Error(error.message);
      // }
    },
  },
  getters: {
    showWelcomeModal(state) {
      return state.showWelcomeModal;
    },
    getAppLoading: (state) => {
      return state.appLoading || false;
    },
    routeLoading: (state) => {
      return state.routeLoading;
    },
    getUser: (state) => {
      return state.user;
    },
    getUserEmail: (state) => {
      return state.currentGqlUser.email;
    },
    getAuthId: (state) => {
      return state.currentGqlUser?.authId || null;
    },
    getUserRole: (state) => {
      return state.userRole || null;
    },
    getOrgSubmissions: (state) => {
      return state.org?.currentSubmissions || [];
    },
    getAllowedFolderTypes: (state) => {
      return state.org?.currentSubmissions?.map((sub) =>
        sub.folderType.toLowerCase()
      );
    },
    getPulseSurveysAndResponses: (state) => {
      return state.pulseSurveysAndResponses || [];
    },
    getOrgResources: (state) => {
      return state.org?.resources || [];
    },
    getOrgPlan: (state) => {
      return state.org?.planName || "";
    },
    getOrgAllowsExternalSharing: (state) => {
      return state.org?.allowExternalShare || false;
    },
    getAuth0Connection: (state) => {
      return state.auth0Connection || null;
    },
    getOrgPersonsInvolvedProperties: (state) => {
      return state.org?.personsInvolvedProperties;
    },
    getExperienceDateRequired: (state) => {
      return state.org?.experienceDateRequired;
    },
    getPeopleAnalyticsFeatureAllowed: (state) => {
      return state.org?.peopleAnalyticsFeature;
    },
    getUserId: (state) => {
      return (state.currentGqlUser && state.currentGqlUser.id) || "";
    },
    getUserOrgId: (state) => {
      return state.org?.id || null;
    },
    getOrgName: (state) => {
      // No spaces - refers to auth0 org string
      return state.org?.name;
    },
    getOrgDisplayName: (state) => {
      return state.org?.displayName || "";
    },
    getOrgNoteActionOptions: (state) => {
      return state.org?.noteActionOptions || [];
    },
    getOrgLogoutUrl: (state) => {
      return state.org.quickExitUrl || "https://google.com";
    },
    getOrgIsMultilingual: (state) => {
      return state.org?.isMultilingual || false;
    },
    getOrgIsIntegrated: (state) => {
      return state.org?.isIntegratedOrg || false;
    },
    getOrgAllowsAnonymous: (state) => {
      return state.org?.allowAnonymous || false;
    },
    getShowResourcesTab: (state) => {
      return state.org?.showResourcesTab || false;
    },
    getShowSupportTab: (state) => {
      return state.org?.showSupportTab;
    },
    getOrgAllowsAddingPersonalEmail: (state) => {
      return state.org?.allowAddingPersonalEmail || false;
    },
    getOrgAllowsIdentityDisclosure: (state) => {
      return state.org?.allowIdentityDisclosure || false;
    },
    getOrgAllowMessaging: (state) => {
      return state.org?.allowMessaging || false;
    },
    getUserAuthId: (state) => {
      return state.userAuthId;
    },
    getUserNotifcations: (state) => {
      return state.userNotificatons;
    },
    getUserNotifcationTemplates: (state) => {
      return state.notificationTemplates;
    },
    getTotalLogEntries: (state) => {
      return state.userLogEntries.length;
    },
    getAllLogEntries: (state) => {
      return state.userLogEntries;
    },
    getCurrentGqlUser: (state) => {
      return state.currentGqlUser;
    },
    getUserFolders: (state) => {
      return state.userFolders;
    },
    getUserFeedback: (state) => {
      return state.userFeedback;
    },
    getOrgFeedback: (state) => {
      return state.orgFeedback;
    },
    getTotalFolders: (state) => {
      return state.userFolders.length;
    },
    getUserSharedFolders: (state) => {
      return state.userSharedFolders;
    },
    getEmployeeFolders: (state) => {
      return state.allEmployeeFolders;
    },
    getSelectedFolder: (state) => {
      // const selectedFolder = state.selectedFolder;
      // if (selectedFolder?.sharedWith?.length) {
      //   selectedFolder.sharedWith = selectedFolder.sharedWith.filter(
      //     (record) => record.isActive
      //   );
      // }
      return state.selectedFolder;
    },
    getSelectedFeedback: (state) => {
      return state.selectedFeedback;
    },
    getSelectedMessages: (state) => {
      return state.selectedMessages;
    },
    getSelectedEmployeeAnalytics: (state) => {
      return state.selectedEmployeeAnalytics;
    },
    getSelectedEmployeeFolderData: (state) => {
      return state.selectedEmployeeFolderData;
    },
    getSelectedFolderNotes: (state) => {
      return state.selectedFolderNotes;
    },
    getSelectedFolderMessages: (state) => {
      return state.selectedFolderMessages;
    },
    getSelectedLogEntry: (state) => {
      return state.selectedLogEntry;
    },
    getSelectedLogAttachments: (state) => {
      return state.selectedLogEntry.attachments;
    },
    getEmployeeData: (state) => {
      return state.employeeData;
    },
    getHrEmployees: (state) => {
      return state.hrEmployees;
    },
    getSelectedEmployee: (state) => {
      return state.selectedEmployee;
    },
    getFolderMetadata: (state) => {
      return state.folderMetadata;
    },
    getTrialStartDate: (state) => {
      return state.trialStartDate;
    },
    getCurrentNote: (state) => {
      return state.note;
    },
    getExportPDF: (state) => {
      return state.exportPDFData;
    },
    getStatusTrendData: (state) => {
      return state.statusTrendData;
    },
    getIncidentMetadata: (state) => {
      return state.incidentMetadata;
    },
    getAnonymousByMonth: (state) => {
      return state.anonymousByMonth;
    },
    getAvailableFilterValues: (state) => {
      return state.availableUserFilterValues;
    },
    getPeopleAnalytics: (state) => {
      return state.peopleAnalytics;
    },
    getOrgFilterFactors: (state) => {
      return state.org.filterFactors;
    },
    getActiveUserDataFilters: (state) => {
      return state.activeUserDataFilters;
    },
    getActiveFilterUserCount: (state) => {
      return state.activeFilterUserCount;
    },
    getStatusHistory: (state) => {
      //working on it
      const folders = state.userFolders;
      let statusHistory = [];
      folders.forEach((x) =>
        x.statusHistory.forEach((y) =>
          statusHistory.push({
            date: y.date,
            status:
              y.status.toLowerCase() === "saved" ? "folderSaved" : y.status,
            type: x.__typename,
            name: x.name,
          })
        )
      );
      folders.forEach((x) =>
        x.incidents.forEach((y) =>
          y.statusHistory.forEach((z) =>
            statusHistory.push({
              date: z.date,
              status: z.status,
              type: y.__typename === "Incident" ? "log entry" : y.__typename,
              id: x.id,
              folderName: x.name,
            })
          )
        )
      );
      statusHistory = statusHistory.filter((x) => x.status !== "folderSaved");
      const sortByValue = statusHistory.sort(function (a, b) {
        return b.date < a.date ? -1 : b.date > a.date ? 1 : 0;
      });
      return sortByValue;
    },
  },
});
