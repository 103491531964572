
import Vue, {
  computed,
  defineComponent,
  getCurrentInstance,
  onMounted,
  ref,
} from "vue";

import EventBus from "@/EventBus/eventBus";
import Resources from "@/components/toolbar/Resources.vue";
import { HUBSPOT_LEAD } from "../../graphql/userQueries";
import RadialProgressBar from "vue-radial-progress";
import UpgradeRequestConfirm from "../trial/upgradeRequestConfirmation.vue";
import FeedbackModal from "@/components/toolbar/Feedback.vue";
import ReferModal from "@/components/toolbar/Refer.vue";
import dayjs from "@/plugins/dayjs.plugin";
import { useRouter } from "vue-router";
import { useStore } from "vuex";
import { useMutation } from "@vue/apollo-composable";
import { useRole } from "@/plugins/rolePlugin";
import { usePlan } from "@/plugins/planPlugin";
import { useAuth0 } from "@auth0/auth0-vue";

export default defineComponent({
  name: "Header",
  components: {
    Resources,
    RadialProgressBar,
    UpgradeRequestConfirm,
    FeedbackModal,
    ReferModal,
  },
  props: {
    activeMenu: {},
    navOpen: {},
    unread: {},
  },
  setup(props) {
    const scrolled = ref(false);
    const fill = ref({ color: "#FFFFFF" });
    const router = useRouter();
    const store = useStore();
    const instance = getCurrentInstance();
    const feedbackOpen = ref(false);
    const referOpen = ref();
    const auth: any = useAuth0();
    const role = useRole();
    const plan = usePlan();
    const showDash = () => {
      router.push({ name: "dashboard" });
    };

    const isCSE = computed(() => {
      return store.getters.getAuth0Connection === "cse";
    });

    const Nav = () => {
      return (props.navOpen as any)();
    };

    const orgId = computed(() => {
      return store.getters.getUserOrgId;
    });

    const user = computed(() => {
      return auth.user.value?.name || auth.user.value?.email;
    });

    const currentUser = computed(() => {
      return store.getters.getCurrentGqlUser;
    });

    const daysSpent = computed(() => {
      const startDate = store.getters.getTrialStartDate;
      const today = dayjs();
      const daysSince = today.diff(startDate, "days");
      return daysSince > 30 ? 30 : daysSince;
    });

    const daysLeft = () => {
      const daysRemaining = 30 - daysSpent.value;
      return daysRemaining < 0 ? 0 : daysRemaining;
    };

    async function toggleCSEView() {
      const realConnection = computed(() => store.getters.getUser.connection);
      if (isCSE.value) {
        await store.commit("setAuth0Connection", realConnection.value);
        router.push({ name: "dashboard" });
      } else {
        await store.commit("setAuth0Connection", "cse");
        router.push({ name: "cse.dashboard" });
      }
    }

    const manageTrial = () => {
      router.push("manageTrial");
    };
    const toggleNav = () => {
      instance?.emit("onToggleNav");
    };

    const toggleToolbar = (menu: number) => {
      instance?.emit("onToggleToolbar", menu);
    
    };

    async function upgrade() {
      const fields = [
        {
          property: "note",
          value: "Upgrade Request",
        },
      ];
      const { mutate } = useMutation(HUBSPOT_LEAD);
      mutate({
        hubspotData: {
          email: auth.user.value?.email,
          fields: fields,
        },
      });
      // await apollo.mutate({
      //   mutation: HUBSPOT_LEAD,
      //   variables: {
      //     hubspotData: {
      //       email: $auth.user.email,
      //       fields: fields,
      //     },
      //   },
      // });
      // await ($refs["upgrade-request-modal"] as any).show();
    }

    const resourcesOpen = ref(false);
    const toggleResources = () => {
      resourcesOpen.value = !resourcesOpen.value;
    };
    onMounted(() => {
      EventBus.on("dashboard", toggleResources);
    });
    return {
      isCSE,
      manageTrial,
      daysLeft,
      upgrade,
      daysSpent,
      feedbackOpen,
      toggleCSEView,
      showDash,
      toggleToolbar,
      referOpen,
      toggleNav,
      Nav,
      role,
      plan,
    };
  },
});
