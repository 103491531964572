import { useAuth0 } from "@auth0/auth0-vue";
// import { store } from "../store";
import { useStore } from "vuex";
import { App, Plugin, computed, inject } from "vue";

// import { isDeepStrictEqual } from 'util';
interface PlanMethods {
  isNow: () => boolean;
  isBasic: () => boolean;
  isPro: () => boolean;
  isEnterprise: () => boolean;
  isTrial: () => boolean;
  value: () => string;
  isCSE: () => boolean;
  isSSO: () => boolean;
}
const planSymbol = Symbol();

export const PlanPlugin: Plugin = {
  install: (app, store) => {
    const auth: any = useAuth0();
    const orgPlan = computed(() => store.getters.getOrgPlan)
    const planMethods: PlanMethods = {
      isNow() {
        return orgPlan.value.toLowerCase() == "now";
        // return true
      },
      isBasic() {
        return orgPlan.value.toLowerCase() == "basic";
      },
      isPro() {
        return orgPlan.value.toLowerCase() == "pro";
      },
      isEnterprise() {
        return (
          orgPlan.value.toLowerCase() == "enterprise" ||
          orgPlan.value.toLowerCase() == "trial"
        );
      },
      isTrial() {
        return orgPlan.value.toLowerCase() == "trial";
      },
      value() {
        return orgPlan.value.toLowerCase();
      },
      isCSE() {
        return store.getters.getAuth0Connection?.toLowerCase() === "cse";
      },
      isSSO() {
        let user = auth?.user;
        return user?.isSSO;
      },
    };
    app.provide<PlanMethods>(planSymbol, planMethods);
  },
};
export function usePlan(): PlanMethods {
  const plan = inject<PlanMethods>(planSymbol);

  if (!plan) {
    throw new Error(
      "Unable to inject $plan. Make sure the planPlugin is installed."
    );
  }

  return plan;
}
