
import Vue, {
  computed,
  defineComponent,
  getCurrentInstance,
  onMounted,
  ref,
} from "vue";

import { UPDATE_USER } from "../../graphql/userQueries";
import { useStore } from "vuex";
import { useRole } from "@/plugins/rolePlugin";
import { useAuth0 } from "@auth0/auth0-vue";

export default defineComponent({
  name: "UserSettings",
  setup() {
    const name = ref("");
    const personalEmail = ref(null);
    const store = useStore();
    const instance = getCurrentInstance();
    const user = computed(() => {
      return store.getters.getCurrentGqlUser;
    });
    const role = useRole();
    const auth: any = useAuth0();
    const orgAllowsPersonalEmail = computed(() => {
      return store.getters.getOrgAllowsAddingPersonalEmail;
    });

    onMounted(() => {
      personalEmail.value = user.value.personalEmail || null;
    });

    async function submit() {
      if (name) {
        await store.dispatch("updateAuth0User", {
          name: name,
          authId: user.value.authId,
        });
        await auth.refreshUserObj();
      }
      if (role.isOrg()) {
        if (personalEmail !== user.value.personalEmail) {
          // await $apollo.mutate({
          //   // Query
          //   mutation: UPDATE_USER,
          //   // Parameters
          //   variables: {
          //     updateUserData: {
          //       personalEmail: personalEmail,
          //     },
          //   },
          // });
          user.value.personalEmail = personalEmail;
          await store.commit("setGqlUser", user);
        }
      }
      back();
    }

    const back = () => {
      instance?.emit("back");
    };
    return {
      orgAllowsPersonalEmail,
      user,
      personalEmail,
      name,
      submit,
      back,
      role,
    };
  },
});
