import { useAuth0 } from "@auth0/auth0-vue";
import { App, Plugin, inject, ref } from "vue";

interface RoleMethods {
  role: () => string | undefined;
  isOrg: () => boolean;
  isAdmin: () => boolean;
  isHR: () => boolean;
  isHRViewer: () => boolean;
  isExternal: () => boolean;
  isSpeakfullyAdmin: () => boolean;
  value: () => string | undefined;
}

const roleSymbol = Symbol();
const rolePlugin: Plugin = {
  install: (app: App, auth) => {
    const roleMethods: RoleMethods = {
      role() {
        const user = ref(auth?.user.value);      
        return ( user.value && user.value["https://speakfully.com/roles"] &&
          user.value["https://speakfully.com/roles"][0]) ||
        null;
      },
      isOrg() {
        let role = this.role();
        return (
          role === "HRAdmin" ||
          role === "HRViewer" ||
          role === "OrgUser" ||
          role === "SpeakfullyAdmin"
        );
      },
      isAdmin() {
        let role = this.role();
        return role === "HRAdmin" || role === "SpeakfullyAdmin";
      },
      isHR() {
        let role = this.role();
        return (
          role === "HRAdmin" ||
          role === "HRViewer" ||
          role === "SpeakfullyAdmin"
        );
      },
      isHRViewer() {
        let role = this.role();
        return role === "HRViewer";
      },
      isExternal() {
        let role = this.role();
        return role === "ExternalUser";
      },
      isSpeakfullyAdmin() {
        let role = this.role();
        return role === "SpeakfullyAdmin";
      },
      value() {
        let role = this.role();
        return role;
      },
    };

    app.provide<RoleMethods>(roleSymbol, roleMethods);
  },
};

export default rolePlugin;

export function useRole(): RoleMethods {
  const role = inject<RoleMethods>(roleSymbol);

  if (!role) {
    throw new Error(
      "Unable to inject $role. Make sure the rolePlugin is installed."
    );
  }

  return role;
}
