import gql from "graphql-tag";
import { FolderFragment, SharedFolderFragment, FolderNoteFragment, MessageFragment, SubmissionFragment } from "./fragments";

//FOLDER SERVER QUERIES
export const ADD_FOLDER = gql`
  mutation addFolder($newFolderData: NewFolderInput!) {
    addFolder(newFolderData: $newFolderData) {
      ...FolderFragment
    }
  }
  ${FolderFragment}
`;

export const UPDATE_FOLDER_NAME = gql`
  mutation updateFolderName($updateFolderNameData: UpdateFolderNameInput!) {
    updateFolderName(updateFolderNameData: $updateFolderNameData) {
      ...FolderFragment
    }
  }
  ${FolderFragment}
`;

export const GET_FOLDER = gql`
  query getFolder($folderId: String!) {
    getFolder(folderId: $folderId) {
      ...FolderFragment
    }
  }
  ${FolderFragment}
`;

export const GET_SHARED_FOLDER = gql`
  query getSharedFolder($folderId: String!) {
    getSharedFolder(folderId: $folderId) {
      ...on SharedFolder {
        ...SharedFolderFragment
      }
      ...on AnonymousSubmission {
        ...SubmissionFragment
      }
    }
  }
  ${SharedFolderFragment}
  ${SubmissionFragment}
`;

export const UPDATE_SHARED_FOLDER_STATUS = gql`
  mutation updateSharedFolderStatus($updateStatusData: UpdateFolderStatusInput!) {
    updateSharedFolderStatus(updateStatusData: $updateStatusData) {
      ...on SharedFolder {
        ...SharedFolderFragment
      }
      ...on AnonymousSubmission {
        ...SubmissionFragment
      }
    }
  }
  ${SharedFolderFragment}
  ${SubmissionFragment}
`;

export const UNSHARE_FOLDER = gql`
	mutation revokeSharedWithPermissions(
		$folderId: String!,
    $userId: String!
	) {
		revokeSharedWithPermissions(folderId: $folderId, userId: $userId) {
			... on SharedFolder {
				...SharedFolderFragment
			}
			... on AnonymousSubmission {
				...SubmissionFragment
			}
		}
	}
	${SharedFolderFragment}
	${SubmissionFragment}
`

export const GET_EMPLOYEE_FOLDERS = gql`
					query getEmployeeFolderList($queryInput: SharedFolderListInput!) {
						getEmployeeFolderList(queryInput: $queryInput) {
							id
							name
							userId
              reference_id
              isIntegratedFolder
							reportedBy
							isAnonymous
							folderType
							createdDate
							submittedDate
							currentStatus
							tags {
								tag
								_id
								category
								isActive
							}
							personsInvolved {
								userId
								type
								name
							}
							answers {
                textFieldValue
								questionLabel
								option
							}
							sharedWith {
								userId
								_id
								sharedBy
								shareMethod
								notificationsEnabled
								email
								isActive
							}
						}
					}
				`

export const EXPORT_FOLDER_QUESTIONS = gql`
  mutation exportFolderQuestions($exportFolderData: ExportQuestionsInput!) {
    exportFolderQuestions(exportFolderData: $exportFolderData) {
      ...FolderFragment
    }
  }
  ${FolderFragment}
`;

export const EXPORT_FOLDER = gql`
  mutation exportFolder($confirmExport: ExportFolderInput!) {
    exportFolder(confirmExport: $confirmExport) {
      blobUrl
      fileName
    }
  }
`;

export const GET_FOLDER_EXPORT_DATA = gql`
  query getPDFData($folderId: String!, $exporterId: String!) {
   getPDFData(folderId: $folderId, exporterId: $exporterId) {
     title
     author
     exporter {
       role
       name
     }
     experiences {
      id: _id
      description
      isDraft
      dateOfIncident
      statusHistory {
        status
        date
        userId
      }
      attachments {
        id
        filepath
        incidentId
        filename
        fileUrl
      }
      answers {
        textFieldValue
        questionLabel
        questionType
        questionMedia
        questionGroupLabel
        option
        otherResponse
      }
      witnesses {
        name
        relationType
      }
     }
     activityHistory {
       date
       action
       details
       username
       icon
     }
     notes {
       date
       comment
       username
       id
     }
     currentStatus
     personsInvolved {
      name
      type
      title
     }
     willingToTalk
   }
  }
`;

export const HR_EXPORT_FOLDER = gql`
  mutation hrExportFolder($confirmExport: ExportFolderInput!) {
    exportFolder(confirmExport: $confirmExport) {
      ...SharedFolderFragment
    }
  }
  ${SharedFolderFragment}
`;

export const SUBMIT_FOLDER = gql`
  mutation submitFolder($submitFolderData: NewSharedFolderInput!) {
    submitFolder(submitFolderData: $submitFolderData) {
      ...FolderFragment
    }
  }
  ${FolderFragment}
`;

export const INTERNAL_SHARE_FOLDER = gql`
					mutation internalShareFolder(
						$sendInternalData: NewInternalShareInput!
					) {
						internalShareFolder(sendInternalData: $sendInternalData) {
							... on SharedFolder {
								...SharedFolderFragment
							}
							... on AnonymousSubmission {
								...SubmissionFragment
							}
						}
					}
					${SharedFolderFragment}
					${SubmissionFragment}
				`

export const EXTERNAL_SHARE_FOLDER = gql`
  mutation externalShareFolder($sendExternalData: ExternalShareInput!) {
    externalShareFolder(sendExternalData: $sendExternalData) {
      ...on SharedFolder {
        ...SharedFolderFragment
      }
      ...on AnonymousSubmission {
        ...SubmissionFragment
      }
    }
  }
  ${SharedFolderFragment}
  ${SubmissionFragment}
`;

export const GET_FOLDER_NOTES = gql`
  query getFolderNotes($folderId: String!) {
    getFolderNotes(folderId: $folderId) {
      id
      notes {
        id
        date
        comment
        userId
        action {
          actionType
          otherAction
          actionDate
          media
        }
        attachments {
          id
          filepath
          incidentId
          filename
          fileUrl
          mimetype
        }
      }
    }
  }
`;

export const GET_FOLDER_MESSAGES = gql`
  query getFolderMessages($folderId: String!) {
    getFolderMessages(folderId: $folderId) {
      id
      user {
        id
      }
      isAnonymous
      messages {
        ...MessageFragment
      }
    }
  }
  ${MessageFragment}
`;


export const CREATE_FOLDER_MESSAGE = gql`
  mutation createFolderMessage($messageData: MessageInput!) {
    createFolderMessage(messageData: $messageData) {
      anonymous
      message {
        ...MessageFragment
      }
    }
  }
${MessageFragment}
`;

export const CREATE_FOLDER_UPDATE = gql`
  mutation addSubmissionUpdate($updateData: AnonymousSubmissionUpdateInput!) {
    addSubmissionUpdate(updateData: $updateData) {
      ...SubmissionFragment
    }
  }
  ${SubmissionFragment}
`;

export const CREATE_OR_UPDATE_NOTE = gql`
  mutation createOrUpdateNote($noteData: CreateOrUpdateNoteInput!) {
    createOrUpdateNote(noteData: $noteData) {
      id
      userId
      comment
      action {
        actionType
        media
        otherAction
        actionDate
      }
      attachments {
        id
        filepath
        noteId
        filename
        fileUrl
        mimetype
      }
      date
    }
  }
`;

export const DELETE_NOTE = gql`
  mutation deleteNote($deleteNoteData: DeleteNoteInput!) {
    deleteNote(deleteNoteData: $deleteNoteData) {
      id
      notes {
        id
        attachments {
          id
          filepath
          noteId
          filename
          fileUrl
          mimetype
        }
        comment
        action {
          actionType
          otherAction
          actionDate
          media
        }
        userId
        date
      }
    }
  }
`;

export const DELETE_FOLDER = gql`
  mutation deleteFolder($folderId: ID!) {
    deleteFolder(folderId: $folderId)
  }
`;

export const CLOSE_FOLDER = gql`
  mutation closeFolder($closeFolderData: UpdateFolderStatusInput!) {
    closeFolder(closeFolderData: $closeFolderData) {
      ...SharedFolderFragment
    }
  }
  ${SharedFolderFragment}
`;

export const REVIEWED_FOLDER = gql`
  mutation reviewedFolder($reviewedData: UpdateFolderStatusInput!) {
    reviewedFolder(reviewedData: $reviewedData) {
      ...SharedFolderFragment
    }
  }
  ${SharedFolderFragment}
`;
export const UNDO_FOLDER_ANONYMITY = gql`
  mutation toggleFolderAnonymity($anonymousFolderData: ToggleFolderAnonymityInput!) {
    toggleFolderAnonymity(anonymousFolderData: $anonymousFolderData) {
      ...FolderFragment
    }
  }
  ${FolderFragment}
`;

export const UPDATE_SHARED_FOLDER_RESOLUTION = gql`
  mutation createOrUpdateSharedFolderResolution($updateResolutionData: UpdateFolderResolutionInput!) {
    createOrUpdateSharedFolderResolution(updateResolutionData: $updateResolutionData) {
      ...on SharedFolder {
        ...SharedFolderFragment
      }
      ...on AnonymousSubmission {
        ...SubmissionFragment
      }
    }
  }
  ${SharedFolderFragment}
  ${SubmissionFragment}
`;

export const ADD_INCIDENT = gql`
  mutation addIncident($newIncidentData: NewIncidentInput!) {
    addIncident(newIncidentData: $newIncidentData) {
      id
    }
  }
`;

export const DELETE_INCIDENT = gql`
  mutation deleteIncident($removeIncidentData: RemoveIncidentInput!) {
    deleteIncident(removeIncidentData: $removeIncidentData) {
      ...FolderFragment
    }
  }
  ${FolderFragment}
`;

export const ADD_CUSTOM_INCIDENT = gql`
  mutation addCustomIncident($newCustomIncidentData: NewCustomIncidentInput!) {
    addCustomIncident(newCustomIncidentData: $newCustomIncidentData) {
      id
    }
  }
`;

export const DELETE_CUSTOM_INCIDENT = gql`
  mutation deleteCustomIncident(
    $removeCustomIncidentData: RemoveCustomIncidentInput!
  ) {
    deleteCustomIncident(removeCustomIncidentData: $removeCustomIncidentData) {
      ...FolderFragment
    }
  }
  ${FolderFragment}
`;

export const EDIT_ADMIN_TAGS = gql`
  mutation editAdminTags($folderId: ID!, $tagId: ID!) {
    editAdminTags(folderId: $folderId, tagId: $tagId) {
      ...on SharedFolder {
        ...SharedFolderFragment
      }
      ...on AnonymousSubmission {
        ...SubmissionFragment
      }
    }
  }
  ${SharedFolderFragment}
  ${SubmissionFragment}
  `;
