import gql from "graphql-tag";
import { OrgFragment, AppConfigFragment, SubmissionConfigFragment, AnalyticsConfigFragment } from "./fragments";

export const GET_ORG_BY_NAME = gql`
  query getOrgByName($orgName: String!) {
    getOrgByName(orgName: $orgName) {
      ...OrgFragment
    }
  }
  ${OrgFragment}
`;

export const GET_ORG = gql`
  query getOrg {
    getOrg {
      ...OrgFragment
    }
  }
  ${OrgFragment}
`;

export const GET_APP_CONFIG = gql `
  query getOrg {
    getOrg {
      appConfig {
        ...AppConfigFragment
      }
    }
  }
  ${AppConfigFragment}
`

export const GET_SUBMISSION_CONFIG = gql`
  query getOrg {
    getOrg {
      submissionConfig {
        ...SubmissionConfigFragment
      }
    }
  }
  ${SubmissionConfigFragment}
`
export const GET_ANALYTICS_CONFIG = gql`
  query getOrg {
    getOrg {
      analyticsConfig {
        ...AnalyticsConfigFragment
      }
    }
  }
  ${AnalyticsConfigFragment}
`
export const GET_TAG_OPTIONS = gql`
  query getTagOptions {
    getOrg {
      id
      tagOptions {
        _id
        tag
        category
        isActive
        permittedRoles
      }
    }
  }
`
export const GET_ADMIN_REVIEW_TAGS = gql`
    query adminTags {
      adminTags {
        _id
        tag
        category
        isActive
        permittedRoles
      }
    }
  `

export const ADD_RESOURCE = gql`
  mutation addResource($newResourceData: NewResourceInput!) {
    addResource(newResourceData: $newResourceData) {
      id
    }
  }
`;

export const REMOVE_RESOURCE = gql`
  mutation deleteResource($removeResourceData: RemoveResourceInput!) {
    deleteResource(removeResourceData: $removeResourceData) {
      id
    }
  }
`;

export const ADD_SURVEY = gql`
  mutation addSurvey($newSurveyData: NewSurveyData!) {
    addSurvey(newSurveyData: $newSurveyData) {
      id
      title
      startDate
      endDate
      userIds
      viewCount
      recipientGroup
      selectedRoles
      responsesReceived {
        id
        surveyId
        userId
        answers {
          textFieldValue
          questionLabel
          questionType
          questionGroupLabel
          option
          otherResponse
          }
        submitted
        anonymous
        submittedDate
      }
      createdBy
      createdDate
      questions {
        multipleChoice
        openTextField
        priority
        previousLabel
        previousLabel
        groupLabel
        questionType
        label
        multi
        instruction
        text: questionText
        answers: answerOptions {
          displayTextField
          displayTextFieldLabel
          label: answerText
          iconClass: media
          questions {
            groupLabel
            questionType
            label
            previousLabel
            previousLabel
            multi
            instruction
            text: questionText
            answers: answerOptions {
              displayTextField
              displayTextFieldLabel
              label: answerText
              iconClass: media
            }
            skippable
          }
        }
        skippable
      }
    }
  }
`;
export const END_SURVEY = gql`
  mutation endSurvey($endSurveyData: EndSurveyInput!) {
    endSurvey(endSurveyData: $endSurveyData) {
      id
      title
      startDate
      endDate
      userIds
      viewCount
      recipientGroup
      selectedRoles
      responsesReceived {
        id
        surveyId
        userId
        answers {
          textFieldValue
          questionLabel
          questionType
          questionGroupLabel
          option
          otherResponse
          }
        submitted
        anonymous
        submittedDate
      }
      createdBy
      createdDate
      questions {
        multipleChoice
        openTextField
        priority
        groupLabel
        previousLabel
        previousLabel
        questionType
        label
        multi
        instruction
        text: questionText
        answers: answerOptions {
          displayTextField
          displayTextFieldLabel
          label: answerText
          iconClass: media
          questions {
            groupLabel
            questionType
            label
            previousLabel
            previousLabel
            multi
            instruction
            text: questionText
            answers: answerOptions {
              displayTextField
              displayTextFieldLabel
              label: answerText
              iconClass: media
            }
            skippable
          }
        }
        skippable
      }
    }
  }
`;
export const SURVEYS_AND_RESPONSES = gql`
  query orgSurveysAndResponses {
    orgSurveysAndResponses {
      id
      title
      startDate
      endDate
      userIds
      viewCount
      recipientGroup
      selectedRoles
      responsesReceived {
        id
        surveyId
        userId
        answers {
          textFieldValue
          questionLabel
          questionType
          questionGroupLabel
          option
          otherResponse
          }
        submitted
        anonymous
        submittedDate
      }
      createdBy
      createdDate
      questions {
        multipleChoice
        openTextField
        priority
        groupLabel
        questionType
        label
        previousLabel
        multi
        instruction
        text: questionText
        answers: answerOptions {
          displayTextField
          displayTextFieldLabel
          label: answerText
          iconClass: media
          questions {
            groupLabel
            previousLabel
            previousLabel
            questionType
            label
            multi
            instruction
            text: questionText
            answers: answerOptions {
              displayTextField
              displayTextFieldLabel
              label: answerText
              iconClass: media
            }
            skippable
          }
        }
        skippable
      }
    }
  }
`

export const UPDATE_ORG = gql`
  mutation updateOrg($updateOrgData: UpdateOrgInput!) {
    updateOrg(updateOrgData: $updateOrgData) {
      id
    }
  }
`;


export const CREATE_ANSWER_OPTION_TAGS = gql`
  mutation createAnswerOptionTags($tagData: AnswerOptionsTagInput!) {
    createAnswerOptionTags(tagData: $tagData) {
      _id
      tag
      category
      isActive
      permittedRoles
    }
  }
`;


export const ADD_SUBMISSION = gql`
  mutation addSubmission($newSubmissionData: NewSubmissionData!) {
    addSubmission(newSubmissionData: $newSubmissionData) {
      id
    }
  }
`;

export const REMOVE_SUBMISSION = gql`
  mutation deleteSubmission($removeSubmissionData: RemoveSubmissionInput!) {
    deleteSubmission(removeSubmissionData: $removeSubmissionData) {
      id
    }
  }
`;

export const REMOVE_SURVEY = gql`
  mutation deleteSurvey($removeSurveyData: RemoveSurveyInput!) {
    deleteSurvey(removeSurveyData: $removeSurveyData) {
      id
    }
  }
`;


export const BULK_HRA_CONVERSION = gql`
  mutation bulkHRAConversion($data: UpdateFolderHRAInput!) {
    bulkHRAConversion(data: $data) {
      success
    }
  
  }`