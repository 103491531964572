export const referOrgId = "d-5246b0ca93e84de186b3a57110264278";
export const referIndId = "d-81c81b0da619419ca89b27bb066a6299";
export const folderExportId = "d-156c4ba9118343fd966bda1a237dda74";
export const submitFolderId = "d-61eee38bc7554cafad58ab8969316275";
export const folderStatusChangeId = "d-6f4844658586493b946be457c0ef84a6";
export const folderClosedId = "d-f3614d885895483b8bd780dc49f997c8";
export const folderDeletedId = "d-bdd2f841ce6f49288b4d711f5e0f2f60";
export const folderDeletedReminderTemplateId =
  "d-3d4ea8e038434a1cb32e063e65777865";
export const folderExternalShareTemplateId =
  "d-816041c3b259482c9f2f75caf846e9d2";
