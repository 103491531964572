
import {
  defineComponent,
  PropType,
  ref,
  watch,
  onBeforeUnmount,
  getCurrentInstance,
  computed,
} from "vue";
import EventBus from "@/EventBus/eventBus";
import { useStore } from "vuex";
import { useRoute } from "vue-router";
import { useAuth0 } from "@auth0/auth0-vue";
import { useRole } from "@/plugins/rolePlugin";

export default defineComponent({
  props: {
    email: {
      type: [String, Object] as PropType<any>,
      required: true,
    },
    isOpen: {
      type: Boolean as PropType<boolean>,
      required: true,
    },
  },
  setup(props, { emit }) {
    const role = useRole();
    const instance = getCurrentInstance();
    const store = useStore();
    const route = useRoute();
    const auth = useAuth0();
    const logoutUrl = computed(() => {
      return store.getters.getOrgLogoutUrl;
    });

    const close = () => {
      instance?.emit("close");
    };

    const logout = () => {
      // auth.quickLogOut({ returnTo: logoutUrl.value });
    };

    const onRouteChange = (newVal: any) => {
      if (props.isOpen) {
        close();
      }
    };

    watch(() => route, onRouteChange, { deep: true });

    onBeforeUnmount(() => {
      EventBus.off("route-change", onRouteChange);
    });

    return {
      logoutUrl,
      close,
      logout,
      role,
    };
  },
});
