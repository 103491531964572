
import Vue, {
  computed,
  defineComponent,
  getCurrentInstance,
  onMounted,
  ref,
} from "vue";

import TimeAgo from "javascript-time-ago";
import en from "javascript-time-ago/locale/en";

TimeAgo.addLocale(en);

// @Component
// export default class Notifications extends Vue{
export default defineComponent({
  name: "Notifications",
  props: {
    data: {
      type: Array,
    },
  },
  setup(props:any) {
    const month = ref<string[]>([
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December",
    ]);
    const timeAgo = ref(new TimeAgo("en-US"));
    const instance = getCurrentInstance();

    const notifications = computed(() => {
      return formatNotifications(props.data as any[]);
    });

    const noNotifications = computed(() => {
      return props.data?.length == 0;
    });
    const formatNotifications = (data: any[]) => {
      const formatted = {};
      data
        .sort(function (a: any, b: any) {
          return (
            (new Date(b.createdDate) as any) - (new Date(a.createdDate) as any)
          );
        })
        .map((d) => {
          const date = new Date(d.createdDate);
          const dateString = `${
            month.value[date.getMonth()]
          } ${date.getDate()}`;
          const timeSince = timeAgo.value.format(date, "round");
          const item = {
            message: d.message,
            timeSince,
          };
          if (formatted[dateString]) {
            formatted[dateString].push(item);
          } else {
            formatted[dateString] = [item];
          }
        });
      return formatted;
    };

    onMounted(() => {
      instance?.emit("viewed");
    });
    const close = () => {
      instance?.emit("close");
    };
    return {
      close,
      notifications,
      noNotifications,
    };
  },
});
