
import Vue, { computed, defineComponent, getCurrentInstance, ref } from "vue";

import UserSettings from "./UserSettings.vue";
import Security from "./Security.vue";
import NotificationsSettings from "./NotificationsSettings.vue";
import { useStore } from "vuex";
import { useRole } from "@/plugins/rolePlugin";
export default defineComponent({
  name: "Settings",
  components: {
    UserSettings,
    Security,
    NotificationsSettings,
  },
  setup() {
    const settingsView = ref<SettingsView | null>(null);
    const menuTitle = ref<string>("");
    const store = useStore();
    const role = useRole();
    const instance = getCurrentInstance();
    const selectMenu = (view: SettingsView) => {
      if (view == SettingsView.User) {
        menuTitle.value = "Account Settings";
      }
      if (view == SettingsView.Security) {
        menuTitle.value = "Security";
      }
      if (view == SettingsView.Notifications) {
        menuTitle.value = "Notifications";
      }
      settingsView.value = view;
    };

    const back = () => {
      settingsView.value = null;
    };

    const handleClose = () => {
      instance?.emit("close");
    };

    const orgAllowsPersonalEmail = computed(() => {
      return store.getters.getOrgAllowsAddingPersonalEmail;
    });

    const currentUser = computed(() => {
      return store.getters.getCurrentGqlUser;
    });
    return {
      orgAllowsPersonalEmail,
      handleClose,
      back,
      menuTitle,
      settingsView,
      selectMenu,
      role
    };
  },
});

enum SettingsView {
  User = 1,
  Security = 2,
  Notifications = 3,
}
