
import { useRole } from "@/plugins/rolePlugin";
import Vue, { computed, defineComponent, getCurrentInstance, ref } from "vue";

import { useStore } from "vuex";
export default defineComponent({
  name: "Security",
  setup() {
    const email = ref<string>("");
    const instance = getCurrentInstance();
    const store = useStore();
    const role = useRole();
    const reset = async () => {
      const options = {
        method: "POST",
        headers: { "content-type": "application/json" },
        body: JSON.stringify({
          client_id: process.env.VUE_APP_AUTH0_CLIENTID,
          email: email,
          connection: process.env.VUE_APP_AUTH0_CONNECTION,
        }),
      };
      try {
        const url =
          "https://" +
          process.env.VUE_APP_AUTH0_DOMAIN +
          "/dbconnections/change_password";
        fetch(url, options);
      } catch (err: any) {
        console.error("Error during reset", err);
        if (err?.message) {
          const errorMessage = err.message;
        }
      }
    };
    const back = () => {
      instance?.emit("back");
    };
    const currentUser = computed(() => {
      return store.getters.getCurrentGqlUser;
    });
    return {
      email,
      currentUser,
      reset,
      back,
      role,
    };
  },
});
