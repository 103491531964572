
import Vue, { computed, defineComponent, getCurrentInstance } from "vue";

import Settings from "@/components/toolbar/Settings.vue";
import Support from "@/components/toolbar/Support.vue";
import Notifications from "@/components/toolbar/Notifications.vue";

import { useStore } from "vuex";
import { useAuth0 } from "@auth0/auth0-vue";

export default defineComponent({
  name: "ToolbarMenu",
  components: {
    Settings,
    Support,
    Notifications,
  },
  props: {
    active: {
      type: Number,
      default: null,
    },
  },
  setup(props) {
    const store = useStore();
    const instance = getCurrentInstance();
    const auth = useAuth0();
    const openMenu = computed(() => {
      return props.active;
    });
    const Notifications = computed(() => {
      const notif = store.getters.getUserNotifcations;
      let count = 0;
      notif.forEach((x) => {
        if (!x.read) count++;
      });
      return { notif, count };
    });
    const markAsRead = computed(() => {
      store.dispatch("markNotifcationsRead", auth);
    });
    const handleClose = () => {
      instance?.emit("close");
    };
    return {
      openMenu,
      handleClose,
      Notifications,
      markAsRead,
    };
  },
});

enum ToolbarView {
  Settings = 1,
  Notifications = 2,
  Support = 3,
}
