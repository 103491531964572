<template>
  <b-row class="footer">
    <b-col md="4">
      <div class="">
          <img
            class="footerlogo"
            src="../../assets/shared-images/icon-blue.svg"
          />
        </div>
    </b-col>
    <b-col md="4">
      <div class="social-icons text-center">
          <a href="mailto:hello@speakfully.com">
            <img
              class="social-icon"
              src="../../assets/shared-images/social/email-icon.svg"
            />
          </a>
          <a target="_blank" href="https://twitter.com/speakfullynow">
            <img
              class="social-icon"
              src="../../assets/shared-images/social/twitter-icon.svg"
            />
          </a>
          <a
            target="_blank"
            href="https://www.linkedin.com/company/speakfully/"
          >
            <img
              class="social-icon"
              src="../../assets/shared-images/social/linkedin-icon.svg"
            />
          </a>
          <a target="_blank" href="https://www.facebook.com/speakfullynow/">
            <img
              class="social-icon"
              src="../../assets/shared-images/social/facebook-icon.svg"
            />
          </a>
          <a target="_blank" href="https://instagram.com/speakfullynow">
            <img
              class="social-icon"
              src="../../assets/shared-images/social/instagram-icon.svg"
            />
          </a>
        </div>
        <div class="footer-links text-center">
          <div>
            <a :href="`${STATIC_URL}/privacypolicy`">Privacy Policy</a>
            /
            <a :href="`${STATIC_URL}/termsandconditions`"
              >Terms and Conditions</a
            >
          </div>
          <div>Copyright © 2021 Speakfully</div>
        </div>
    </b-col>
  </b-row>
  <!-- <div class="footer-container" >
    <div class="footer" >
      <div class="help" >
        <div class="" >
          <img
            class="footerlogo"
            src="../../assets/shared-images/icon-blue.svg"
          />
        </div>
      </div>

      
      <div class="" >
        <div class="" >
          <div class="" >
            <router-link class="" to="/support"
              >SUPPORT</router-link
            >
          </div>
          <div class="" >
            <router-link class="" to="/refer">REFER</router-link>
          </div>
          <div class="" >
            <router-link class="y" to="/feedback"
              >FEEDBACK</router-link
            >
          </div>
          <div class="" >
            <a class="" href="mailto:hello@speakfully.com"
              >CONTACT</a
            >
          </div>
        </div>
        <div class="">
          <a href="mailto:hello@speakfully.com">
            <img
              class="social-icon"
              src="../../assets/shared-images/social/email-icon.svg"
            />
          </a>
          <a target="_blank" href="https://twitter.com/speakfullynow">
            <img
              class="social-icon"
              src="../../assets/shared-images/social/twitter-icon.svg"
            />
          </a>
          <a
            target="_blank"
            href="https://www.linkedin.com/company/speakfully/"
          >
            <img
              class="social-icon"
              src="../../assets/shared-images/social/linkedin-icon.svg"
            />
          </a>
          <a target="_blank" href="https://www.facebook.com/speakfullynow/">
            <img
              class="social-icon"
              src="../../assets/shared-images/social/facebook-icon.svg"
            />
          </a>
          <a target="_blank" href="https://instagram.com/speakfullynow">
            <img
              class="social-icon"
              src="../../assets/shared-images/social/instagram-icon.svg"
            />
          </a>
        </div>
        <div class="">
          <div>
            <a :href="`${STATIC_URL}/privacypolicy`">Privacy Policy</a>
            /
            <a :href="`${STATIC_URL}/termsandconditions`"
              >Terms and Conditions</a
            >
          </div>
          <div>Copyright © 2019 Speakfully</div>
        </div>
      </div>
     
    </div>
  </div> -->
</template>

<script>
export default {
  name: "Footer",
  data() {
    return {
      staticStyle: true,
      STATIC_URL: process.env.VUE_APP_STATIC_URL,
      PRODUCT_URL: process.env.VUE_APP_PRODUCT_URL
    };
  },
  methods: {
    setStaticStyle() {
      this.staticStyle = true;
    },
    removeStaticStyle() {
      this.staticStyle = false;
    }
  }
};
</script>

<style lang="scss" scoped>
.footer{
  height:92px;
}

.footerlogo{
  padding-left:18px;
  padding-top:18px;
}

.social-icons {
  width:250px;
  padding-top: 18px;
  margin: 0 auto;
  a {
    padding-right: 9px;
    padding-left:9px;
  }
}
.footer-links{
  padding-top:18px;
  font-size: 0.7rem;
  a{
    color: #15337E;
  }
  color:#15337E;
}
</style>
